import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Loader, Wrapper } from 'sputnik-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Key, ConfirmActionModal, AddKeyModal, FoldButton } from 'components'
import styled, { css } from 'styled-components'
import {
  CardHeader,
  IconButton,
  Typography,
  List,
  Tooltip,
  LinearProgress,
} from '@material-ui/core'
import { AddCircle, DeleteSweep, Publish, GetApp } from '@material-ui/icons'
import { ReactComponent as KeyIcon } from 'assets/Nfc.svg'
import {
  deleteAllKeys,
  removeKey,
  getKeys,
  syncKeys,
  importKeys,
  exportKeys,
} from 'store/keys/operations'

import FileInput from 'components/atoms/FileInput'
import { ReactComponent as UpdateIcon } from '../../../assets/update.svg'

const StyledWrapper = styled(Wrapper)`
  background: ${(p) =>
    p.isUnpaid
      ? p.theme.palette.grayscale[2]
      : p.theme.palette.background.primary};
  color: ${(p) =>
    p.isUnpaid ? p.theme.palette.text.primary : p.theme.palette.text.primary};
`

const ListWrapper = styled.div`
  /* transition: max-height 300ms ease-in; */
  max-height: 0rem;
  padding: 0;
  overflow-y: scroll;
  &:last-child {
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(192, 192, 192);
  }

  ${(p) =>
    p.isExpanded &&
    css`
      padding: 16px 0 !important;
      max-height: 26rem !important;
    `}
`

const StyledHeader = styled(CardHeader)`
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
  color: inherit;
  position: sticky;
  z-index: 2;
  top: 0;
  height: 80;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
`
export default function Keys({ add, ...props }) {
  const keysList = useRef()
  const dispatch = useDispatch()

  const { current, keys, isUnpaid, loading, keysCount } = useSelector(
    ({ resources: { entries, flats }, keys }) => ({
      current: entries.current || flats.current,
      keys: keys.list,
      isUnpaid: keys.isUnpaid,
      loading: keys.loading,
      keysCount: keys.count,
    })
  )

  const [state, setState] = useState({
    loading: false,
    deleteKeyModal: false,
    syncKeysModal: false,
    toDelete: null,
    expanded: false,
    addKeyModal: false,
    deleteAllKeysModal: false,
    page: 1,
  })

  const onDeleteKeyModalClose = () =>
    setState({ ...state, deleteKeyModal: false, toDelete: null })

  const onDeleteKey = () => {
    dispatch(removeKey(current.uuid, state.toDelete)).then(() =>
      dispatch(getKeys(current.uuid))
    )
    onDeleteKeyModalClose()
  }

  const onModalOpen = (key) => () =>
    setState({
      ...state,
      deleteKeyModal: true,
      toDelete: key,
    })

  const [page, setPage] = useState(1)
  const loadNextPage = (e) => {
    if (!keysList.current) return

    const { scrollTop, clientHeight, scrollHeight } = keysList.current
    if (Math.abs(scrollHeight - clientHeight - Math.ceil(scrollTop)) < 3) {
      setPage(page + 1)
    }
  }
  useEffect(() => {
    if (current) {
      dispatch(getKeys(current.uuid, page))
    }
  }, [current, dispatch, page])

  const toggleModal = (modal) => () =>
    setState({
      ...state,
      [modal]: !state[modal],
    })

  const handleDeleteKeys = () => {
    setState({ ...state, loading: true })
    dispatch(deleteAllKeys(current.uuid))
      .then(() => dispatch(getKeys(current.uuid)))
      .then(() => setState({ ...state, loading: false }))
      .then(toggleModal('deleteAllKeysModal'))
    toggleModal('deleteAllKeysModal')
  }

  const handleSyncKeys = () => {
    dispatch(syncKeys(current.uuid))
      .then(() => dispatch(getKeys(current.uuid)))
      .then(toggleModal('syncKeysModal'))
    toggleModal('syncKeysModal')
  }

  const { t } = useTranslation(['keys', 'content', 'common'])

  const [csvFile, setCsvFile] = useState(null)

  const handleUploud = useCallback((e) => {
    setCsvFile(e.target.files[0])
    e.target.value = null
  }, [])

  useEffect(() => {
    if (csvFile && current) {
      dispatch(importKeys(current.uuid, csvFile)).then(() => {
        setCsvFile(null)
        setState(true)
        dispatch(getKeys(current.uuid, page))
      })
    }
  }, [csvFile, current, dispatch, page])

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <StyledWrapper
        style={{
          padding: 0,
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
        }}
        isUnpaid={isUnpaid}
        shadow={false}
      >
        <StyledHeader
          avatar={<KeyIcon />}
          title={
            isUnpaid ? (
              <Typography
                color="inherit"
                style={{
                  fontSize: '1.2rem',
                }}
              >
                {t('keys:messages.control_info.1')}
                <Typography
                  color="inherit"
                  style={{
                    fontSize: '1.2rem',
                  }}
                  inline
                  variant="body2"
                  component="a"
                  href="https://partner.sputnik.systems/subscriptions"
                >
                  {t('keys:messages.control_info.2')}
                </Typography>
              </Typography>
            ) : (
              <Typography
                color="inherit"
                style={{
                  fontSize: '1.2rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {keys && keys.length < 1
                  ? t('keys:messages.empty')
                  : `${keysCount} ${t('keys:units.key', {
                      count: keysCount,
                    })}`}
              </Typography>
            )
          }
          action={
            !isUnpaid && (
              <div
                style={{
                  marginTop: 8,
                }}
              >
                <Tooltip
                  title={t('keys:actions.remove_all_keys')}
                  placement="top"
                >
                  <IconButton onClick={toggleModal('deleteAllKeysModal')}>
                    <DeleteSweep color="error" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('keys:actions.import')} placement="top">
                  <IconButton color="inherit" disabled={!keys}>
                    <Publish />
                    <FileInput
                      id="file"
                      name="file"
                      accept=".csv"
                      onChange={handleUploud}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t('keys:actions.export')} placement="top">
                  <IconButton
                    color="inherit"
                    disabled={!keys}
                    onClick={() =>
                      dispatch(exportKeys(current?.uuid, keysCount))
                    }
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t('keys:actions.sync_keys')} placement="top">
                  <IconButton
                    color="inherit"
                    disabled={!keys}
                    onClick={toggleModal('syncKeysModal')}
                  >
                    <UpdateIcon />
                  </IconButton>
                </Tooltip>
                {/* add collecting keys check */}
                {/* {current && !current.opened && (
                  <Tooltip title={t('keys:actions.sync_keys')} placement="top">
                    <IconButton
                      color="inherit"
                      onClick={toggleModal('syncKeys')}
                    >
                      <Sync />
                    </IconButton>
                  </Tooltip>
                )} */}

                {add && (
                  <Tooltip placement="top" title={t('keys:actions.add_key')}>
                    <IconButton
                      color="inherit"
                      onClick={toggleModal('addKeyModal')}
                    >
                      <AddCircle />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )
          }
        />
        <ListWrapper
          isExpanded={state.expanded}
          onScroll={loadNextPage}
          ref={keysList}
        >
          <List>
            {keys ? (
              keys.length > 0 ? (
                <>
                  {keys.map((k) => (
                    <Key
                      parentUuid={current && current.uuid}
                      key={k.uuid}
                      {...k}
                      onClick={onModalOpen(k.uuid)}
                    >
                      {k.hex}
                    </Key>
                  ))}
                </>
              ) : (
                <Typography align="center">
                  {t('messages:content.empty')}
                </Typography>
              )
            ) : (
              <Loader msg={t('messages:content.loading')} fluid />
            )}
          </List>
        </ListWrapper>
        {loading && (
          <LinearProgress
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          />
        )}

        <ConfirmActionModal
          action="keys:actions.remove_key"
          handleClose={onDeleteKeyModalClose}
          handleAction={onDeleteKey}
          open={state.deleteKeyModal}
        />
      </StyledWrapper>
      {keys && keys.length > 0 && !isUnpaid && (
        <Tooltip
          placement="bottom"
          title={
            state.expanded ? t('common:button.fold') : t('common:button.unfold')
          }
        >
          <FoldButton
            onClick={() => setState({ ...state, expanded: !state.expanded })}
            disableRipple
            isExpanded={state.expanded}
          />
        </Tooltip>
      )}

      <ConfirmActionModal
        action={t('keys:actions.remove_all_keys')}
        handleClose={toggleModal('deleteAllKeysModal')}
        handleAction={handleDeleteKeys}
        open={state.deleteAllKeysModal}
        loading={state.loading}
      />
      <ConfirmActionModal
        action={t('keys:actions.sync_keys')}
        handleClose={toggleModal('syncKeysModal')}
        handleAction={handleSyncKeys}
        open={state.syncKeysModal}
      />
      <AddKeyModal
        handleClose={toggleModal('addKeyModal')}
        open={state.addKeyModal}
      />
    </div>
  )
}
