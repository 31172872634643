import * as types from './types'

export const changeFlatConfigRequest = () => ({
  type: types.CHANGE_FLAT_CONFIG_REQUEST,
})
export const changeFlatConfigSuccess = (msg) => ({
  payload: { msg },
  type: types.CHANGE_FLAT_CONFIG_SUCCESS,
})
export const changeFlatConfigFailure = (error) => ({
  payload: { error },
  type: types.CHANGE_FLAT_CONFIG_FAILURE,
})

export const getFlatsRequest = () => ({
  type: types.GET_FLATS_REQUEST,
})
export const getFlatsSuccess = (flats) => ({
  type: types.GET_FLATS_SUCCESS,
  payload: flats,
})
export const getFlatsError = (error) => ({
  type: types.GET_FLATS_ERROR,
  payload: error,
})

export const changeFlatRangeRequest = () => ({
  type: types.CHANGE_FLAT_RANGE_REQUEST,
})
export const changeFlatRangeSuccess = (msg) => ({
  type: types.CHANGE_FLAT_RANGE_SUCCESS,
  payload: msg,
})
export const changeFlatRangeError = (error) => ({
  type: types.CHANGE_FLAT_RANGE_ERROR,
  payload: error,
})

export const fetchFlatRequest = () => ({
  type: types.FETCH_FLAT_REQUEST,
})
export const fetchFlatSuccess = (flat) => ({
  type: types.FETCH_FLAT_SUCCESS,
  payload: { flat },
})
export const fetchFlatFailure = (error) => ({
  type: types.FETCH_FLAT_FAILURE,
  payload: { error },
})

export const updateFlatRequest = () => ({
  type: types.UPDATE_FLAT_REQUEST,
})
export const updateFlatSuccess = (msg) => ({
  type: types.UPDATE_FLAT_SUCCESS,
  payload: { msg },
})
export const updateFlatFailure = (error) => ({
  type: types.UPDATE_FLAT_FAILURE,
  payload: { error },
})
