import Api from 'utils/api'
import * as actions from './actions'

export const fetchGate = (gateUuid) => async (dispatch) => {
  dispatch(actions.fetchGateRequest())
  try {
    const gate = await Api.request(`/gates/${gateUuid}`)
    dispatch(actions.fetchGateSuccess(gate))
    return Promise.resolve(gate)
  } catch (err) {
    // const { message } = await err.json()
    dispatch(actions.fetchGateFailure(err))
    return Promise.reject(err)
  }
}

export const updateGate =
  ({ uuid, lat, lon }) =>
  async (dispatch) => {
    dispatch(actions.updateGateRequest())
    try {
      const gate = await Api.request(`/gates/${uuid}`, {
        method: 'PUT',
        body: JSON.stringify({ lat, lon }),
      })
      dispatch(actions.updateGateSuccess(gate))
      return Promise.resolve()
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.updateGateFailure(message))
      return Promise.reject(err)
    }
  }
