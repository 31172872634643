// @flow
import Api from 'utils/api'
import * as actions from './actions'

export const syncInstallationPoint = (addressUuid) => async (dispatch) => {
  dispatch(actions.syncInstallationPointRequest())
  try {
    const payload = await Api.request(
      `/installation_points/${addressUuid}/sync`,
      {
        method: 'PUT',
      }
    )
    dispatch(actions.syncInstallationPointSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncInstallationPointError(message))
    return Promise.reject()
  }
}

export const openCollectKeys = (deviceUuid) => async (dispatch) => {
  dispatch(actions.openCollectingKeysRequest())
  try {
    const res = await Api.request(
      `/intercoms/${deviceUuid}/open_collection_keys`,
      {
        method: 'POST',
      }
    )
    dispatch(actions.openCollectingKeysSuccess(res.message))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.closeCollectingKeysError(error))
    return Promise.reject(error)
  }
}

export const closeCollectKeys = (deviceUuid) => async (dispatch) => {
  dispatch(actions.closeCollectingKeysRequest())
  try {
    const res = await Api.request(
      `/intercoms/${deviceUuid}/close_collection_keys`,
      {
        method: 'POST',
      }
    )
    dispatch(actions.closeCollectingKeysSuccess(res.message))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.openCollectingKeysError(error))
    return Promise.reject(error)
  }
}

export const searchInstallationPoint = (intercomNumber) => async (dispatch) => {
  dispatch(actions.searchInstallationPointRequest())
  try {
    const searchResults = await Api.request(
      `/installation_points?number=${intercomNumber}`
    )
    if (searchResults.length === 0) throw new Error()
    dispatch(actions.searchInstallationPointSuccess())
    return Promise.resolve(searchResults[0].address_uuid)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.searchInstallationPointError(message))
    return Promise.reject(message)
  }
}

export const generateQr = (entryUuid) => async (dispatch) => {
  dispatch(actions.generateQrRequest())
  try {
    await Api.request(`/entries/${entryUuid}/generate_qr`)
    dispatch(actions.generateQrSuccess())
    return Promise.resolve()
  } catch (err) {
    const message = 'lel'
    // const { message } = await err.json()
    dispatch(actions.generateQrError(err))
    return Promise.reject(message)
  }
}

export const invalidateQr = (entryUuid) => async (dispatch) => {
  dispatch(actions.invalidateQrRequest())
  try {
    const payload = await Api.request(`/entries/${entryUuid}/invalidate_qr`, {
      method: 'PATCH',
    })
    dispatch(actions.invalidateQrSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.invalidateQrError(message))
    return Promise.reject(message)
  }
}

export const fetchInstallationPoints = (address) => async (dispatch) => {
  dispatch(actions.fetchInstallationPointsRequest())
  try {
    const installationPoints = await Api.request(
      `/installation_points?address=${address}`
    )
    dispatch(actions.fetchInstallationPointsSuccess(installationPoints))
    return Promise.resolve(installationPoints)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchInstallationPointsFailure(message))
    return Promise.reject(message)
  }
}
