import React from 'react'
import BaseTemplate from 'components/templates/BaseTemplate'
import { DeviceZone } from 'features/DeviceZone'

function DeviceZonePage({ children, ...props }) {
  return (
    <BaseTemplate menu>
      <DeviceZone />
    </BaseTemplate>
  )
}

export default DeviceZonePage
