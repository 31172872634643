import React from 'react'
import { Add } from '@material-ui/icons'
import { motion } from 'framer-motion'

const variants = {
  open: { rotate: 45 },
  closed: { rotate: 0 },
}

export default function DropdownIcon({ open }) {
  return (
    <motion.span
      animate={open ? 'open' : 'closed'}
      variants={variants}
      transition={{
        mass: 0.5,
      }}
    >
      <Add />
    </motion.span>
  )
}
