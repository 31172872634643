import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'phone', function (msg) {
  return this.test('phone', msg, (value) => {
    try {
      return (
        value === undefined ||
        value.replace(/\s|-/g, '').match(/^[0-9+]{8,13}$/)
      )
    } catch (e) {
      return false
    }
  })
})
