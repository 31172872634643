import React from 'react'
import { Wrapper, formatDeviceId } from 'sputnik-ui'
import styled from 'styled-components'
import { CardHeader, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FileCopy } from '@material-ui/icons'
// import Bulb from 'components/atoms/Bulb'
import sputnik from 'assets/sputnik.png'
import useHover from 'hooks/useHover'
import { copyToClipboard } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { showInfoMessage } from 'store/ui/actions'

const styles = {
  subheader: {
    fontSize: '0.9rem',
    fontWeight: 400,
    display: 'inline-flex',
    alignItems: 'center',
    // textDecoration: 'underline',
  },
}

const StyledWrapper = styled(Wrapper)`
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  position: relative;
  background: ${(p) => p.theme.palette.background.primary};
  /* color: ${(p) =>
    p.isOnline
      ? p.theme.palette.success.contrastText
      : p.theme.palette.error.contrastText}; */

  &:hover {
    /* border: 1px solid black; */
    box-shadow: ${(p) => p.theme.shadows.strong};
    transform: translateY(-0.325rem);
  }
`

const Status = styled.div`
  background: ${(p) => p.theme.palette.primary[500]};
  background: ${(p) =>
    p.isOnline ? p.theme.palette.success['500'] : p.theme.palette.error['500']};
  width: calc(100% - 85%);
  height: 160px;
  transition: background-color 450ms ease-in;
  will-change: background-color;
  position: absolute;
  right: 0;
  top: 0;
  transform: skew(-30deg);

  /* @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      margin-left: 10%;
    } */
`

// const Status = styled.div`
//   background: ${p => p.theme.palette.primary[500]};
//   background: ${p =>
//     p.isOnline ? p.theme.palette.success['500'] : p.theme.palette.error['500']};
//   width: 40px;
//   height: 40px;
//   transition: background-color 450ms ease-in;
//   will-change: background-color;
//   position: absolute;
//   right: 0;
//   top: 0;
//   border-radius: 50%;
// `

const SputnikIntercom = ({
  device,
  action,
  openCameraModal,
  handleUnlink,
  isOnline = false,
  isGate,
}) => {
  const { t } = useTranslation(['devices', 'info'])

  const { hovered, bind } = useHover()
  const dispatch = useDispatch()

  return (
    <StyledWrapper {...bind}>
      <CardHeader
        style={{ backgroundColor: 'inherit', color: 'inherit' }}
        avatar={
          <img
            src={sputnik}
            alt="device"
            style={{
              height: 44,
              width: 44,
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        }
        title={
          <Typography
            variant="h6"
            style={{ fontSize: '1.2rem' }}
            color="textPrimary"
            display="block"
          >
            {formatDeviceId(device.serial_number)}
            {hovered && (
              <FileCopy
                style={{ width: 14, height: 14, marginLeft: 8 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyToClipboard(device.serial_number)
                  dispatch(
                    showInfoMessage(
                      t('info:copy.device', { number: device?.serial_number })
                    )
                  )
                }}
              />
            )}
          </Typography>
        }
        subheader={
          <Typography
            variant="body1"
            color="textSecondary"
            style={styles.subheader}
          >
            {/* <DialerSip
              color="inherit"
              style={{
                width: '0.9rem',
                height: '0.9rem',
                marginRight: '0.125rem',
              }}
            />{' '}
            {device.sip_login}
           {' · '}
            <GpsFixed
              color="inherit"
              style={{
                width: '0.9rem',
                height: '0.9rem',
                marginLeft: '0.125rem',
                marginRight: '0.125rem',
              }}
            />
            {new Date(device.close_collect_keys).toLocaleDateString()} */}
            {isGate
              ? t('devices:actions.control_gate')
              : t('devices:actions.control')}
          </Typography>
        }
        action={<Status isOnline={device.is_online} />}
      />
    </StyledWrapper>
  )
}

// <Status isOnline={device.is_online} />

export default SputnikIntercom
