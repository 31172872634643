import * as types from './types'

/* streets */

export const getStreetsRequest = () => ({
  type: types.GET_STREETS_REQUEST,
})
export const getStreetsSuccess = (streets) => ({
  type: types.GET_STREETS_SUCCESS,
  payload: streets,
})
export const getStreetsError = (error) => ({
  type: types.GET_STREETS_ERROR,
  payload: error,
})

export const fetchStreetRequest = () => ({
  type: types.FETCH_STREET_REQUEST,
})
export const fetchStreetSuccess = (street) => ({
  type: types.FETCH_STREET_SUCCESS,
  payload: { street },
})
export const fetchStreetFailure = (error) => ({
  type: types.FETCH_STREET_FAILURE,
  payload: { error },
})
