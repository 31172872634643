// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import FiltersWrapper from 'components/atoms/FiltersWrapper'
import MainTemplate from '../MainTemplate'

const OrdersTemplate = ({ header, children, filters, ...props }) => (
  // return (
  //   <Wrapper {...props}>
  //     <Container
  //       style={{ height: 'auto', paddingTop: '2rem', paddingBottom: '2rem' }}
  //     >
  //       {header}
  //       <FiltersWrapper>{filters}</FiltersWrapper>
  //       {children}
  //     </Container>
  //   </Wrapper>
  // )
  <MainTemplate {...props}>
    <div>
      {header}

      <FiltersWrapper>{filters}</FiltersWrapper>

      {children}
    </div>
  </MainTemplate>
)

export default OrdersTemplate
