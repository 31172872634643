import React from 'react'
import { ReactComponent as CitizenIcon } from 'assets/Citizen.svg'
import { Mascotte } from 'sputnik-ui'
import { ChatBubbleOutline, NoteOutlined } from '@material-ui/icons'

const Hello = (p) => (
  <Mascotte emote="hello" fit="contain" style={{ width: '100%' }} />
)
const Forward = (p) => (
  <Mascotte emote="forward" fit="contain" style={{ width: '100%' }} />
)

const getOrderParams = (variant) => {
  switch (variant) {
    case 'note':
      return NoteOutlined

    case 'service_partner_comment':
      return ChatBubbleOutline
    case 'service_partner_note':
      return NoteOutlined
    case 'sputnik_note':
      return Hello
    case 'sputnik_comment':
      return Forward
    case 'user_comment':
      return CitizenIcon

    default:
      return ChatBubbleOutline
  }
}

function MessageIcon({ type, ...props }) {
  const Icon = getOrderParams(type)

  return <Icon {...props} />
}

export default MessageIcon
