export const getMsg = (resources) =>
  resources.flats.msg ||
  resources.entries.msg ||
  resources.countries.msg ||
  resources.areas.msg ||
  resources.citizens.msg ||
  resources.houses.msg ||
  resources.streets.msg

export const getError = (resources) =>
  resources.flats.error ||
  resources.entries.error ||
  resources.countries.error ||
  resources.areas.error ||
  resources.citizens.error ||
  resources.houses.error ||
  resources.streets.error
