import React from 'react'
import styled from 'styled-components'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import { Wrapper } from 'sputnik-ui'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const CardWrapper = styled(Wrapper)`
  padding: 0;
  padding-bottom: 1rem;
  overflow: hidden;
  flex-direction: column;
  background: ${(p) => p.theme.palette.background.primary};
`

const Header = styled(CardHeader)`
  && {
    background-color: ${(p) =>
      p.whiteHeader
        ? p.theme.palette.white[500]
        : p.theme.palette.primary[500]};
    color: ${(p) =>
      p.whiteHeader
        ? p.theme.palette.white.contrastText
        : p.theme.palette.primary.contrastText};
    height: 80px;
    position: sticky;
    z-index: 2;
    top: 0;
  }
`

const Card = ({
  children,
  titleIcon,
  title,
  actionIcon,
  classes,
  action,
  toggleSearch,

  whiteHeader,
  onSearch,
}) => (
  <CardWrapper shadow={false}>
    <Header
      avatar={titleIcon}
      title={
        <Typography color="inherit" style={{ fontSize: '1.2rem' }}>
          {title}
        </Typography>
      }
      // action={
      //   <IconButton onClick={toggleSearch} color="inherit">
      //     {actionIcon || (
      //       <Tooltip title="Поиск" placement="bottom">
      //         <SearchIcon />
      //       </Tooltip>
      //     )}
      //   </IconButton>
      // }
      whiteHeader={whiteHeader}
      classes={{
        action: classes.cardAction,
        avatar: classes.avatar,
      }}
    />

    <CardContent className={classes.cardContent}>
      <List component="nav" className={classes.list} dense>
        {children}
      </List>
    </CardContent>
  </CardWrapper>
)

export default withStyles(styles)(Card)
