import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import DropdownIcon from 'components/atoms/DropdownIcon'
import Collapse from 'components/atoms/Collapse'
import styled from 'styled-components'
import { Wrapper } from 'sputnik-ui'

const Wrap = styled(Wrapper)`
  padding: 1rem;
  display: grid;
  /* grid-gap: 1rem; */
  grid-auto-columns: 1fr;

  background: ${(p) => p.theme.palette.background.secondary};
`

const Body = styled.div`
  /* background: ${(p) => p.theme.palette.background.primary}; */
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  /* padding: 0.325rem 0; */
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.5;
  }
`

export default function Accordion({
  title,
  children,
  bodyProps = {},
  ...props
}) {
  const [open, setOpen] = useState(false)
  return (
    <Wrap border={false} shadow={false} {...props}>
      <Body onClick={() => setOpen(!open)} {...bodyProps}>
        <Typography>{title}</Typography>

        <DropdownIcon open={open} />
      </Body>
      <Collapse open={open}>
        <div style={{ height: '1rem' }} />
        {typeof children === 'function' ? children({ open }) : children}
      </Collapse>
    </Wrap>
  )
}
