import * as types from './types'

export const fetchAccessCodesRequest = () => ({
  type: types.FETCH_ACCESS_CODES_REQUEST,
})
export const fetchAccessCodesSuccess = (accessCodes) => ({
  type: types.FETCH_ACCESS_CODES_SUCCESS,
  payload: {
    accessCodes,
  },
})
export const fetchAccessCodesFailure = (error) => ({
  type: types.FETCH_ACCESS_CODES_FAILURE,
  payload: {
    error,
  },
})

export const addAccessCodeRequest = () => ({
  type: types.ADD_ACCESS_CODE_REQUEST,
})
export const addAccessCodeSuccess = (msg) => ({
  type: types.ADD_ACCESS_CODE_SUCCESS,
  payload: {
    msg,
  },
})
export const addAccessCodeFailure = (error) => ({
  type: types.ADD_ACCESS_CODE_FAILURE,
  payload: {
    error,
  },
})

export const removeAccessCodeRequest = () => ({
  type: types.REMOVE_ACCESS_CODE_REQUEST,
})
export const removeAccessCodeSuccess = (msg) => ({
  type: types.REMOVE_ACCESS_CODE_SUCCESS,
  payload: {
    msg,
  },
})
export const removeAccessCodeFailure = (error) => ({
  type: types.REMOVE_ACCESS_CODE_FAILURE,
  payload: {
    error,
  },
})
