// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'

import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - (100vw - 100%));
  height: 100%;
`

const Content = styled(Container)`
  display: flex;
  padding: 1.625rem;
  padding-bottom: 2rem;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    width: calc(50% - (1.625rem / 2));
    margin-bottom: 1.625rem;

    @media screen and (max-width: 768px) {
      width: calc(100%);
    }
  }
`

const CountryTemplate = ({ header, children, margin, subheader, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

// CountryTemplate.propTypes = {
//   subheader: PropTypes.node,
//   children: PropTypes.any.isRequired,
//   margin: PropTypes.string,
// }

export default CountryTemplate
