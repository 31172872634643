import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'

export const fetchAvailable = createAsyncThunk(
  'installations/fetchAvailable',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { q, order = 'desc', page = 1, limit = 25 } = params
    params.with_gates = true
    try {
      const payload = await API.request(
        buildUrl('/installation/devices', params),
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  }
)

export const linkDevice = createAsyncThunk(
  'installations/linkDevice',
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        buildUrl(`/installation_points/link_device`, params),
        {
          method: 'PUT',
        }
      )
      return payload
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

export const manualGateDevice = createAsyncThunk(
  '',
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        buildUrl(`/installation_points`, params),
        {
          method: 'GET',
        }
      )
      return payload
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

const initialState = {
  available: [],
  loadingAvailable: false,
  search: '',
  msg: '',
  error: '',
  linkPending: false,
}

// reducers
export const installationsSlice = createSlice({
  name: 'installations',
  initialState,
  reducers: {
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
  },
  extraReducers: {
    [fetchAvailable.pending]: (state) => {
      state.loadingAvailable = true
    },
    [fetchAvailable.rejected]: (state) => {
      state.loadingAvailable = false
    },
    [fetchAvailable.fulfilled]: (state, { payload }) => {
      state.loadingAvailable = false
      state.available = payload.devices.map((device) => ({
        label: device.serial_number,
        value: device.uuid,
        type: device.type,
      }))
    },
    [linkDevice.pending]: (state) => {
      state.linkPending = true
      state.msg = ''
      state.error = ''
    },
    [linkDevice.fulfilled]: (state, { payload }) => {
      state.msg = 'Устройство было успешно привязано'
      state.linkPending = false
    },
    [linkDevice.rejected]: (state, { payload }) => {
      state.error = payload
      state.linkPending = false
    },
    [CLEAR_RESOURCES]: () => initialState,
    [CLEAR_STATUS]: (state) => {
      state.msg = ''
      state.error = ''
    },
  },
})

export const { setSearch } = installationsSlice.actions

export default installationsSlice.reducer
