import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  /* box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06); */
  border-radius: 20px;
  background-color: ${(p) => p.theme.palette.migration.background.white};
  color: ${(p) => p.theme.palette.color.secondary};
`

export const Card = ({ children, ...props }) => (
  <StyledCard {...props}>{children}</StyledCard>
)
