import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Toggle from 'shared/ui/Toggle/Toggle'
import {
  Button,
  FormModal,
  FormModalActions,
  FormModalContent,
  FormModalTitle,
  Input,
  Typography,
} from 'sputnik-ui'
import {
  createExtraKeyReader,
  fetchIntercomByUuid,
} from 'store/devices/operations'

const deviceTypesVariables = { entry: 'entry', barrier: 'barrier' }

export const ConnectSecondDoorModal = ({
  serial,
  currnentDeviceUuid,
  open,
  onClose,
}) => {
  const { t } = useTranslation(['common', 'devices'])
  const dispatch = useDispatch()

  const [selectedDeviceType, setSelectedDeviceType] = useState(
    deviceTypesVariables.entry
  )

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose && onClose()
  }

  const handleSubmit = ({ alias, httpLink, uuid }) => {
    setLoading(true)
    dispatch(
      createExtraKeyReader({
        uuid,
        kind: selectedDeviceType,
        alias,
        httpLink,
      })
    ).then(() => {
      dispatch(fetchIntercomByUuid(uuid))
      handleClose()
      setLoading(false)
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      uuid: currnentDeviceUuid,
      alias: undefined,
      httpLink: undefined,
    },
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleChangeDeviceType = () => {
    selectedDeviceType === deviceTypesVariables.entry
      ? setSelectedDeviceType(deviceTypesVariables.barrier)
      : (() => {
          setSelectedDeviceType(deviceTypesVariables.entry)
          formik.setFieldValue('httpLink', undefined)
        })()
  }

  return (
    <FormModal open={open} onClose={handleClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <FormModalTitle>
          <Typography>{t('devices:extraKeyReader.title')}</Typography>
        </FormModalTitle>
        <FormModalContent>
          <Typography style={{ marginBottom: '1.5rem' }} color="textSecondary">
            {t('devices:extraKeyReader.subtitle')}
          </Typography>
          <Box display="flex" flexDirection="column" gridGap="2rem">
            <Input
              fullWidth
              value={serial}
              disabled
              label={t('devices:device_number')}
            />
            <Toggle
              style={{ margin: '0 auto', maxWidth: 320 }}
              label1={t('devices:extraKeyReader.entry')}
              label2={t('devices:extraKeyReader.barrier')}
              checked={selectedDeviceType === deviceTypesVariables.barrier}
              onChange={handleChangeDeviceType}
            />
            {selectedDeviceType === deviceTypesVariables.barrier && (
              <Input
                name="httpLink"
                placeholder={t('devices:extraKeyReader.enter_url_placeholder')}
                onChange={formik.handleChange}
                value={formik.values.httpLink}
                fullWidth
                label={t('devices:extraKeyReader.http_link')}
              />
            )}
            <Input
              name="alias"
              placeholder={t('devices:extraKeyReader.text_comment_placeholder')}
              maxLength={10}
              onChange={formik.handleChange}
              value={formik.values.alias}
              fullWidth
              label={t('devices:extraKeyReader.text_comment')}
            />
          </Box>
        </FormModalContent>
        <FormModalActions>
          <Button
            type="submit"
            fullWidth
            primary
            variant="v2"
            onClick={formik.handleSubmit}
            disabled={!serial || loading}
          >
            {t('common:button.connect')}
          </Button>
          <Button fullWidth tertiary variant="v2" onClick={handleClose}>
            {t('common:button.back')}
          </Button>
        </FormModalActions>
      </form>
    </FormModal>
  )
}
