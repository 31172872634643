import { CLEAR_STATUS } from 'store/ui/types'
import * as types from './types'

const initialState = {
  current: null,
  msg: null,
  error: null,
}

export default function gates(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_GATE_SUCCESS:
      return { ...state, current: payload.gate }
    case types.UPDATE_GATE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.UPDATE_GATE_FAILURE:
      return { ...state, error: payload }
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
