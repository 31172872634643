import * as types from './types'

/*  countries  */

export const getCountriesRequest = () => ({
  type: types.GET_COUNTRIES_REQUEST,
})
export const getCountriesSuccess = (countries) => ({
  type: types.GET_COUNTRIES_SUCCESS,
  payload: countries,
})
export const getCountriesError = (error) => ({
  type: types.GET_COUNTRIES_ERROR,
  payload: error,
})

export const fetchCountryRequest = () => ({
  type: types.FETCH_COUNTRY_REQUEST,
})
export const fetchCountrySuccess = (country) => ({
  type: types.FETCH_COUNTRY_SUCCESS,
  payload: { country },
})
export const fetchCountryFailure = (error) => ({
  type: types.FETCH_COUNTRY_FAILURE,
  payload: { error },
})
