import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'
import * as types from './types'

const initialState = {
  list: null,
}

export default function localities(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_LOCALITIES_SUCCESS:
      return { ...state, list: payload, error: null }
    case types.GET_LOCALITIES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_LOCALITY_SUCCESS:
      return { ...state, current: payload.locality, error: null }
    case types.FETCH_LOCALITY_FAILURE:
      return { ...state, error: payload.error }
    case CLEAR_RESOURCES:
      return initialState
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
