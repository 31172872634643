import * as types from './types'

/* localities */

export const getLocalitiesRequest = () => ({
  type: types.GET_LOCALITIES_REQUEST,
})
export const getLocalitiesSuccess = (localities) => ({
  type: types.GET_LOCALITIES_SUCCESS,
  payload: localities,
})
export const getLocalitiesError = (error) => ({
  type: types.GET_LOCALITIES_ERROR,
  payload: error,
})

export const fetchLocalityRequest = () => ({
  type: types.FETCH_LOCALITY_REQUEST,
})
export const fetchLocalitySuccess = (locality) => ({
  type: types.FETCH_LOCALITY_SUCCESS,
  payload: { locality },
})
export const fetchLocalityFailure = (error) => ({
  type: types.FETCH_LOCALITY_FAILURE,
  payload: { error },
})
