import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IntercomIcon } from 'assets/intercom.svg'
import { formatDeviceId } from 'sputnik-ui'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CameraIcon } from 'assets/WhiteCamera.svg'
import { useTranslation } from 'react-i18next'
import { getDeviceTypeByNumber } from 'utils/helpers'
import WarrantyCard from './WarrantyCard'

const StyledText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #c4c4c4;
`

const StyledDates = styled.div`
  font-size: 12px;
  line-height: 16.34px;
  font-weight: 600;
  color: #c4c4c4;
`

const StatusLine = styled.div`
  position: absolute;
  height: 130%;
  width: 6px;
  top: -1rem;
  right: 0;
  background: ${(p) => p.color};
`
const StatusCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #c4c4c4;
  margin-right: 12px;
`

function WarrantyItem({
  color,
  number,
  status,
  created_at,
  updated_at,
  device_number,
  locale_status,
  surname,
  name,
  paid,
}) {
  const history = useHistory()
  const { t } = useTranslation(['warranty'])
  const showCurrent = () => {
    history.push(`/warranty/${number}`)
  }

  return (
    <WarrantyCard onClick={showCurrent} style={{ cursor: 'pointer' }}>
      <StatusLine color={color} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <StatusCircle>
            {getDeviceTypeByNumber(device_number) === 'camera' ? (
              <CameraIcon />
            ) : (
              <IntercomIcon />
            )}
          </StatusCircle>

          <div style={{ fontWeight: 600 }}>
            <div style={{ marginBottom: 6 }}>{locale_status}</div>
            <div style={{ fontSize: '0.875rem', fontWeight: 500 }}>
              {formatDeviceId(device_number)}
            </div>
          </div>
        </div>
        <StyledText>#{number}</StyledText>
      </div>

      <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
        <StyledDates
          style={{
            marginTop: 12,
          }}
        >
          {paid
            ? t('warranty:warranty_not_available')
            : t('warranty:warranty_available')}
          {/* TODO: translation TR */}
        </StyledDates>
        <StyledDates
          style={{
            marginTop: 4,
          }}
        >
          {t('warranty:created')} {created_at}
        </StyledDates>
        <StyledDates
          style={{
            marginTop: 4,
          }}
        >
          {t('warranty:updated')} {updated_at}
        </StyledDates>
        <StyledDates
          style={{
            marginTop: 4,
          }}
        >
          {t('warranty:by')}: {`${name} ${surname}`}
        </StyledDates>
      </div>
    </WarrantyCard>
  )
}

export default React.memo(WarrantyItem)
