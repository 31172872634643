// @flow
import * as React from 'react'
import { TwoChildrenTemplate, Regions, Localities } from 'components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { clearResources } from 'store/actions'
import {
  fetchCountry,
  getRegions,
  getLocalities,
} from 'store/resources/operations'

function CountryPage({
  dispatch,
  match: {
    params: { country },
  },
}) {
  React.useEffect(() => {
    if (country) {
      dispatch(fetchCountry(country))
      dispatch(getRegions({ parentUuid: country }))
      dispatch(getLocalities({ parentUuid: country }))
    }
    return () => dispatch(clearResources())
  }, [dispatch, country])
  return (
    <TwoChildrenTemplate>
      <Regions />
      <Localities whiteHeader />
    </TwoChildrenTemplate>
  )
}

export default connect()(withRouter(CountryPage))
