import Api from 'utils/api'
import * as actions from './actions'

// localities

export const getLocalities =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getLocalitiesRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const localities = await Api.request(`/localities${setParams()}`)

      dispatch(actions.getLocalitiesSuccess(localities))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getLocalitiesError(message))
    }
  }

export const fetchLocality = (localityUuid) => async (dispatch) => {
  dispatch(actions.fetchLocalityRequest())
  try {
    const locality = await Api.request(`/localities/${localityUuid}`)

    dispatch(actions.fetchLocalitySuccess(locality))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchLocalityFailure(message))
  }
}
