// @flow
import * as React from 'react'
import { SingleChildTemplate, Houses } from 'components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { clearResources } from 'store/actions'
import { fetchStreet, getHouses } from 'store/resources/operations'

function StreetPage({
  dispatch,
  match: {
    params: { street },
  },
}) {
  React.useEffect(() => {
    if (street) {
      dispatch(fetchStreet(street))
      dispatch(getHouses({ parentUuid: street }))
    }
    return () => dispatch(clearResources())
  }, [dispatch, street])
  return (
    <SingleChildTemplate>
      <Houses />
    </SingleChildTemplate>
  )
}

export default connect()(withRouter(StreetPage))
