import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { ReactComponent as CitizenIcon } from 'assets/Citizen.svg'
import { formatDate } from 'utils/helpers'
import styled from 'styled-components'

const IconWrapper = styled(ListItemIcon)`
  && {
    margin-right: 1rem;
    color: ${(p) => p.theme.palette.other.greenDark};
    background-color: ${(p) => p.theme.palette.other.green};
    border-radius: 50%;
    min-width: 0;
  }
`

const CitizenOrder = ({ message, time, short_address, ...props }) => (
  <ListItem
    button
    onClick={() => console.log()}
    disableRipple
    style={{ borderRadius: 8 }}
  >
    <IconWrapper>
      <CitizenIcon />
    </IconWrapper>
    <ListItemText
      primary={message}
      secondary={`${short_address} · ${formatDate(time)}`}
    />
  </ListItem>
)

export default CitizenOrder
