import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'
import * as types from './types'

const initialState = {
  loading: false,
}

export default function flats(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_FLATS_SUCCESS:
      return { ...state, list: payload, error: null }
    case types.GET_FLATS_ERROR:
      return { ...state, error: payload }
    case types.CHANGE_FLAT_RANGE_SUCCESS:
      return { ...state, msg: payload }
    case types.CHANGE_FLAT_RANGE_ERROR:
      return { ...state, error: payload }
    case types.FETCH_FLAT_SUCCESS:
      return { ...state, current: payload.flat, error: null }
    case types.FETCH_FLAT_FAILURE:
      return { ...state, error: payload.error }
    case types.CHANGE_FLAT_CONFIG_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.CHANGE_FLAT_CONFIG_FAILURE:
      return { ...state, error: payload.error }
    case types.UPDATE_FLAT_REQUEST:
      return { ...state, loading: true }
    case types.UPDATE_FLAT_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.UPDATE_FLAT_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case CLEAR_RESOURCES:
      return initialState
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
