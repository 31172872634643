import * as types from './types'

/* entries */

export const getEntriesRequest = () => ({
  type: types.GET_ENTRIES_REQUEST,
})
export const getEntriesSuccess = (entries) => ({
  type: types.GET_ENTRIES_SUCCESS,
  payload: entries,
})
export const getEntriesError = (error) => ({
  type: types.GET_ENTRIES_ERROR,
  payload: error,
})

export const fetchEntryRequest = () => ({
  type: types.FETCH_ENTRY_REQUEST,
})
export const fetchEntrySuccess = (entry) => ({
  type: types.FETCH_ENTRY_SUCCESS,
  payload: { entry },
})
export const fetchEntryFailure = (error) => ({
  type: types.FETCH_ENTRY_FAILURE,
  payload: { error },
})
