import { Link } from 'react-router-dom'
import styled from 'styled-components'

export default styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  text-decoration: none;
  cursor: pointer;
  font-family: ${(p) => p.theme.fonts.primary};
  &:hover {
    color: ${(p) => p.theme.palette.primary.lighter};
  }
`
