import React from 'react'
import { Wrapper } from 'sputnik-ui'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { SyncProblem } from '@material-ui/icons'
import i18next from 'i18next'

const Alert = styled(Wrapper)`
  background: ${(p) => p.theme.palette.error[50]};
  flex-direction: column;
`

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}

const getSettingName = (setting) => {
  switch (setting) {
    case 'base':
      return i18next.t('devices:sync_status.commutator')
    case 'flats':
      return i18next.t('devices:sync_status.flats')
    case 'sound':
      return i18next.t('devices:sync_status.sound')
    default:
      return null
  }
}

const Setting = ({ children }) => (
  <Typography color="error" style={styles.row}>
    <SyncProblem style={{ marginRight: 8 }} />
    {getSettingName(children)} {i18next.t('devices:sync_status.no_sync')}
  </Typography>
)

const hasUnsyncedSettings = (configSync) =>
  Object.values(configSync).reduce((acc, cur) => {
    if (!acc) {
      return !cur
    }
    return acc
  }, false)

export default function SyncStatus({ configSync, ...props }) {
  return configSync && hasUnsyncedSettings(configSync) ? (
    <Alert {...props}>
      {Object.entries(configSync).map(([key, val]) =>
        !val ? <Setting key={key}>{key}</Setting> : null
      )}
    </Alert>
  ) : null
}
