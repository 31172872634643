import CameraModal from 'components/organisms/CameraModal'
import { unlinkCamera } from 'features/Cameras/camerasSlice'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card } from 'shared/ui'
import { Button, ConfirmModal, Loader, Typography } from 'sputnik-ui'

import { AddressDeviceElement } from '../AddressDeviceElement/AddressDeviceElement'

export const AddressDevices = ({
  selectArchiveDevice,
  isSelected,
  ...props
}) => {
  const { t } = useTranslation(['devices', 'messages'])
  const history = useHistory()
  const dispatch = useDispatch()
  const { list, pending } = useSelector(({ devicesNew }) => devicesNew)
  const [isMainDeviceModal, setMainDeviceModal] = useState(false)
  const [isDisconnectModal, setDisconnectModal] = useState(false)
  const [cameraModalOpen, setCameraModalOpen] = useState(false)
  const [currentCamera, setCurrentCamera] = useState(null)
  const [currentDevice, setCurrentDevice] = useState(null)
  const openCameraModal = (camera) => {
    setCameraModalOpen(true)
    setCurrentCamera(camera)
  }
  const closeModal = () => {
    setCameraModalOpen(false)
    setCurrentCamera(null)
  }

  const makeMainDevice = () => {
    setMainDeviceModal(!isMainDeviceModal)
  }

  const openDisconnectDeviceModal = (device) => {
    setCurrentDevice(device)
    setDisconnectModal(!isDisconnectModal)
  }
  const closeDisconnectDeviceModal = () => {
    setCurrentDevice(null)
    setDisconnectModal(!isDisconnectModal)
  }
  const handleDisconnectDevice = () => {
    const { uuid, type } = currentDevice
    if (type === 'camera') {
      dispatch(unlinkCamera(uuid))
    }
  }

  const goToDeviceZonePage = (uuid) => {
    // feature
    history.push('/device_zone/1')
  }

  const goToAccessFlatDevicePage = (uuid) => {
    // feature
    history.push('/access_device/1')
  }

  const goToDevicePage = (uuid) => {
    history.push(`/intercom/${uuid}`)
  }

  if (pending)
    return (
      <Card>
        <Loader />
      </Card>
    )
  return (
    <>
      <CameraModal
        open={cameraModalOpen}
        handleClose={closeModal}
        uuid={currentCamera?.uuid}
      />
      <Card {...props}>
        <Typography variant="title" style={{ marginBottom: 24 }}>
          {t('devices:control.device_address')}
        </Typography>
        {!list?.length && !pending ? (
          <Typography color="textSecondary">
            {t('messages:content.no_devices')}
          </Typography>
        ) : (
          list?.map((device, index) => (
            <AddressDeviceElement
              key={device.uuid}
              selected={isSelected}
              device={device}
              isCamera={Boolean(device.type === 'camera')}
              style={{ marginTop: index === 0 && 0 }}
              selectArchiveDevice={selectArchiveDevice}
              goToDevicePage={goToDevicePage}
              openCameraModal={openCameraModal}
              makeMain={makeMainDevice} // feature
              disconnectDevice={openDisconnectDeviceModal} // feature
              goToDeviceZone={goToDeviceZonePage} // feature
              goToDeviceAccess={goToAccessFlatDevicePage} // feature
            />
          ))
        )}
        {/* <Typography variant="title" style={{ marginBottom: 24, marginTop: 32 }}>
          Устройства с доступом на адресе (Дополнительные)
        </Typography> */}
      </Card>
      {/* <ConfirmModal
        open={isMainDeviceModal}
        title="Вы уверены, что хотите привязать устройство к этому адресу?"
        label="Устройство привязано к другому адресу. Хотите отвязать от текущего и привязать к этому?"
        actions={
          <>
            <Button variant="v2" primary fullWidth>
              Привязать
            </Button>
            <Button variant="v2" tertiary onClick={makeMainDevice}>
              Назад
            </Button>
          </>
        }
      /> */}
      <ConfirmModal
        open={isDisconnectModal}
        title={t('devices:cameras.confirm_unlink')}
        label={t('devices:cameras.become_unavailable')}
        actions={
          <>
            <Button
              variant="v2"
              primary
              fullWidth
              onClick={handleDisconnectDevice}
            >
              {t('common:button.unconnect')}
            </Button>
            <Button variant="v2" tertiary onClick={closeDisconnectDeviceModal}>
              {t('common:button.back')}
            </Button>
          </>
        }
      />
    </>
  )
}
