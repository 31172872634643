import * as types from './types'
import { CLEAR_STATUS } from '../ui/types'

const initialState = {
  list: [],
  current: null,
  error: null,
  msg: null,
  loading: false,
  loadingArhive: false,
  archivingOrder: false,
  archivedPages: null,
  notFound: false,
}

export default function orders(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_ORDERS_REQUEST:
      return { ...state, loading: true }
    case types.FETCH_ORDERS_SUCCESS:
      return payload.isFirstPage
        ? {
            ...state,
            list: payload.orders,
            loading: false,
            count: payload.count,
          }
        : {
            ...state,
            list: [...state.list, ...payload.orders],
            loading: false,
            count: payload.count,
          }

    case types.FETCH_ORDERS_FAILURE:
      return { ...state, loading: false, error: payload.error }

    case types.RECEIVE_NEW_ORDER:
      return {
        ...state,
        list: state.list ? [payload.order, ...state.list] : null,
      }
    case types.UPDATE_CURRENT_ORDER:
      return { ...state, current: payload.order }
    case types.UPDATE_ORDERS_LIST:
      if (!state.list) return state
      if (payload.order.archived) {
        return {
          ...state,
          list: state.list.filter((order) => order.id !== payload.order.id),
        }
      }
      return {
        ...state,
        list: state.list.map((order) =>
          order.id === payload.order.id ? payload.order : order
        ),
      }
    case types.FETCH_ARCHIVED_ORDERS_REQUEST:
      return { ...state, loadingArchive: true }
    case types.FETCH_ARCHIVED_ORDERS_FAILURE:
      return { ...state, loadingArchive: false, error: payload.error }
    case types.FETCH_ARCHIVED_ORDERS_SUCCESS:
      return payload.isFirstPage
        ? {
            ...state,
            archived: payload.orders,
            loadingArchive: false,
            archivePages: payload.archivePages,
          }
        : {
            ...state,
            archived: [...state.archived, ...payload.orders],
            loadingArchive: false,
            archivePages: payload.archivePages,
          }

    case types.FETCH_ORDER_REQUEST:
      return { ...state, notFound: false }
    case types.FETCH_ORDER_SUCCESS:
      return { ...state, current: payload.order }
    case types.FETCH_ORDER_FAILURE:
      return { ...state, notFound: true }

    case types.COMPLETE_ORDER_REQUEST:
      return { ...state, archivingOrder: true }
    case types.COMPLETE_ORDER_SUCCESS:
      return { ...state, msg: payload.msg, archivingOrder: false }
    case types.COMPLETE_ORDER_FAILURE:
      return { ...state, error: payload.error, archivingOrder: false }

    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    case types.CLEAR_CURRENT_ORDER:
      return { ...state, current: null, notFound: false }
    case types.CLEAR_ORDERS:
      return initialState
    default:
      return state
  }
}
