/* localities */
export const GET_LOCALITIES_REQUEST =
  'resources/localities/GET_LOCALITIES_REQUEST'
export const GET_LOCALITIES_SUCCESS =
  'resources/localities/GET_LOCALITIES_SUCCESS'
export const GET_LOCALITIES_ERROR = 'resources/localities/GET_LOCALITIES_ERROR'

export const FETCH_LOCALITY_REQUEST =
  'resources/localities/FETCH_LOCALITY_REQUEST'
export const FETCH_LOCALITY_SUCCESS =
  'resources/localities/FETCH_LOCALITY_SUCCESS'
export const FETCH_LOCALITY_FAILURE =
  'resources/localities/FETCH_LOCALITY_FAILURE'
