import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import LocationCityIcon from '@material-ui/icons/LocationCity'

import { getStreets } from 'store/operations'
import { selectStreet } from 'store/actions'
import { useTranslation } from 'react-i18next'

const searchStreets = (props, query) => {
  const { match, dispatch } = props

  const parent_uuid = match.params.locality
  const q = query || null

  dispatch(
    getStreets({
      parent_uuid,
      q,
    })
  )
}

const Streets = ({ streets, err, dispatch, match, history, ...props }) => {
  const { t } = useTranslation('geo')
  return (
    <Card
      title={t('geo:locations.street_plural')}
      titleIcon={<LocationCityIcon />}
      {...props}
    >
      {streets ? (
        streets.map((s) => (
          <ListItem
            key={s.uuid}
            button
            onClick={() => {
              dispatch(selectStreet(s))
              history.push(`/s/${s.uuid}`)
            }}
          >
            <ListItemIcon>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText primary={s.full_name} />
            <ListItemSecondaryAction style={{ paddingRight: '1rem' }}>
              <ArrowForwardIcon />
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.streets')} err={err} fluid />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  streets: resources.streets.list,
  err: resources.streets.err,
})

let toExp = withSearch(searchStreets)(Streets)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
