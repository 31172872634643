import * as types from './types'

export const signIn = (user) => ({
  type: types.SIGN_IN,
  payload: user,
})

export const signInError = (error) => ({
  type: types.SIGN_IN_ERROR,
  payload: error,
})

export const checkAuth = () => ({
  type: types.CHECK_AUTH,
})

export const logOutRequest = () => ({ type: types.LOG_OUT_REQUEST })

export const logOutSuccess = () => ({ type: types.LOG_OUT_SUCCESS })

export const logOutError = () => ({ type: types.LOG_OUT_ERROR })
