import React from 'react'
import { Button } from 'sputnik-ui'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function ConfirmActionModal({
  open,
  handleClose,
  action,
  description,
  handleAction,
  loading,
}) {
  const { t } = useTranslation(['common', 'messages'])
  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <DialogTitle color="textSecondary" style={{ fontWeight: 400 }}>
        {t('messages:action.confirm')}{' '}
        <span display="inline" style={{ textTransform: 'lowercase' }}>
          {action}
        </span>
        ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description || t('messages:action.undone')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} secondary rounded>
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={handleAction}
          rounded
          color="danger"
          variant="contained"
          disabled={loading}
        >
          {t('common:button.confirm')}
          {loading && '...'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmActionModal
