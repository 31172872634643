import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const Title = styled(Typography)`
  && {
    -webkit-font-smoothing: antialiased;
    font-size: 2rem;
    line-height: 2rem;
    padding: 0;
  }
`

const Subtitle = styled(Typography)`
  && {
    color: #8b959e;
    text-align: center;
    margin-top: 0.875rem;
    max-width: 38rem;
    margin: auto;
    line-height: 1.5rem;
    margin: 1rem auto 0;
    padding: 0 0.5rem;
    word-break: break-word;
    font-size: 1rem;
  }
`

const Wrapper = styled.div`
  padding: 2rem 5.75rem;
`

const FullscreenDialogTitle = ({ children, subtitle, ...props }) => (
  <Wrapper {...props}>
    <Title align="center">{children}</Title>
    {subtitle && <Subtitle color="textSecondary">{subtitle}</Subtitle>}
  </Wrapper>
)

export default FullscreenDialogTitle
