import React, { useEffect, useCallback, useState } from 'react'
import {
  EntryPageTemplate,
  Flats,
  // Device,
  // Map,
  Keys,
  // InstallationPointActions,
} from 'components'
import { useParams } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'

import Map from 'components/atoms/Map'
import AccessCodes from 'components/molecules/AccessCodes'

import ChangeFlatRangeModal from 'components/organisms/ChangeFlatRangeModal'
// import AttachNewIntercomModal from 'components/organisms/AttachNewIntercomModal'
import AttachNewDeviceModal from 'features/Devices/AttachNewDeviceModal'
import useIntercomsListStatus from 'hooks/useIntercomsListStatus'
import {
  getIntercomsByAddress,
  fetchEntry,
  getKeys,
  getCamerasByAddress,
} from 'store/operations'
import { clearResources } from 'store/actions'
import { getFlats } from 'store/resources/operations'
import { updateIntercomsListStatus } from 'store/devices/actions'
import { fetchMapIntercoms } from 'store/maps/operations'
import { updateGate } from 'store/gates/operations'
import { fetchDevices, clearDevices } from 'features/Devices/devicesSlice'
import { useTranslation } from 'react-i18next'
import ModalVideoArchive from 'features/Entry/ModalVideoArchive'
import { PageMenu, PageMenuButton, PageMenuList } from 'shared/ui'
import { AddressDevices, AddressNote } from 'features/AddressDevices'
import { CitizensListPro } from 'features/CitizensListPro'

const KeysPro = React.lazy(() => import('features/Keys'))

function EntryPage({
  dispatch,
  intercoms,
  error,
  lat,
  lon,
  mapIntercoms,
  ...props
}) {
  const [changeFlatRangeModal, setChangeFlatRangeModal] = useState(false)
  const [attachNewDeviceModal, setAttachNewDeviceModal] = useState(false)
  const [downloadVideoArchive, setDownloadVideoArchive] = useState(false)
  const [selected, setSelected] = useState(null)
  const [isCamera, setIsCamera] = useState(false)

  const { entry } = useParams()
  const { t } = useTranslation(['devices'])

  const onMsgReceived = useCallback(
    ({ uuid, is_online }) => {
      dispatch(
        updateIntercomsListStatus({ uuid, is_online: is_online === 'true' })
      )
    },
    [dispatch]
  )

  useIntercomsListStatus(entry, onMsgReceived)
  const { pro } = useSelector(({ auth }) => auth.user)

  useEffect(() => {
    if (entry) {
      dispatch(fetchEntry(entry))
      dispatch(getFlats({ parentUuid: entry }))
      dispatch(getKeys(entry, 1, 1))

      dispatch(fetchMapIntercoms(entry))

      dispatch(fetchDevices({ address_uuid: entry }))
    }
    return () => dispatch(clearDevices())
  }, [dispatch, entry])

  // const entryHistoryFeature = useFeature('control-web/entry_history')

  const modalArchiveToggle = () => {
    setDownloadVideoArchive(!downloadVideoArchive)
  }

  const selectArchiveDevice = (deviceId, camera = false, manual = false) => {
    setIsCamera(camera)
    setSelected(deviceId)
    if (manual) modalArchiveToggle()
  }

  return (
    <EntryPageTemplate
      map={
        <Map
          initialMarkers={mapIntercoms}
          center={{ lat, lon }}
          withConfirm
          onUpdate={({ uuid, lat, lon }) =>
            dispatch(updateGate({ uuid, lat, lon }))
          }
        />
      }
      left={<Flats setChangeFlatRangeModal={setChangeFlatRangeModal} />}
      right={
        <>
          <AddressDevices
            isSelected={selected}
            selectDeviceArchive={setSelected}
            selectArchiveDevice={selectArchiveDevice}
          />
          {pro && <CitizensListPro />}
          {!pro ? <Keys add /> : <KeysPro />}
          <AccessCodes />
          <AddressNote />
          <PageMenu>
            <div />
            <PageMenuList style={{ gap: '16px' }}>
              <PageMenuButton
                variant="v2"
                subtle
                onClick={() => setAttachNewDeviceModal(true)}
              >
                {t('devices:control.add')}
              </PageMenuButton>
              {/* <PageMenuButton variant="v2" subtle disabled={!selected} onClick={modalArchiveToggle}>
                {t('devices:video_archive.action')}
              </PageMenuButton> */}
            </PageMenuList>
          </PageMenu>

          <ModalVideoArchive
            selected={selected}
            isCamera={isCamera}
            open={downloadVideoArchive}
            handleClose={modalArchiveToggle}
          />
          <AttachNewDeviceModal
            open={attachNewDeviceModal}
            handleClose={() => setAttachNewDeviceModal(false)}
          />
          <ChangeFlatRangeModal
            open={changeFlatRangeModal}
            handleClose={() => setChangeFlatRangeModal(false)}
          />
        </>
      }
    />
  )
}

const mapStateToProps = ({ devices, devicesNew, resources, keys, maps }) => ({
  intercoms: devicesNew.intercoms,
  // cameras: devicesNew.cameras,
  // deviceList: devicesNew.list,
  error: devices.error || resources.error || keys.error,
  msg: devices.msg || resources.msg || keys.msg,
  keys: keys.list,
  lat: resources.entries.current && resources.entries.current.lat,
  lon: resources.entries.current && resources.entries.current.lon,
  mapIntercoms: maps.intercoms,
})

export default connect(mapStateToProps)(EntryPage)
