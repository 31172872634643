import { useEffect, useRef, useCallback } from 'react'
import config from 'config'
import ActionCable from 'actioncable'

export default function useOrderStatus(orderId, onReceived) {
  const cable = useRef()
  const orderStatusSubscription = useRef()

  const createSocket = useCallback(() => {
    cable.current = ActionCable.createConsumer(config.socketUrl)
    orderStatusSubscription.current = cable.current.subscriptions.create(
      {
        channel: 'OrderUpdateChannel',
        room: `order_${orderId}`,
        order_id: orderId,
      },
      {
        received: onReceived,
      }
    )
  }, [orderId, onReceived])

  useEffect(() => {
    if (orderId && !cable.current) createSocket()

    return () => {
      if (cable.current && orderStatusSubscription.current) {
        cable.current.subscriptions.remove(orderStatusSubscription.current)
      }
    }
  }, [createSocket, orderId])

  return cable
}
