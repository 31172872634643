import Api from 'utils/api'
import * as actions from './actions'

export const changeFlatConfig =
  (flatUuid, call_blocked) => async (dispatch) => {
    dispatch(actions.changeFlatConfigRequest())
    try {
      const payload = await Api.request(`/flats/${flatUuid}/update_config`, {
        method: 'POST',
        body: JSON.stringify({ call_blocked }),
      })

      // const msg = call_blocked
      //   ? 'Уведомления были отключены'
      //   : 'Уведомления были включены'

      dispatch(actions.changeFlatConfigSuccess(payload?.message))
      return Promise.resolve()
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.changeFlatConfigFailure(message))
      return Promise.reject()
    }
  }

export const getFlats =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getFlatsRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}&with_subs=yes`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const flats = await Api.request(`/flats${setParams()}`)

      dispatch(actions.getFlatsSuccess(flats))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getFlatsError(message))
    }
  }

export const changeFlatRange =
  ({ entry, firstFlat, lastFlat }) =>
  async (dispatch) => {
    dispatch(actions.changeFlatRangeRequest())
    try {
      const payload = await Api.request(
        `/entries/${entry}/update_range?first_flat=${firstFlat}&last_flat=${lastFlat}`,
        { method: 'PUT' }
      )
      dispatch(actions.changeFlatRangeSuccess(payload?.message))
      return Promise.resolve()
    } catch (err) {
      const { error } = await err.json()
      dispatch(actions.changeFlatRangeError(error))
      return Promise.reject()
    }
  }

export const fetchFlat = (flatUuid) => async (dispatch) => {
  dispatch(actions.fetchFlatRequest())
  try {
    const entry = await Api.request(`/flats/${flatUuid}`)

    dispatch(actions.fetchFlatSuccess(entry))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchFlatFailure(message))
  }
}

// type IFlatConfig = {
//   call_type: string,
//   call_blocked: boolean,
//   parent_uuid: string,
//   flat_number: string,
//   owner_first_name: string,
//   owner_middle_name: string,
//   owner_last_name: string,
//   owner_phone: string,
//   notice: string,
// }
export const updateFlat =
  (flatUuid, config, isAccessCompany) => async (dispatch, getState) => {
    dispatch(actions.updateFlatRequest())
    // const isPro = getState().auth.user.pro

    try {
      let newFlat = {}
      if (flatUuid) {
        const proReq = `/flats/${flatUuid}/update_block_state`
        const defaultReq = `/flats/${flatUuid}`
        const currentReq = isAccessCompany ? proReq : defaultReq
        newFlat = await Api.request(currentReq, {
          method: 'PUT',
          body: JSON.stringify(config),
        })
      } else {
        newFlat = await Api.request(`/flats`, {
          method: 'POST',
          body: JSON.stringify(config),
        })
      }
      dispatch(actions.updateFlatSuccess(newFlat?.message))
      return Promise.resolve(newFlat)
    } catch (err) {
      const { error } = await err.json()
      dispatch(actions.updateFlatFailure(error))
      return Promise.reject()
    }
  }
