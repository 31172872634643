import { useState, useEffect } from 'react'

const getOrientation = () => window.screen.orientation.type

export const screenOrientations = {
  portraitPrimary: 'portrait-primary',
  portraitSecondary: 'portrait-secondary',
  landscapePrimary: 'landscape-primary',
  landscapeSecondary: 'landscape-secondary',
}

export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation())

  const updateOrientation = () => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation)
    return () => {
      window.removeEventListener('orientationchange', updateOrientation)
    }
  }, [])

  return orientation
}
