import React from 'react'
import OrderIcon from 'components/atoms/OrderIcon'
import styled from 'styled-components'

import { Typography, ListItemText, ListItem } from '@material-ui/core'

const getOrderColor = (variant) => {
  switch (variant) {
    case 'activation':
      return 'lightblue'
    case 'installation':
      return 'lightblue'
    case 'configuration':
      return 'peach'
    case 'citizen_request':
      return 'green'
    default:
      return 'pink'
  }
}

const OrderTypeText = styled(Typography)`
  && {
    color: ${(p) => `${p.theme.palette.other[getOrderColor(p.type)]}Dark`};
  }
`

const getPrimaryText = (order) => {
  switch (order.type) {
    case 'anomaly':
      return order.message.event
    case 'configuration':
      return order.message.full_name
    case 'installation':
      return order.message.full_name

    default:
      return order.message.full_name
  }
}

const getSecondaryText = (order) => {
  switch (order.type) {
    case 'configuration':
      return order.message.phone
    case 'installation':
      return order.message.phone
    case 'anomaly':
      return order.message.data
    case 'activation':
      return order.message.serial_number
    default:
      return null
  }
}

function OrderHeader({ order, ...props }) {
  return (
    <div>
      {' '}
      {/* <OrderTypeText type={order.type} variant="h5"></OrderTypeText> */}
      <ListItem style={{ padding: 0 }}>
        <OrderIcon style={{ height: 58, width: 58 }} variant={order.type} />
        <ListItemText
          disableTypography
          primary={
            <OrderTypeText variant="h5" type={order.type}>
              {getPrimaryText(order)}
            </OrderTypeText>
          }
          secondary={
            <Typography
              variant="h6"
              style={{ fontWeight: '400' }}
              color="textSecondary"
            >
              {getSecondaryText(order)}
            </Typography>
          }
        />
      </ListItem>
    </div>
  )
}

export default OrderHeader
