import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { keyDownWithClick } from 'utils/helpers'

const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
})

const Flags = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(auto-fit, 220px);
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    justify-content: center;
  }
  color: ${(p) => p.theme.palette.text.secondary};

  /* each flag */
  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    margin: 2rem 0;

    @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      margin-left: 10%;
    }

    img {
      filter: saturate(0.7);
      transition: filter 0.2s ease-in;
      border-radius: 16px;
      box-shadow: ${(p) => p.theme.shadows.main};
      width: 80%;
      height: 100px;

      object-fit: cover;
    }

    &:hover {
      & p {
        color: ${(p) => p.theme.palette.text.primary};
      }

      & img {
        filter: saturate(1);
      }

      & > div {
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.3);
      }
    }
  }
`

const Countries = ({ countries, classes, dispatch, history, ...props }) => {
  const { t } = useTranslation('geo')

  const handleRedirect = (event, country) => {
    keyDownWithClick(event, () => history.push(`/c/${country.uuid}`))
  }

  return countries && Array.isArray(countries) && countries.length > 0 ? (
    <div {...props}>
      <Typography variant="h5">{t('geo:countries.list')}</Typography>

      <Flags>
        {countries.map((c) => (
          <div
            key={c.uuid}
            onKeyDown={(event) => handleRedirect(event, c)}
            onClick={(event) => handleRedirect(event, c)}
            tabIndex="0"
            role="button"
          >
            <img alt={c.name} src={c.flag_url} />

            <Typography
              align="left"
              style={{
                marginTop: '.625rem',
                textTransform: 'Capitalize',
                transition: 'color 0.2s ease-in',
              }}
            >
              {c.name}
            </Typography>
          </div>
        ))}
      </Flags>
    </div>
  ) : null
}

const mapStateToProps = ({ resources }) => ({
  countries: resources.countries.list,
})

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(Countries))
)
