import * as types from './types'

export const fetchGateRequest = () => ({
  type: types.FETCH_GATE_REQUEST,
})
export const fetchGateSuccess = (gate) => ({
  type: types.FETCH_GATE_SUCCESS,
  payload: { gate },
})
export const fetchGateFailure = (error) => ({
  type: types.FETCH_GATE_FAILURE,
  payload: error,
})

export const updateGateRequest = () => ({
  type: types.UPDATE_GATE_REQUEST,
})
export const updateGateSuccess = (msg) => ({
  type: types.UPDATE_GATE_SUCCESS,
  payload: { msg },
})
export const updateGateFailure = (error) => ({
  type: types.UPDATE_GATE_FAILURE,
  payload: error,
})
