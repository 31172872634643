// @flow
import * as types from './types'

// type State = {
//   msg: ?string,
//   error: ?string,
// }

// type Action = {
//   type: string,
//   payload: any,
// }

const initialState = {
  error: null,
  msg: null,
  installationPoints: null,
}

export default function devices(state = initialState, { type, payload }) {
  switch (type) {
    case types.SYNC_INSTALLATION_POINT_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_INSTALLATION_POINT_ERROR:
      return { ...state, error: payload.error }
    case types.CLOSE_COLLECTING_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.CLOSE_COLLECTING_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.OPEN_COLLECTING_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.OPEN_COLLECTING_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.PROLONG_COLLECTING_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.PROLONG_COLLECTING_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.INVALIDATE_QR_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.FETCH_INSTALLATION_POINTS_SUCCESS:
      return { ...state, installationPoints: payload.installationPoints }
    case types.FETCH_INSTALLATION_POINTS_FAILURE:
      return { ...state, error: payload.error }
    case 'resources/CLEAR':
      return initialState
    case 'ui/CLEAR_STATUS':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
