import * as types from './types'

const initialState = {
  country: null,
  region: null,
  locality: null,
  area: null,
  street: null,
  house: null,
  entry: null,
  flat: null,
}

export default function selected(state = initialState, { type, payload }) {
  switch (type) {
    case types.SELECT_COUNTRY:
      return { ...state, country: payload }
    case types.SELECT_REGION:
      return { ...state, region: payload }
    case types.SELECT_LOCALITY:
      return { ...state, locality: payload }
    case types.SELECT_AREA:
      return { ...state, area: payload }
    case types.SELECT_STREET:
      return { ...state, street: payload }
    case types.SELECT_HOUSE:
      return { ...state, house: payload }
    case types.SELECT_ENTRY:
      return { ...state, entry: payload }
    case types.SELECT_FLAT:
      return { ...state, flat: payload }
    default:
      return state
  }
}
