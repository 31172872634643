import styled from 'styled-components'

const FileInput = styled.input.attrs(() => ({
  type: 'file',
}))`
  opacity: 0;
  border: none;
  border-radius: 3px;
  background: grey;
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
`

export default FileInput

/*
  id="file"
  name="file"
  accept=".csv"
*/
