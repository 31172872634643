import { useState, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInput } from 'sputnik-ui'
import useCompanyIntercomsStatus from 'hooks/useCompanyIntercomsStatus'
import { getIntercomsByIdentifier } from 'store/devices/operations'
import { updateCompanyIntercomsList } from 'store/devices/actions'
import debounce from 'lodash/debounce'
// import throttle from 'lodash/throttle'
import { useScrollYPosition } from 'react-use-scroll-position'

import { fetchDevices, clearDevices } from 'features/Devices/devicesSlice'
import { unwrapResult } from '@reduxjs/toolkit'

const calcCurrentDeviceFilter = (device) => {
  if (device.intercom && device.camera) return null
  if (device.intercom) return 'intercom'
  if (device.camera) return 'camera'
  return null
}

const useIntercoms = () => {
  const dispatch = useDispatch()

  const { devices, total, userCompanyUuid } = useSelector(
    ({ devicesNew, auth }) => ({
      devices: devicesNew.list,
      userCompanyUuid: auth.user.company.uuid,
      total: devicesNew.total,
      // loading: devices.loading,
    })
  )
  // const loading = useSelector(({ devicesNew }) => devicesNew.pending)
  const [loading, setLoading] = useState(false)

  // search
  const [sort, setSort] = useState({
    by: 'address',
    order: 'desc',
  })
  const handleSort = useCallback((by) => {
    setSort((prevSort) => ({
      by,
      order:
        by === prevSort.by
          ? prevSort.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'desc',
    }))
  }, [])
  const [filters, setFilters] = useState({
    online: false,
    offline: false,
    installed: false,
    uninstalled: false,

    is_online: undefined,
    is_installed: undefined,
    device_type: {
      intercom: undefined,
      camera: undefined,
    },
  })

  useEffect(() => {
    setFilters({
      ...filters,
      is_online:
        filters.online && filters.offline
          ? undefined
          : filters.online
          ? true
          : filters.offline
          ? false
          : undefined,
      is_installed:
        filters.installed && filters.uninstalled
          ? undefined
          : filters.installed
          ? true
          : filters.uninstalled
          ? false
          : undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.online, filters.offline, filters.installed, filters.uninstalled])

  const [page, setPage] = useState(1)
  const isLastPage = useSelector(
    ({ devicesNew }) => devicesNew.totalPages === page
  )
  const { value: searchQuery, bind } = useInput('')
  const handleSearch = useRef(
    debounce(
      (
        q,
        limit,
        page,
        filters,
        sort,
        device_type = calcCurrentDeviceFilter(filters.device_type)
      ) => {
        setLoading(true)
        dispatch(
          fetchDevices({ search: q, limit, page, filters, sort, device_type })
        )
          .then(unwrapResult)
          .then(() => setLoading(false))
      },
      250
    )
  )
  useEffect(() => {
    setPage(1)
    // handleSearch.current(searchQuery, 20, page)
  }, [searchQuery, filters, sort])
  useEffect(() => {
    handleSearch.current(searchQuery, 20, page, filters, sort)
  }, [page, searchQuery, filters, sort, dispatch])

  const incrementPage = useCallback(() => setPage(page + 1), [page])

  // WebSockets
  // const onMsgReceived = useCallback(
  //   (msg) => {
  //     dispatch(updateCompanyIntercomsList(msg))
  //   },
  //   [dispatch],
  // )
  // useCompanyIntercomsStatus(userCompanyUuid, onMsgReceived)

  const scrollY = useScrollYPosition()
  useEffect(() => {
    const bottom =
      window.innerHeight + scrollY + 300 >= document.body.offsetHeight
    if (devices?.length > 0 && bottom && !loading && !isLastPage) {
      incrementPage()
      setLoading(true)
    }
  }, [devices, incrementPage, isLastPage, loading, scrollY])

  useEffect(() => () => dispatch(clearDevices()), [dispatch])

  return {
    filters,
    sort,
    bind,
    handleSort,
    setFilters,
    devices,
    loading,
    incrementPage,
    total,
  }
}

export default useIntercoms

// endless scroll
// const scrollY = useScrollYPosition()
// useEffect(
//   (e) => {
//     console.log('loading', loading)

//     if (!loading && devices?.length > 0) {
//       // const bottom =
//       //   e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
//       const bottom =
//         window.innerHeight + scrollY >= document.body.offsetHeight

//       if (bottom) {
//         console.log('bottom')
//         incrementPage()
//       }
//     }
//   },
//   [incrementPage, devices, loading, scrollY],
// )

// const onScroll = useCallback(
//   throttle(
//     (e) => {
//       if (e) {
//         const bottom =
//           e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
//         if (bottom) {
//           incrementPage()
//         }
//       }
//     },
//     [incrementPage],
//     150,
//   ),
//   [incrementPage],
// )
