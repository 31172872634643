// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { TwoChildrenTemplate, Localities, Areas } from 'components'
import { fetchRegion } from 'store/operations'
import { clearResources } from 'store/actions'
import { getLocalities, getAreas } from 'store/resources/operations'

function RegionsPage({
  match: {
    params: { region },
  },
  dispatch,
}) {
  React.useEffect(() => {
    if (region) {
      dispatch(fetchRegion(region))
      dispatch(getLocalities({ parentUuid: region }))
      dispatch(getAreas({ parentUuid: region }))
    }
    return () => dispatch(clearResources())
  }, [dispatch, region])
  return (
    <TwoChildrenTemplate>
      <Localities />
      <Areas whiteHeader />
    </TwoChildrenTemplate>
  )
}

export default connect()(withRouter(RegionsPage))
