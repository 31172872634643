import React, { useState } from 'react'
import styled from 'styled-components'
import { useInput, Button } from 'sputnik-ui'
import { TextField, Typography } from '@material-ui/core'
import FiltersWrapper from 'components/atoms/FiltersWrapper'
import { useDispatch } from 'react-redux'
import { sendMessage } from 'store/orders/operations'
import { useParams } from 'react-router-dom'
import HighlightText from 'components/atoms/HighlightText'
import { useTranslation } from 'react-i18next'

const Body = styled.div`
  display: flex;
  flex-direction: column;
`

export default function NewMessage() {
  const { bind, value, reset } = useInput('')
  const { t } = useTranslation(['common', 'orders'])
  const [type, setType] = useState('note')

  const { order } = useParams()

  const dispatch = useDispatch()
  const handleSubmit = () => {
    dispatch(sendMessage(order, type, value)).then(reset)
  }
  return (
    <Body>
      <Typography display="block" color="textSecondary">
        {t('common:button.send')}
      </Typography>
      <FiltersWrapper style={{ marginTop: 0 }}>
        <HighlightText active={type === 'note'} onClick={() => setType('note')}>
          {t('orders:note')}
        </HighlightText>
        <HighlightText
          active={type === 'comment'}
          onClick={() => setType('comment')}
        >
          {t('orders:message')}
        </HighlightText>
      </FiltersWrapper>
      <TextField
        multiline
        variant="filled"
        fullWidth
        {...bind}
        style={{ borderRadius: 30 }}
      />

      <Button
        rounded
        color="primary"
        variant="contained"
        style={{
          marginLeft: 'auto',
          marginTop: '.325rem',
          marginBottom: '2rem',
        }}
        onClick={handleSubmit}
      >
        {t('common:button.send')}
      </Button>
    </Body>
  )
}
