import React, { useCallback } from 'react'
import { Button, useInput } from 'sputnik-ui'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Zoom,
  withStyles,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormField from 'components/atoms/FormField'

import { addAccessCode, fetchAccessCodes } from 'store/codes/operations'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

const getDefaultDateTimeValue = () => {
  const today = new Date()
  let currentMonth = today.getMonth() + 1
  if (currentMonth < 10) currentMonth = `0${currentMonth}`
  let currentDate = today.getDate() // + 1
  if (currentDate < 10) currentDate = `0${currentDate}`
  let minutes = today.getMinutes()
  if (minutes < 10) {
    minutes = `0${minutes}`
  } else if (minutes.toString().length < 2) {
    minutes += '0'
  }
  let hours = today.getHours()
  if (hours < 10) {
    hours = `0${hours}`
  } else if (hours.toString().length < 2) {
    hours += '0'
  }

  const date = `${today.getFullYear()}-${currentMonth}-${currentDate}`
  const time = `${hours}:${minutes}`
  const dateTime = `${date}T${time}`

  return { dateTime, date, time }
}

const schema = Yup.object().shape({
  code: Yup.string().length(3, i18next.t('common:field.error.code')),
  exp_time: Yup.date().required(i18next.t('common:field.error.exp_time')),
  time: Yup.string().required(i18next.t('common:field.error.time')),
})

export default function AddAccessCodeModal({ open, handleClose }) {
  const dispatch = useDispatch()
  const { entry: entryUuid } = useParams()

  const { value: withExpTime, bind: bindWithExpTime } = useInput('')

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        addAccessCode({
          entryUuid,
          accessCode: values.code,
          expTime:
            withExpTime === 'date'
              ? `${values.exp_time}T${values.time}`
              : undefined,
        })
      ).then(() => {
        dispatch(fetchAccessCodes(entryUuid))
        handleClose()
      })
    },
    [dispatch, entryUuid, handleClose, withExpTime]
  )
  const { t } = useTranslation(['common', 'keys', 'time'])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{ style: { minWidth: '40%' } }}
    >
      <Formik
        initialValues={{
          exp_time: getDefaultDateTimeValue().date,
          time: getDefaultDateTimeValue().time,
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogTitle>{t('keys:actions.add_access_code')}</DialogTitle>
          <DialogContent>
            <div>
              <FormLabel component="legend" style={{ marginBottom: '.125rem' }}>
                {t('keys:units.access_code', { count: 1 })}
              </FormLabel>
              <FormField
                placeholder={t('keys:placeholder.access_code')}
                type="number"
                fullWidth
                autoFocus
                name="code"
                InputProps={{
                  min: 1,
                  max: 999,
                  step: 1,
                  startAdornment: (
                    <InputAdornment position="start">0</InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <Typography variant="h6">Срок действия</Typography> */}
            <FormControl component="fieldset" style={{ marginTop: '1rem' }}>
              <FormLabel component="legend" style={{ marginBottom: '.625rem' }}>
                {t('time:expiration_time')}
              </FormLabel>
              <RadioGroup
                defaultValue=""
                aria-label="withExpTime"
                name="withExpTime"
                {...bindWithExpTime}
              >
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label={t('time:timeless')}
                />
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  fullWidth
                  label={
                    <div style={{ display: 'flex', width: '100%' }}>
                      <FormField
                        label={t('time:until')}
                        type="date"
                        name="exp_time"
                        style={{ marginRight: '0.625rem', width: '50px' }}
                      />
                      <FormField
                        label={t('time:units.hour_plural')}
                        type="time"
                        name="time"
                      />
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button secondary onClick={handleClose} rounded>
              {t('common:button.cancel')}
            </Button>
            <Button rounded primary variant="contained" type="submit">
              {t('common:button.add')}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}
