// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  /* height: 100%; */
  width: 100%;
  background: ${(p) => p.theme.palette.background.primary};
  height: fit-content;
  min-height: 100%;
  /* overflow: hidden; */
`

const Content = styled.section`
  /* height: calc(100% - 7.125rem); */
  /* margin-top: 7.125rem; */

  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: fit-content;
  min-height: calc(100vh - 7.125rem);
`

const AppTemplate = ({ header, children, subheader, ...props }) => (
  <Wrapper {...props}>
    {header}
    <Content>{children}</Content>
  </Wrapper>
)

// AppTemplate.propTypes = {
//   header: PropTypes.node.isRequired,
//   subheader: PropTypes.node,
//   children: PropTypes.any.isRequired,
// }

export default AppTemplate
