import React, { useEffect, useCallback } from 'react'
import {
  Button,
  useInput,
  FullscreenDialogTitle,
  FullscreenExitButton,
  Mascotte,
} from 'sputnik-ui'
import { useSelector, useDispatch } from 'react-redux'
import {
  Drawer,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  Typography,
  Fade,
} from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import { ReactComponent as KeyIcon } from 'assets/Nfc.svg'
import { formatDate } from 'utils/helpers'

import {
  getKeys,
  addKey,
  fetchInvalidKeys,
  removeInvalidKey,
  addInvalidKey,
} from 'store/keys/operations'
import { useTranslation } from 'react-i18next'

function Transition(props) {
  return <Fade {...props} />
}

function AddKeyModal({ flat, open, handleClose }) {
  const dispatch = useDispatch()
  const entry = useSelector(({ resources: { entries } }) => entries.current)
  const invalidKeys = useSelector(({ keys: { invalid } }) => invalid)
  useEffect(() => {
    if (entry) {
      dispatch(fetchInvalidKeys(entry.uuid))
    }
  }, [dispatch, entry])
  const { value: newKey, bind: bindKeyInput, reset } = useInput('')
  const addNewKey = useCallback(
    () =>
      dispatch(addKey(entry.uuid, newKey))
        .then(() => dispatch(getKeys(entry.uuid)))
        .then(() => {
          reset()
          handleClose()
        }),
    [dispatch, entry, newKey, reset, handleClose]
  )
  const { t } = useTranslation(['common', 'keys', 'messages'])

  return (
    <Drawer
      open={open}
      // TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: { minWidth: '40%' } }}
      // transitionDuration={0}
      anchor="right"
      elevation={10}
    >
      <FullscreenExitButton
        style={{ position: 'absolute', top: '1.5rem' }}
        onClick={handleClose}
      />
      <FullscreenDialogTitle
        subtitle={flat && t('keys:messages.key_flat', { flat: flat.number })}
      >
        {t('keys:actions.add_key')}
      </FullscreenDialogTitle>
      <DialogContent
        style={{ maxWidth: '50rem', margin: '0 auto', width: '100%' }}
      >
        <div style={{ display: 'flex' }}>
          <TextField
            placeholder={t('keys:placeholder.key')}
            style={{ width: '100%' }}
            autoFocus
            {...bindKeyInput}
          />

          <Button
            onClick={addNewKey}
            disabled={!newKey}
            variant="contained"
            color="primary"
            rounded
            primary
          >
            {t('common:button.add')}
          </Button>
        </div>
        {invalidKeys && (
          <>
            <Typography variant="h6" style={{ marginTop: '1.625rem' }}>
              {t('keys:messages.recently_used')}
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {t('keys:helper.recently_used')}
            </Typography>
            {Object.entries(invalidKeys).length < 1 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Mascotte
                  emote="notFound"
                  style={{ maxWidth: '15rem', margin: '2rem auto' }}
                />
                <Typography color="textSecondary" variant="h5" align="center">
                  {t('messages:content.empty')}
                </Typography>
              </div>
            ) : (
              Object.entries(invalidKeys).map(([keyData, keyGroup]) => (
                <List key={keyData}>
                  <ListItem style={{ paddingBottom: 0 }}>
                    <ListItemIcon>
                      <KeyIcon />
                    </ListItemIcon>
                    <ListItemText primary={keyData} />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() =>
                          dispatch(
                            removeInvalidKey(
                              keyGroup[0].address_uuid,
                              keyGroup[0].id
                            )
                          ).then(() => {
                            dispatch(getKeys(entry.uuid))
                            dispatch(fetchInvalidKeys(keyGroup[0].address_uuid))
                          })
                        }
                      >
                        <Remove color="error" />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          dispatch(
                            addInvalidKey(
                              keyGroup[0].address_uuid,
                              keyGroup[0].id
                            )
                          ).then(() => {
                            dispatch(getKeys(entry.uuid))
                            dispatch(fetchInvalidKeys(keyGroup[0].address_uuid))
                          })
                        }
                        color="primary"
                      >
                        <Add />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {keyGroup.map((key) => (
                    <ListItem key={key.id} style={{ paddingTop: 0 }}>
                      <ListItemText secondary={formatDate(key.created_at)} />
                    </ListItem>
                  ))}
                </List>
              ))
            )}
          </>
        )}
      </DialogContent>
    </Drawer>
  )
}

export default AddKeyModal

/*
 keyGroup.map(key => (
                    <ListItem key={keyData}>


                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() =>
                            dispatch(
                              removeInvalidKey(key.address_uuid, key.id),
                            ).then(() => {
                              dispatch(getKeys(entry.uuid))
                              dispatch(fetchInvalidKeys(key.address_uuid))
                            })
                          }
                        >
                          <Remove />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            dispatch(
                              addInvalidKey(key.address_uuid, key.id),
                            ).then(() => {
                              dispatch(getKeys(entry.uuid))
                              dispatch(fetchInvalidKeys(key.address_uuid))
                            })
                          }
                        >
                          <Add />
                        </IconButton>
                      </ListItemSecondaryAction>

                      */
