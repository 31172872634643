import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'sputnik-ui'
import { LinearProgress } from '@material-ui/core'
import {
  closeCollectKeys,
  openCollectKeys,
} from 'store/installations/operations'

import { fetchIntercomByUuid } from 'store/devices/operations'
import { useParams } from 'react-router'
// fix me
const styles = {
  action: {
    // fontWeight: '400',
    // color: 'inherit',
    overflow: 'hidden',
    '& svg': {
      marginRight: 15,
    },
  },
  progress: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    height: '100%',
    backgroundColor: 'transparent',
    opacity: 0.1,
  },
}

const variablesOfCollecting = {
  disabled: 'OFF',
  enabled: 'ON_GENERAL',
}

const ControlCollectKeys = ({ ...props }) => {
  const intercom = useSelector(({ devices }) => devices.intercom)
  const { device: deviceUuid } = useParams()
  const { t } = useTranslation(['devices', 'geo'])

  const [collectingKeys, setCollectingKeys] = useState(
    variablesOfCollecting.disabled
  )
  const [counter, setCounter] = useState(0)
  const dispatch = useDispatch()

  const handleOpenCollecting = useCallback(() => {
    setCounter(10)
    dispatch(openCollectKeys(deviceUuid)).then(() => {
      dispatch(fetchIntercomByUuid(deviceUuid))
    })
  }, [dispatch, deviceUuid])

  const handleCloseCollecting = useCallback(() => {
    setCounter(10)
    dispatch(closeCollectKeys(deviceUuid)).then(() => {
      dispatch(fetchIntercomByUuid(deviceUuid))
    })
  }, [dispatch, deviceUuid])

  useEffect(() => {
    let timer1
    if (counter > 0) {
      timer1 = setTimeout(
        () =>
          setCounter((prevCounter) =>
            prevCounter >= 100 ? 0 : prevCounter + 10
          ),
        1000
      )
    }
    return () => {
      clearTimeout(timer1)
    }
  }, [counter])

  useEffect(() => {
    setCollectingKeys(intercom?.config?.collecting_keys)
  }, [intercom])

  const handleClick = useCallback(() => {
    collectingKeys === variablesOfCollecting.enabled
      ? handleCloseCollecting()
      : handleOpenCollecting()
  }, [collectingKeys, handleCloseCollecting, handleOpenCollecting])

  return (
    <Button
      rounded
      secondary
      onClick={handleClick}
      disabled={counter > 0 || !deviceUuid}
      {...props}
    >
      {collectingKeys === variablesOfCollecting.enabled
        ? t('devices:actions.close_collecting')
        : t('devices:actions.open_collecting')}
      <LinearProgress
        style={styles.progress}
        variant="determinate"
        value={counter}
      />
    </Button>
  )
}

export default ControlCollectKeys
