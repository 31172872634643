export const GET_KEYS_REQUEST = 'keys/GET_KEYS_REQUEST'

export const GET_ENTRY_KEYS_SUCCESS = 'keys/GET_ENTRY_KEYS_SUCCESS'
export const GET_ENTRY_KEYS_ERROR = 'keys/GET_ENTRY_KEYS_ERROR'

export const GET_FLAT_KEYS_SUCCESS = 'keys/GET_FLAT_KEYS_SUCCESS'
export const GET_FLAT_KEYS_ERROR = 'keys/GET_FLAT_KEYS_ERROR'

export const REMOVE_KEY_REQUEST = 'keys/REMOVE_KEY_REQUEST'
export const REMOVE_KEY_SUCCESS = 'keys/REMOVE_KEY_SUCCESS'
export const REMOVE_KEY_ERROR = 'keys/REMOVE_KEY_ERROR'

export const ADD_KEY_REQUEST = 'keys/ADD_KEY_REQUEST'
export const ADD_KEY_SUCCESS = 'keys/ADD_KEY_SUCCESS'
export const ADD_KEY_ERROR = 'keys/ADD_KEY_ERROR'

export const DELETE_ALL_KEYS_REQUEST = 'keys/DELETE_ALL_KEYS_REQUEST'
export const DELETE_ALL_KEYS_SUCCESS = 'keys/DELETE_ALL_KEYS_SUCCESS'
export const DELETE_ALL_KEYS_ERROR = 'keys/DELETE_ALL_KEYS_ERROR'

export const SYNC_KEYS_REQUEST = 'keys/SYNC_KEYS_REQUEST'
export const SYNC_KEYS_SUCCESS = 'keys/SYNC_KEYS_SUCCESS'
export const SYNC_KEYS_ERROR = 'keys/SYNC_KEYS_ERROR'

export const FETCH_INVALID_KEYS_REQUEST = 'keys/FETCH_INVALID_KEYS_REQUEST'
export const FETCH_INVALID_KEYS_SUCCESS = 'keys/FETCH_INVALID_KEYS_SUCCESS'
export const FETCH_INVALID_KEYS_FAILURE = 'keys/FETCH_INVALID_KEYS_FAILURE'

export const ADD_INVALID_KEY_REQUEST = 'keys/ADD_INVALID_KEY_REQUEST'
export const ADD_INVALID_KEY_SUCCESS = 'keys/ADD_INVALID_KEY_SUCCESS'
export const ADD_INVALID_KEY_FAILURE = 'keys/ADD_INVALID_KEY_FAILURE'

export const REMOVE_INVALID_KEY_REQUEST = 'keys/REMOVE_INVALID_KEY_REQUEST'
export const REMOVE_INVALID_KEY_SUCCESS = 'keys/REMOVE_INVALID_KEY_SUCCESS'
export const REMOVE_INVALID_KEY_FAILURE = 'keys/REMOVE_INVALID_KEY_FAILURE'

export const IMPORT_KEYS_REQUEST = 'keys/IMPORT_KEYS_REQUEST'
export const IMPORT_KEYS_SUCCESS = 'keys/IMPORT_KEYS_SUCCESS'
export const IMPORT_KEYS_FAILURE = 'keys/IMPORT_KEYS_FAILURE'

export const EXPORT_KEYS_REQUEST = 'keys/EXPORT_KEYS_REQUEST'
export const EXPORT_KEYS_SUCCESS = 'keys/EXPORT_KEYS_SUCCESS'
export const EXPORT_KEYS_FAILURE = 'keys/EXPORT_KEYS_FAILURE'

export const CLEAR_FLAT_KEYS = 'keys/CLEAR_FLAT'
