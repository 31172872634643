import React, { useState, useEffect } from 'react'
import Map from 'components/atoms/Map'
import styled from 'styled-components'
import { Wrapper } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { keyDownWithClick } from 'utils/helpers'

const StyledWrapper = styled(Wrapper)`
  padding: 0;
  height: 300px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
`

export default function GateForm({ position, setGatePos, isManual, touch }) {
  const { t } = useTranslation(['devices'])

  const [initialMarkers, setInitialMarkers] = useState([
    { ...position, address_type: 'gate' },
  ])

  useEffect(() => {
    setInitialMarkers([{ ...position, address_type: 'gate' }])
    // fixme
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(position)])

  const handleTouch = (event) => {
    keyDownWithClick(event, touch)
  }

  return (
    <>
      <Typography gutterBottom>
        {t('devices:gate.set_gate_location')}
      </Typography>
      <StyledWrapper border={false}>
        {position.lat && position.lon ? (
          <Map
            initialMarkers={initialMarkers}
            onUpdate={(markers) => {
              setGatePos({
                gate_lat: markers?.[0].lat,
                gate_lon: markers?.[0].lon,
              })
            }}
          />
        ) : !isManual ? (
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ margin: 'auto' }}
          >
            {t('devices:gate.choose_address')}
          </Typography>
        ) : (
          <div
            onClick={handleTouch}
            onKeyDown={handleTouch}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
            tabIndex="0"
            role="button"
          >
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ margin: 'auto' }}
            >
              {t('devices:gate.touch_to_continue')}
            </Typography>
          </div>
        )}
      </StyledWrapper>
    </>
  )
}
