/* eslint-disable */

import config from 'config'
import i18next from 'i18next'

/**
 *
 *
 * @export
 * @class Api
 */
class Api {
  /**
   * Creates an instance of Api.
   * @param {*} baseUri
   * @memberof Api
   */
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  /**
   * Makes a redirect to the SSO if err status is 401, returns a Promise.reject
   *
   * @param {*} err
   * @returns {Promise<Response>}
   * @memberof Api
   */
  _handleError(err) {
    if (err.status === 401) {
      sessionStorage.removeItem('auth')
      window.location = `${config.redirectUri}/auth/sso?origin=${window.location.href}`
      return Promise.reject()
    }
    return Promise.reject(err)
  }

  /**
   * Makes an api request, handles error status 401
   *
   * @param {string} endpoint - the endpoint, that gets appended to the (default) baseUri
   * @param {?Object} [options={}] - custom request options, override default ones
   * @param {?string} baseUri - custom baseUri
   * @returns {Promise<any>}
   * @memberof Api
   */
  // endpoint: string, options: ?Object = {}, baseUri: ?string, blob
  request(endpoint, options, baseUri, blob) {
    const BASE_URI = baseUri || this.baseUri
    const selectedLanguage = i18next.language
    const request = new Request(`${BASE_URI}${endpoint}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept-Language': selectedLanguage,
      }),
      credentials: 'include',
      ...options,
    })
    return fetch(request)
      .then((r) =>
        blob ? r.blob() : r.status <= 207 ? r.json() : Promise.reject(r)
      )
      .catch(this._handleError)
  }
}

const api = new Api(config.baseUri)

export default api
