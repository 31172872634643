import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { AddAddressModal } from 'components'
import { Button, Container } from 'sputnik-ui'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  background: ${(p) => p.theme.palette.primary['500']};
  color: ${(p) => p.theme.palette.primary.contrastText};
`

function HomePageActions({ history, match, ...props }) {
  const { t } = useTranslation('common')
  return (
    <Wrapper {...props}>
      <Container>
        <Button
          style={{ marginBottom: '.625rem' }}
          rounded
          color="primary"
          variant="contained"
          component={Link}
          to="/add_address"
        >
          {t('common:button.add_address')}
        </Button>

        <AddAddressModal
          handleClose={history.goBack}
          open={match.url === '/add_address'}
        />
      </Container>
    </Wrapper>
  )
}

export default withRouter(HomePageActions)
