import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import { Button, DropListItem } from 'sputnik-ui'
import { useTheme } from 'styled-components'

// werwolf component

export const PageMenuButton = ({ children, ...props }) => {
  const theme = useTheme()
  const query = useMediaQuery(`(min-width:${theme.sizes.tablet})`)
  return (
    <>
      {query ? (
        <Button {...props}>{children}</Button>
      ) : (
        <DropListItem style={{ paddingLeft: 0 }} label={children} {...props} />
      )}
    </>
  )
}
