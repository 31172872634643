import React from 'react'
import styled, { css } from 'styled-components'
import { formatDate } from 'utils/helpers'
import { Typography } from '@material-ui/core'

// sputnik_comment - сообщение от Мансура (или кого-то из нас)
const ifSputnikComment = css`
  background: ${(p) => p.theme.palette.secondary[500]};
`

// sputnik_note - заметка от Мансура + автоматическая заметка при создании заявки
const ifSputnikNote = css`
  background: ${(p) => p.theme.palette.secondary[50]};
  opacity: 0.5;
`

// service_partner_note - заметка из контрола
const ifServicePartnerNote = css`
  background: ${(p) => p.theme.palette.background.primary};
  opacity: 0.5;
`

// service_partner_comment - сообщение от СП (оставленное через контрол)
const ifServicePartnerComment = css`
  background: ${(p) => p.theme.palette.background.secondary};
`

// user_comment - сообщение от жителя
const ifDefault = css`
  background: ${(p) => p.theme.palette.other.green};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  ${(p) => {
    switch (p.type) {
      // TODO
      case 'note':
        return ifServicePartnerNote

      case 'sputnik_comment':
        return ifSputnikComment
      case 'sputnik_note':
        return ifSputnikNote
      case 'service_partner_comment':
        return ifServicePartnerComment
      case 'service_partner_note':
        return ifServicePartnerNote
      case 'user_comment':
        return ifDefault
      default:
        return ifDefault
    }
  }};
`

export default function ChatBubble({ date, children, type }) {
  return (
    <Body type={type} style={{ padding: '1rem', borderRadius: 20 }}>
      <Typography style={{ fontWeight: 400 }} color="textSecondary">
        {formatDate(date, true)}
      </Typography>
      <Typography variant="h6" style={{ fontWeight: 400 }} color="textPrimary">
        {children}
      </Typography>
    </Body>
  )
}
