import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from 'sputnik-ui'
import { extraKeyReaderDeviceOpen } from 'store/devices/operations'

export const OpenSecondDoorButton = ({ uuid, httpRelay }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('devices')

  const handleOpen = () => {
    uuid &&
      dispatch(extraKeyReaderDeviceOpen({ uuid, duration: 10, httpRelay }))
  }

  return (
    <Button rounded secondary onClick={handleOpen}>
      {t('devices:actions.open_sec_door')}
    </Button>
  )
}
