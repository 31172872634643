// @flow

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('auth')
    if (serializedState === null) {
      return undefined
    }
    // $FlowFixMe
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = ({ auth }) => {
  try {
    const serializedState = JSON.stringify({
      auth,
    })
    sessionStorage.setItem('auth', serializedState)
  } catch (err) {
    console.log(err)
  }
}
