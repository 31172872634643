import Api from 'utils/api'
import * as actions from './actions'

export const fetchOrders =
  ({ filters, archived = false, page, limit, query, sortBy, sortOrder }) =>
  async (dispatch) => {
    if (archived) {
      dispatch(actions.fetchArchivedOrdersRequest())
    } else {
      dispatch(actions.fetchOrdersRequest())
    }
    try {
      const setParams = () => {
        const params = []

        if (filters) {
          if (filters.type) {
            params.push(`type=${filters.type}`)
          }
          if (filters.urgent) {
            params.push(`urgent=${filters.urgent}`)
          }
        }
        if (query) params.push(`q=${query}`)
        if (sortBy) params.push(`sort_by=${sortBy}`)
        if (sortOrder) params.push(`order_by=${sortOrder}`)

        // if (!archived) params.push(`limit=${10000}`)
        // if (archived) {
        //   params.push(`page=${page}`)
        //   params.push(`limit=${25}`)
        // }

        if (limit) params.push(`limit=${limit}`)
        if (page) params.push(`page=${page}`)

        return params.join('&')
      }
      const { count, orders, pages } = await Api.request(
        `/orders?archived=${archived}&${setParams()}`
      )

      if (!archived) {
        dispatch(
          actions.fetchOrdersSuccess({ orders, count, isFirstPage: page === 1 })
        )
      } else {
        dispatch(
          actions.fetchArchivedOrdersSuccess({
            orders,
            count,
            isFirstPage: page === 1,
            archivePages: pages,
          })
        )
      }
      return Promise.resolve({ count })
    } catch (err) {
      const { message } = await err.json()
      if (!archived) {
        dispatch(actions.fetchOrdersFailure(message))
      } else {
        dispatch(actions.fetchArchivedOrdersFailure(message))
      }
      return Promise.reject()
    }
  }

// export const fetchArchivedOrders = () => async dispatch => {
//   dispatch(actions.fetchArchivedOrdersRequest())
//   try {
//     const { count, orders } = await Api.request('/orders?archived=true')
//   } catch (err) {
//     const { message } = await err.json()
//     dispatch(actions.fetchArchivedOrdersFailure(message))
//   }
// }

export const fetchOrder = (id) => async (dispatch) => {
  dispatch(actions.fetchOrderRequest())
  try {
    const order = await Api.request(`/orders/${id}`)
    dispatch(actions.fetchOrderSuccess(order))
  } catch (err) {
    try {
      const { message } = await err.json()
      dispatch(actions.fetchOrderFailure(message))
    } catch {
      dispatch(actions.fetchOrderFailure('', true))
    }
  }
}

export const completeOrder = (id) => async (dispatch) => {
  dispatch(actions.completeOrderRequest())
  try {
    const payload = await Api.request(`/orders/${id}/archive`, {
      method: 'PATCH',
    })
    dispatch(actions.completeOrderSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.completeOrderFailure(message))
    return Promise.reject()
  }
}

export const sendMessage = (orderId, type, message) => async (dispatch) => {
  dispatch(actions.sendMessageRequest())
  try {
    await Api.request(`/orders/${orderId}/messages`, {
      method: 'POST',
      body: JSON.stringify({ type, message }),
    })
    dispatch(actions.sendMessageSuccess())
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.sendMessageFailure(message))
    return Promise.reject()
  }
}
