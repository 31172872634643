import { useEffect, useRef, useCallback } from 'react'
import config from 'config'
import ActionCable from 'actioncable'
import { useSelector } from 'react-redux'

export default function useOrdersListStatus(onReceived) {
  const cable = useRef()
  const ordersListSubscription = useRef()
  const companyUuid = useSelector(
    ({ auth }) => auth.user.company && auth.user.company.uuid
  )

  const createSocket = useCallback(() => {
    cable.current = ActionCable.createConsumer(config.socketUrl)
    ordersListSubscription.current = cable.current.subscriptions.create(
      {
        channel: 'OrdersListChannel',
        room: `orders_for_${companyUuid}`,
        company_uuid: companyUuid,
      },
      {
        received: onReceived,
      }
    )
  }, [companyUuid, onReceived])

  useEffect(() => {
    if (companyUuid && !cable.current) createSocket()

    return () => {
      if (cable.current && ordersListSubscription.current) {
        cable.current.subscriptions.remove(ordersListSubscription.current)
      }
    }
  }, [createSocket, companyUuid])

  return cable
}
