import Api from 'utils/api'
import * as actions from './actions'

// regions

export const getRegions =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getRegionsRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const regions = await Api.request(`/regions${setParams()}`)

      dispatch(actions.getRegionsSuccess(regions))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getRegionsError(message))
    }
  }

export const fetchRegion = (regionUuid) => async (dispatch) => {
  dispatch(actions.fetchRegionRequest())
  try {
    const region = await Api.request(`/regions/${regionUuid}`)

    dispatch(actions.fetchRegionSuccess(region))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchRegionFailure(message))
  }
}
