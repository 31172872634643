import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'utils/api'
import { buildUrl } from 'utils/helpers'
import config from 'config'
import { showErrorMessage } from 'store/ui/actions'

export const fetchCitizensList = createAsyncThunk(
  'citizensPro/fetchCitizensList',
  async (
    { addressUuid, inviteState, page = 1, limit = 9999, update = false }, // update use in slice meta arg; for better ux
    thunkApi
  ) => {
    const { rejectWithValue, signal, dispatch, getState } = thunkApi

    const tableReq = inviteState || getState().citizensPro.activeTable

    const parsedParams = {
      entry_uuid: addressUuid,
      invite_state: tableReq,
      page,
      limit,
    }

    try {
      const payload = await api.request(
        buildUrl('/citizens', parsedParams),
        { signal },
        `${config.redirectUri}/api/v2`
      )

      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)
