import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { ReactComponent as EngineerInstallation } from 'assets/EngineerInstallation.svg'
import { ReactComponent as EngineerSettings } from 'assets/EngineerSettings.svg'
import { formatDate } from 'utils/helpers'
import styled from 'styled-components'

const variants = {
  installation: { color: 'lightblue', icon: EngineerInstallation },
  configuration: { color: 'peach', icon: EngineerSettings },
}

const IconWrapper = styled(ListItemIcon)`
  && {
    margin-right: 1rem;
    color: ${(p) => p.theme.palette.other[`${variants[p.variant].color}Dark`]};
    background-color: ${(p) =>
      p.theme.palette.other[variants[p.variant].color]};
    border-radius: 50%;
    min-width: 0;
  }
`

const EngineerOrder = ({ message, time, variant, ...props }) => {
  const Icon = variants[variant].icon

  return (
    <ListItem
      button
      onClick={() => console.log()}
      disableRipple
      style={{ borderRadius: 8 }}
    >
      <IconWrapper variant={variant}>
        <Icon />
      </IconWrapper>
      <ListItemText primary={message} secondary={formatDate(time)} />
    </ListItem>
  )
}

export default EngineerOrder
