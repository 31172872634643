import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFlatKeys } from 'store/keys/operations'
import { clearFlatKeys } from 'store/keys/actions'
import Key from 'components/atoms/Key'
import { List, Typography } from '@material-ui/core'
import { Loader } from 'sputnik-ui'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export default function FlatKeys({
  flat,
  flatUuid,
  parent,
  onAddKey,
  ...props
}) {
  const dispatch = useDispatch()
  const { entry } = useParams()
  useEffect(() => {
    dispatch(getFlatKeys(parent, flat, flatUuid)) // flatUuid for local redux work
    return () => dispatch(clearFlatKeys())
  }, [dispatch, flat, flatUuid, parent])

  const { keys } = useSelector(({ keys }) => ({
    keys: keys.flatKeys[flatUuid],
  }))

  const { t } = useTranslation(['keys', 'messages'])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} {...props}>
      <List>
        {keys ? (
          <>
            {keys.length > 0 ? (
              <>
                {keys.map((k) => (
                  <Key parentUuid={entry} {...k} key={k.id} isFlat>
                    {k.hex}
                  </Key>
                ))}
              </>
            ) : (
              <Typography align="center">
                {t('messages:content.empty')}
              </Typography>
            )}
          </>
        ) : (
          <Loader msg={t('messages:content.loading')} fluid />
        )}
      </List>
      {/* <Button
        variant="text"
        primary
        rounded
        style={{ margin: '0 auto' }}
        onClick={onAddKey}
      >
        Добавить ключ
      </Button> */}
    </div>
  )
}
