import { useEffect, useRef, useCallback } from 'react'
import config from 'config'
import ActionCable from 'actioncable'

export default function useAddressIntercomsStatus(addressUuid, onReceived) {
  const cable = useRef()
  const Subscription = useRef()
  const createSocket = useCallback(() => {
    cable.current = ActionCable.createConsumer(config.socketUrl)
    Subscription.current = cable.current.subscriptions.create(
      {
        channel: 'IntercomsListStatusChannel',
        room: `address_${addressUuid}`,
        address_uuid: addressUuid,
      },
      {
        received: onReceived,
      }
    )
  }, [addressUuid, onReceived])

  useEffect(() => {
    if (addressUuid && !cable.current) createSocket()

    return () => {
      if (cable.current && Subscription.current) {
        cable.current.subscriptions.remove(Subscription.current)
      }
    }
  }, [createSocket, addressUuid])

  return cable
}
