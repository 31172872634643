import React, { useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Zoom,
} from '@material-ui/core'
import styled from 'styled-components'
import { Button, Input, Select } from 'sputnik-ui'
import { useFormik } from 'formik'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { showInfoMessage } from 'store/ui/actions'
import useVideoArchive from './useVideoArchive'

const StyledContent = styled(DialogContent)`
  && {
    padding: 1rem 70px;
    padding-top: 0px;
    overflow: auto;
    @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
      height: 70vh;
      padding: 0 2rem;
    }
  }
`

const StyledRow = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 0.5rem 0;
  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    grid-template-columns: 1fr;
  }
`

const StyledAction = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px 20px 24px 20px;
    > * {
      margin: 0;
      /* width: 280px; */
    }
  }
`

const timezones = [
  { label: 'Eastern European Time (+2)', value: 2 },
  { label: 'Moscow Standard Time (+3)', value: 3 },
  { label: 'Samara Time (+4)', value: 4 },
  { label: 'Yekaterinburg Time (+5)', value: 5 },
  { label: 'Omsk Standard Time (+6)', value: 6 },
  { label: 'Novosibirsk Time (+7)', value: 7 },
  { label: 'Irkutsk Time (+8)', value: 8 },
  { label: 'Yakutsk Time (+9)', value: 9 },
  { label: 'Vladivostok Time (+10)', value: 10 },
  { label: 'Sakhalin Time (+11)', value: 11 },
  { label: 'Kamchatka Time (+12)', value: 12 },
]

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function ModalVideoArchive({
  selected,
  open,
  handleClose,
  isCamera,
  ...props
}) {
  const dispatch = useDispatch()
  const { pending, fetchArchive } = useVideoArchive()

  const { t } = useTranslation(['devices', 'time'])
  const options = [
    { value: 600, label: `10 ${t('time:units.min_2')}` },
    { value: 1800, label: `30 ${t('time:units.min_2')}` },
    { value: 3600, label: `60 ${t('time:units.min_2')}` },
    { value: 10800, label: `3 ${t('time:units.hour_1')}` },
    { value: 21600, label: `6 ${t('time:units.hour_2')}` },
    { value: 43200, label: `12 ${t('time:units.hour_2')}` },
    { value: 86400, label: `24 ${t('time:units.hour_1')}` },
  ]
  useEffect(() => {
    if (!selected && open) {
      dispatch(showInfoMessage(t('devices:video_archive.err_select')))
      handleClose()
    }
  }, [selected, open, dispatch, t, handleClose])

  const initialState = {
    time: '09:30',
    date: dayjs().format('YYYY-MM-DD'),
    duration: options[0],
    timezone: timezones[1],
  }

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: ({ duration, date, time, timezone }) => {
      const timeString =
        `${date}T${time}:00` +
        `+${
          timezone.value.toString().length > 1
            ? timezone.value
            : `0${timezone.value}`
        }:00`

      fetchArchive({
        duration: duration.value,
        datetime: dayjs(timeString).unix(),
        device_uuid: selected,
        isCamera,
      })
    },
  })

  const resetState = useCallback(() => {
    formik.setValues(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik])
  // fixme need move init options to outside component
  const handleCloseForm = () => {
    resetState()
    handleClose()
  }

  return (
    <Dialog
      open={open && selected}
      fullWidth
      maxWidth="xs" // invalid
      TransitionComponent={Transition}
      onClose={handleCloseForm}
      PaperProps={{
        style: { borderRadius: 20, maxHeight: '100vh' }, // , minHeight: 510
      }}
      {...props}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <DialogTitle>{t('devices:video_archive.title')}</DialogTitle>
        <StyledContent>
          <Typography color="textSecondary">
            {t('devices:video_archive.info')}
          </Typography>
          <Input
            style={{ width: '100%', marginTop: '.5rem' }}
            placeHolder={t('devices:video_archive.select_date')}
            name="date"
            type="date"
            inputProps={{
              min: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
              max: dayjs().format('YYYY-MM-DD'),
            }}
            onChange={formik.handleChange}
            value={formik.values.date}
          />
          <StyledRow>
            <Input
              label={t('devices:video_archive.start_date')}
              style={{ width: '100%' }}
              type="time"
              variant="outlined"
              name="time"
              onChange={formik.handleChange}
              value={formik.values.time}
            />

            <Select
              label={t('devices:video_archive.duration_date')}
              variant="outlined"
              handleSelect={(s) =>
                formik.setValues({ ...formik.values, duration: s })
              }
              menuPortalTarget={document.querySelector('body')}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={options}
              isMulti={false}
              isSearchable={false}
              name="duration"
              value={formik.values.duration}
            />
          </StyledRow>
          <Select
            label={t('devices:video_archive.timezone')}
            variant="outlined"
            options={timezones}
            isMulti={false}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.querySelector('body')}
            name="timezone"
            handleSelect={(s) =>
              formik.setValues({ ...formik.values, timezone: s })
            }
            value={formik.values.timezone}
          />
        </StyledContent>

        <StyledAction>
          <Button
            type="submit"
            primary
            variant="v2"
            disabled={pending || !selected}
          >
            {t('devices:video_archive.download')}
            {pending ? '...' : ''}
          </Button>
          <Button onClick={handleCloseForm} tertiary>
            {t('devices:video_archive.back')}
          </Button>
        </StyledAction>
      </form>
    </Dialog>
  )
}
export default ModalVideoArchive
