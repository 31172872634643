// @flow
import Api from 'utils/api'
import * as actions from './actions'

export const fetchMapIntercoms = (addressUuid) => async (dispatch) => {
  dispatch(actions.fetchMapIntercomsRequest())

  try {
    const intercoms = await Api.request(
      `/maps/devices?address_uuid=${addressUuid}`
    )
    dispatch(actions.fetchMapIntercomsSuccess(intercoms))
    return Promise.resolve(intercoms)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchMapIntercomsFailure(message))
    return Promise.reject(err)
  }
}
