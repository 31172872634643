import React, { useState, useEffect } from 'react'
import {
  ListItem,
  ListItemIcon,
  Tooltip,
  ListItemText,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import {
  NotificationsOff,
  NotificationsActive,
  Group,
  ExpandMore,
} from '@material-ui/icons'
import FlatForm from 'components/atoms/FlatForm'
import { ReactComponent as KeyIcon } from 'assets/Nfc.svg'
import { ReactComponent as Subscription } from 'assets/subscription.svg'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconColors, Lock } from 'sputnik-icons'

const styles = {
  icon: {
    width: '.875rem',
    height: '.875rem',
    marginRight: 4,
  },
  stat: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}

// export default function Flat({
//   uuid,
//   number,
//   citizens_count,
//   keys_count,
//   notice,
//   flat_config,
//   ...props
// }) {
//   // const dispatch = useDispatch()
//   // const { entry } = useParams()

//   // const toggleNotifications = useCallback(
//   //   () =>
//   //     dispatch(changeFlatConfig(uuid, !call_blocked)).then(() =>
//   //       dispatch(getFlats({ parentUuid: entry })),
//   //     ),
//   //   [call_blocked, dispatch, entry, uuid],
//   // )
//   return (
//     <ListItem
//       style={{ paddingLeft: '1.325rem' }}
//       key={uuid}
//       button
//       disableRipple
//       {...props}
//     >
//       <ListItemIcon>
//         {flat_config?.call_blocked ? (
//           <NotificationsOff />
//         ) : (
//           <NotificationsActive />
//         )}
//       </ListItemIcon>

//       <ListItemText
//         disableTypography
//         primary={
//           <div style={{ display: 'flex' }}>
//             <Typography display="inline">№{number} </Typography>
//             <Typography
//               display="block"
//               color="textSecondary"
//               style={{
//                 fontStyle: 'italic',
//                 whiteSpace: 'nowrap',
//                 overflow: 'hidden',
//                 textOverflow: 'ellipsis',
//                 width: 250,
//                 marginLeft: '.325rem',
//                 flex: 1,
//               }}
//             >
//               {notice}{' '}
//             </Typography>
//           </div>
//         }
//         secondary={
//           <Typography
//             color="textSecondary"
//             style={{
//               display: 'flex',
//               alignItems: 'center',
//               fontSize: '.875rem',
//             }}
//           >
//             <Tooltip title="Количество жильцов">
//               <span style={styles.stat}>
//                 <Group style={styles.icon} />
//                 {citizens_count}
//               </span>
//             </Tooltip>
//             {'     '}
//             <Tooltip title="Количество ключей">
//               <span style={styles.stat}>
//                 <KeyIcon style={{ ...styles.icon, marginLeft: 10 }} />{' '}
//                 {keys_count}
//               </span>
//             </Tooltip>
//           </Typography>
//         }
//       />
//     </ListItem>
//   )
// }

export default function Flat({
  uuid,
  number,
  citizens_count,
  keys_count,
  notice,
  flat_config,
  flat_owner,
  onAddKey,
  resetSearch,
  onClose,
  subscribe_count,
  subscribe_ids,
  ...props
}) {
  const { entry } = useParams()
  const { t } = useTranslation('geo')
  const [config, setConfig] = useState({
    call_type: '',
    call_blocked: false,
    parent_uuid: entry,
    flat_number: '',
    owner_first_name: '',
    owner_middle_name: '',
    owner_last_name: '',
    owner_phone: '',
    notice: '',
    blocked: false,
    blocking_reason: null,
  })
  useEffect(() => {
    setConfig({
      call_type: '',
      call_blocked: flat_config?.call_blocked,
      parent_uuid: entry,
      flat_number: number,
      notice,
      owner_first_name: flat_owner?.first_name || '',
      owner_middle_name: flat_owner?.middle_name || '',
      owner_last_name: flat_owner?.last_name || '',
      owner_phone: flat_owner?.phone_number || '',
      uuid: uuid || null,
      blocked: flat_config?.blocked,
      blocking_reason: flat_config?.blocking_reason,
      subscribe_count,
      subscribe_ids,
    })
  }, [
    entry,
    flat_config,
    flat_owner,
    notice,
    number,
    subscribe_count,
    subscribe_ids,
    uuid,
  ])

  const [expanded, setExpanded] = useState(!uuid)
  return (
    <ExpansionPanel expanded={expanded}>
      {/* <ExpansionPanelSummary style={{ margin: 0 }} expandIcon={<ExpandMore />}> */}
      <ListItem
        style={{ paddingLeft: '1.325rem' }}
        disableRipple
        {...props}
        button
        onClick={(e) => {
          e.stopPropagation()
          setExpanded(uuid)
          // f.is_enabled
          //   ? dispatch(disableFeature(f.uuid)).then(() =>
          //       dispatch(checkAuth()),
          //     )
          //   : dispatch(enableFeature(f.uuid)).then(() =>
          //       dispatch(checkAuth()),
          //     )
        }}
        onFocus={(e) => e.stopPropagation()}
      >
        <ListItemIcon>
          {flat_config?.call_blocked ? (
            <NotificationsOff />
          ) : (
            <NotificationsActive />
          )}
          {/* <Switch
            checked={!flat_config?.call_blocked}
            onChange={() =>
              setConfig({
                ...config,
                call_blocked: !config.call_blocked,
              })
            }
          /> */}
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6">№{number}</Typography>
                {config.blocked && (
                  <Lock color={IconColors.grey} style={{ marginLeft: 8 }} />
                )}
              </div>
              <Typography
                color="textSecondary"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '.875rem',
                  marginLeft: 'auto',
                }}
              >
                {subscribe_count ? (
                  <Tooltip title={t('geo:apartments.subscribe')}>
                    <span style={styles.stat}>
                      <Subscription
                        style={{ ...styles.icon, marginRight: 10 }}
                      />{' '}
                    </span>
                  </Tooltip>
                ) : null}
                <Tooltip title={t('geo:apartments.citizens_count')}>
                  <span style={styles.stat}>
                    <Group style={styles.icon} />
                    {citizens_count}
                  </span>
                </Tooltip>
                {'     '}
                <Tooltip title={t('geo:apartments.keys_count')}>
                  <span style={styles.stat}>
                    <KeyIcon style={{ ...styles.icon, marginLeft: 10 }} />{' '}
                    {keys_count}
                  </span>
                </Tooltip>
              </Typography>
            </div>
          }
          secondary={
            <Typography
              display="block"
              color="textSecondary"
              style={{
                fontStyle: 'italic',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 250,

                flex: 1,
              }}
            >
              {notice}{' '}
            </Typography>
          }
        />
      </ListItem>
      {/* </ExpansionPanelSummary> */}
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <FlatForm
          config={config}
          onClose={onClose || (() => setExpanded(false))}
          resetSearch={resetSearch}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
