import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { ReactComponent as RobotIcon } from 'assets/Robot.svg'
import { formatDate } from 'utils/helpers'
import styled from 'styled-components'

const IconWrapper = styled(ListItemIcon)`
  && {
    margin-right: 1rem;

    border-radius: 50%;
    min-width: 0;
  }
`
const AnomalyOrder = ({ message, time, ...props }) => (
  <ListItem
    button
    onClick={() => console.log()}
    disableRipple
    style={{ borderRadius: 8 }}
  >
    <IconWrapper>
      <RobotIcon />
    </IconWrapper>
    <ListItemText primary={message} secondary={formatDate(time)} />
  </ListItem>
)

export default AnomalyOrder
