import Api from 'utils/api'
import { buildUrl } from 'utils/helpers'
import { showErrorMessage, showInfoMessage } from 'store/ui/actions'
import config from 'config'
import * as actions from './actions'

// getIntercomsByIdentifier probably never used
// export const getIntercomsByIdentifier =
//   (
//     identifier,
//     limit = 20,
//     page = 1,
//     filters: {
//       is_installed: null,
//       is_online: null,
//     },
//     sort: {
//       by: null,
//       order: null,
//     }
//   ) =>
//   async (dispatch) => {
//     dispatch(actions.getIntercomsByIdentifierRequest(identifier, limit, page))

//     try {
//       const setFilters = () => {
//         let params = []
//         if (filters.is_online !== null && filters.is_online !== undefined)
//           params.push(`is_online=${filters.is_online}`)
//         if (filters.is_installed !== null && filters.is_installed !== undefined)
//           params.push(
//             `is_installed=${
//               filters.is_installed === true ? filters.is_installed : ''
//             }`
//           )
//         params = params.join('&')
//         return params.length > 0 ? `&${params}` : params
//       }

//       const intercoms = await Api.request(
//         `/intercoms?q=${identifier}&page=${page}&limit=${limit}&sort_by=${
//           sort?.by
//         }&order=${sort?.order}${setFilters()}`
//       )
//       dispatch(actions.getIntercomsByIdentifierSuccess(intercoms, page === 1))
//       return Promise.resolve(intercoms)
//     } catch (err) {
//       console.log(err)
//       const { message } = await err.json()
//       dispatch(actions.getIntercomsByIdentifierError(message))
//       return Promise.reject(err)
//     }
//   }

export const getIntercomsByAddress = (address) => async (dispatch) => {
  dispatch(actions.getIntercomsByAddressRequest())
  try {
    const intercoms = await Api.request(`/intercoms?address_uuid=${address}`)
    dispatch(actions.getIntercomsByAddressSuccess(intercoms))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.getIntercomsByAddressError(message))
  }
}

export const extraKeyReaderDeviceOpen =
  ({ uuid, duration, httpRelay }) =>
  async (dispatch) => {
    try {
      const payload = await Api.request(`/intercoms/${uuid}/open_second_door`, {
        method: 'PATCH',
        body: JSON.stringify({
          duration,
          http_relay: httpRelay,
        }),
      })
      dispatch(showInfoMessage(payload.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return Promise.resolve(error)
    }
  }

export const createExtraKeyReader =
  ({ uuid, kind, alias, httpLink }) =>
  async (dispatch) => {
    try {
      const payload = await Api.request(`/intercoms/${uuid}/extra_key_reader`, {
        method: 'POST',
        body: JSON.stringify({ kind, alias, http_relay: httpLink }),
      })
      dispatch(showInfoMessage(payload.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return Promise.resolve(error)
    }
  }

export const deleteExtraKeyReader =
  ({ uuid }) =>
  async (dispatch) => {
    try {
      const payload = await Api.request(`/intercoms/${uuid}/extra_key_reader`, {
        method: 'DELETE',
      })
      dispatch(showInfoMessage(payload.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return Promise.resolve(error)
    }
  }

export const updateExtraKeyReader =
  ({ uuid, alias }) =>
  async (dispatch) => {
    try {
      const payload = await Api.request(`/intercoms/${uuid}/extra_key_reader`, {
        method: 'PATCH',
        body: JSON.stringify({ alias }),
      })
      dispatch(showInfoMessage(payload.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return Promise.resolve(error)
    }
  }
export const getCamerasByAddress = (address) => async (dispatch) => {
  dispatch(actions.getCamerasByAddressRequest())
  try {
    const cameras = await Api.request(
      `/cameras?address_uuid=${address}&belong_to_intercom=false`
    )
    dispatch(actions.getCamerasByAddressSuccess(cameras))
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.getCamerasByAddressError(message))
  }
}

export const getUnlinkedIntercoms =
  ({
    serial_number,
    limit = 25,
    q,
    available_gates_only = false,
    is_installed,
  }) =>
  async (dispatch) => {
    dispatch(actions.getUnlinkedIntercomsRequest())
    try {
      // `/intercoms${
      //     serialNumber ? `?serial_number=${serialNumber}` : ''
      //   }?is_installed=false&limit=${limit}&q=${q}&available_gates_only=${availableGatesOnly}`,
      const unlinkedIntercoms = await Api.request(
        buildUrl('/intercoms', {
          serial_number,
          limit,
          q,
          is_installed,
          available_gates_only,
        })
      )
      dispatch(actions.getUnlinkedIntercomsSuccess(unlinkedIntercoms))
      return Promise.resolve(unlinkedIntercoms)
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getUnlinkedIntercomsError(message))
      return Promise.reject(err)
    }
  }

export const getUnlinkedCameras = (serialNumber) => async (dispatch) => {
  dispatch(actions.getUnlinkedCamerasRequest())
  try {
    const unlinkedCameras = await Api.request(
      `/cameras${
        serialNumber ? `?serial_number=${serialNumber}` : ''
      }?is_installed=false&belong_to_intercom=false`
    )
    dispatch(actions.getUnlinkedCamerasSuccess(unlinkedCameras))
  } catch (err) {
    const { message } = await err.json()

    dispatch(actions.getUnlinkedCamerasError(message))
  }
}

// type ILinkNewDeviceParams = {
//   identifier,
//   address,
//   entry,
//   first_flat,
//   last_flat,
// }

// type ILinkDeviceToEntry = {
//   entry_uuid,
//   device_uuid,
//   device_type: 'intercom' | 'camera',
// }
// params: ILinkNewDeviceParams | ILinkDeviceToEntry
export const linkDevice = (params) => async (dispatch) => {
  dispatch(actions.linkDeviceRequest())
  try {
    const getParams = () => {
      const res = []
      Object.entries(params).forEach(([key, val]) => {
        if (typeof val !== 'undefined') {
          res.push(`${key}=${val}`)
        }
      })
      return res.join('&')
    }
    const address = await Api.request(
      `/installation_points/link_device?${getParams()}`,
      {
        method: 'PUT',
      }
    )
    dispatch(actions.linkDeviceSuccess(address?.message))
    return Promise.resolve(address)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.linkDeviceError(message))
    return Promise.reject(message)
  }
}

export const unlinkDevice = (intercomUuid, houseUuid) => async (dispatch) => {
  dispatch(actions.unlinkDeviceRequest())
  try {
    const payload = await Api.request(
      `/installation_points/unlink_device?identifier=${intercomUuid}&house_uuid=${houseUuid}`,
      {
        method: 'PUT',
      }
    )

    dispatch(actions.unlinkDeviceSuccess(payload?.message))
    return Promise.resolve(payload?.message)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.unlinkDeviceError(message))
    return Promise.reject(message)
  }
}

export const getCamera = (uuid) => async (dispatch) => {
  dispatch(actions.getCameraRequest())
  try {
    const [camera] = await Api.request(`/cameras?intercom_uuid=${uuid}`)

    dispatch(actions.getCameraSuccess(camera))
    return Promise.resolve(camera)
  } catch (err) {
    const msg = ''
    dispatch(actions.getCameraError(msg))
    return Promise.reject(msg)
  }
}

export const syncIntercom = (uuid) => async (dispatch) => {
  dispatch(actions.syncIntercomRequest())
  try {
    const payload = await Api.request(`/intercoms/${uuid}/sync`, {
      method: 'PUT',
    })

    dispatch(actions.syncIntercomSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncIntercomError(message))
    return Promise.reject()
  }
}

export const syncCamera = (cameraUuid) => async (dispatch) => {
  dispatch(actions.syncCameraRequest())
  try {
    const payload = await Api.request(`/cameras/${cameraUuid}/sync`, {
      method: 'PATCH',
    })

    dispatch(actions.syncCameraSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncCameraError(message))
    return Promise.reject()
  }
}

export const fetchIntercomByUuid = (uuid) => async (dispatch) => {
  dispatch(actions.fetchIntercomByUuidRequest())
  try {
    const intercom = await Api.request(
      `/intercoms/${uuid}`,
      {},
      `${config.redirectUri}/api/v2`
    )
    // todo
    dispatch(actions.fetchIntercomByUuidSuccess(intercom))
    return Promise.resolve(intercom)
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.fetchIntercomByUuidError(error))
    return Promise.reject()
  }
}

export const openDoor = (uuid, duration) => async (dispatch) => {
  dispatch(actions.openDoorRequest())
  try {
    const payload = await Api.request(`/intercoms/${uuid}/open_door`, {
      method: 'PATCH',
      body: JSON.stringify({ duration }),
    })

    dispatch(actions.openDoorSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.openDoorError(message))
    return Promise.reject()
  }
}

// type DeviceSettings = {
//   ext_com?: 0 | 1 | 2,
//   general?: number,
//   speak_handset_tx?: number,
//   speak_loudspeaker?: number,
//   flat_offset?: number,
//   preset_name?,
//   door_open_time_general?: number,
// }

export const updateDeviceSettings =
  (deviceUuid, settings, isOnline) => async (dispatch) => {
    dispatch(actions.updateDeviceSettingsRequest())
    try {
      const payload = await Api.request(
        `/intercoms/${deviceUuid}/update_settings`,
        {
          method: 'PUT',
          body: JSON.stringify(settings),
        }
      )

      dispatch(actions.updateDeviceSettingsSuccess(payload?.message))
      return Promise.resolve()
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.updateDeviceSettingsError(message))
      return Promise.reject()
    }
  }

export const fetchDeviceType = (identifier) => async (dispatch) => {
  dispatch(actions.fetchDeviceTypeRequest())
  try {
    const { type } = await Api.request(`/devices/type?identifier=${identifier}`)
    dispatch(actions.fetchDeviceTypeSuccess(type))
    return Promise.resolve(type)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchDeviceTypeError(message))
    return Promise.reject(message)
  }
}

export const fetchUnlinkedDevices = (q) => async (dispatch) => {
  dispatch(actions.fetchUnlinkedDevicesRequest())
  try {
    const devices = await Api.request(`/devices/unlinked?q=${q}`)
    dispatch(actions.fetchUnlinkedDevicesSuccess(devices))
    return Promise.resolve(devices)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchUnlinkedDevicesError(message))
    return Promise.reject(err)
  }
}

export const fetchSoundPresets = () => async (dispatch) => {
  dispatch(actions.fetchSoundPresetsRequest())
  try {
    const presets = await Api.request(`/sound_presets`)
    dispatch(actions.fetchSoundPresetsSuccess(presets))
    return Promise.resolve(presets)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchSoundPresetsFailure(message))
    return Promise.reject(err)
  }
}

export const updateIntercomSounds =
  ({ intercomUuid, presetName, isOnline }) =>
  async (dispatch) => {
    dispatch(actions.updateIntercomSoundsRequest())
    try {
      const payload = await Api.request(
        `/intercoms/${intercomUuid}/standard_sounds`,
        {
          method: 'POST',
          body: JSON.stringify({ name: presetName }),
        }
      )
      dispatch(actions.updateIntercomSoundsSuccess(payload?.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.updateIntercomSoundsFailure(message))
      return Promise.reject(err)
    }
  }
