import React, { useRef } from 'react'
import { Route } from 'react-router-dom'
import { Search } from 'sputnik-ui'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import OrderPage from 'components/pages/OrderPage'
import OrdersTemplate from 'components/templates/OrdersTemplate'
import Orders from 'components/organisms/Orders'
import FilterButton from 'components/atoms/FilterButton'
import useOrders from 'hooks/useOrders'

// const OrderPage = React.lazy(() => import('components/pages/OrderPage'))

const styles = { sortIcon: { width: 14, height: 14, marginLeft: 4 } }

function OrdersPage({ match }) {
  const scrollRef = useRef()
  const { t } = useTranslation(['common', 'orders'])

  const {
    incrementPage,
    orders,
    ordersCount,
    bind,
    sort,
    handleSort,
    filters,
    handleFilter,
    loading,
  } = useOrders()

  return (
    <>
      <OrdersTemplate
        header={
          <>
            <Typography
              variant="h5"
              style={{ marginBottom: '1rem' }}
              ref={scrollRef}
            >
              {ordersCount} {t('orders:units.order', { count: ordersCount })}
            </Typography>
            <Search
              fullWidth
              style={{ position: 'sticky', top: 0, zIndex: 2 }}
              placeholder={t('orders:search_placeholder')}
              {...bind}
            />
          </>
        }
        filters={
          <>
            <FilterButton
              selected={sort.by === 'created_at'}
              onClick={() => handleSort('created_at')}
            >
              {sort.by !== 'created_at'
                ? t('common:filters.new_first')
                : sort.order === 'desc'
                ? t('common:filters.new_first')
                : t('common:filters.old_first')}
              {sort.by === 'created_at' &&
                (sort.order === 'desc' ? (
                  <ArrowDownward style={styles.sortIcon} />
                ) : (
                  <ArrowUpward style={styles.sortIcon} />
                ))}
            </FilterButton>
            <FilterButton
              selected={sort.by === 'short_address'}
              onClick={() => handleSort('short_address')}
            >
              {t('common:filters.by_address')}{' '}
              {sort.by === 'short_address' &&
                (sort.order === 'desc' ? (
                  <ArrowDownward style={styles.sortIcon} />
                ) : (
                  <ArrowUpward style={styles.sortIcon} />
                ))}
            </FilterButton>

            <FilterButton
              color="pink"
              selected={filters.urgent === true}
              onClick={() => handleFilter({ urgent: !filters.urgent })}
            >
              {t('common:filters.urgent')}
            </FilterButton>
            <FilterButton
              color="peach"
              selected={filters.type === 'configuration'}
              onClick={() => handleFilter({ type: 'configuration' })}
            >
              {t('common:filters.configuration')}
            </FilterButton>
            <FilterButton
              color="lightblue"
              selected={filters.type === 'installation'}
              onClick={() => handleFilter({ type: 'installation' })}
            >
              {t('common:filters.installation')}
            </FilterButton>
            <FilterButton
              color="green"
              selected={filters.type === 'citizen_request'}
              onClick={() => handleFilter({ type: 'citizen_request' })}
            >
              {t('common:filters.from_citizen')}
            </FilterButton>
            <FilterButton
              color="pink"
              selected={filters.type === 'anomaly'}
              onClick={() => handleFilter({ type: 'anomaly' })}
            >
              {t('common:filters.anomaly')}
            </FilterButton>
          </>
        }
      >
        <Orders
          orders={orders}
          loading={loading}
          incrementPage={incrementPage}
        />
        {/* 
      {archivedOrders && archivedOrders.length > 0 && (
        <>
          <Orders
            loading={loadingArchive}
            archived
            orders={archivedOrders}
          ></Orders>
        </>
      )}

      {(loadingOrders || loadingArchive) && (
        <CircularProgress style={{ margin: '1rem auto 0 auto' }} />
      )}

      {Boolean(archivePages) &&
        !loadingArchive &&
        archivedOrdersPage >= archivePages && (
          <Button
            onClick={() =>
              scrollRef.current &&
              scrollRef.current.scrollIntoView({ behavior: 'smooth' })
            }
            primary
            size="fullScreen"
            variant="text"
            style={{ maxWidth: 'none', marginTop: '1rem' }}
          >
            {t('common:button.to_top')}
          </Button>
        )} */}
      </OrdersTemplate>
      <Route component={OrderPage} exact path="/orders/:order" />
    </>
  )
}

export default OrdersPage
