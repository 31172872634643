// @flow
import * as React from 'react'
import { SingleChildTemplate, Localities } from 'components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { fetchArea, getLocalities } from 'store/resources/operations'
import { clearResources } from 'store/actions'

function AreaPage({
  dispatch,
  match: {
    params: { area },
  },
}) {
  React.useEffect(() => {
    if (area) {
      dispatch(fetchArea(area))
      dispatch(getLocalities({ parentUuid: area }))
    }
    return () => dispatch(clearResources())
  }, [dispatch, area])
  return (
    <SingleChildTemplate>
      <Localities />
    </SingleChildTemplate>
  )
}

export default connect()(withRouter(AreaPage))
