import React, { useEffect, useState } from 'react'
import MainTemplate from 'components/templates/MainTemplate'
import { Button, ModalContent, Modal } from 'sputnik-ui'
import WarrantyContainer from 'features/Warranty/WarrantyContainer'
import WarrantyForm from 'features/Warranty/WarrantyForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchWarranty,
  clearWarranty,
  closeSuccessOrderModal,
} from 'features/Warranty/warrantySlice'
import { useRouteMatch } from 'react-router'
import WarrantyCurrent from 'features/Warranty/WarrantyCurrent'
import { useTranslation } from 'react-i18next'
import { SuccessWarrantyOrder } from 'features/Warranty/SuccessWarrantyOrder'

function WarrantyPage() {
  const [open, setOpen] = useState(false)
  const isSuccessOrder = useSelector(({ warranty }) => warranty.successOrder)

  const { t } = useTranslation(['warranty', 'orders', 'common'])
  const dispatch = useDispatch()

  const match = useRouteMatch('/warranty/:number')?.params?.number
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  useEffect(() => {
    dispatch(fetchWarranty())
    return () => dispatch(clearWarranty())
  }, [dispatch])

  const modalTab = { label: t('orders:units.order_0') }

  const handleCloseSuccessOrderModal = () => {
    dispatch(closeSuccessOrderModal())
  }

  return (
    <>
      {match ? (
        <WarrantyCurrent number={match} />
      ) : (
        <MainTemplate>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <div />
            <Button primary variant="v2" onClick={handleOpen}>
              {t('warranty:action.create')}
            </Button>
          </div>
          <Modal
            tabs={modalTab}
            open={open}
            close={handleClose}
            label={t('orders:units.order_0')}
          >
            <ModalContent style={{ background: '#fff' }}>
              <WarrantyForm closeModal={handleClose} />
            </ModalContent>
          </Modal>
          <WarrantyContainer />
          <SuccessWarrantyOrder
            open={isSuccessOrder}
            onClose={handleCloseSuccessOrderModal}
          />
        </MainTemplate>
      )}
    </>
  )
}
export default WarrantyPage
