import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const variants = {
  installation: 'lightblue',
  configuration: 'peach',
  citizen_request: 'green',
  anomaly: 'pink',
}
// dark ? 'Dark' :
const useOrderColor = (orderType, dark) => {
  const theme = useContext(ThemeContext)
  const colorVar = `${variants[orderType]}${dark ? 'Dark' : ''}`

  const color = theme.palette.other[colorVar]
  return color
}

export default useOrderColor
