import React from 'react'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  IconButton,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Archive } from '@material-ui/icons'

import OrderIcon from 'components/atoms/OrderIcon'
import { formatDate } from 'utils/helpers'
import { completeOrder } from 'store/orders/operations'

const Wrapper = styled(ListItem)`
  && {
    ${(p) =>
      p.isUrgent &&
      css`
        background: ${(p) => p.theme.palette.error[50]};

        &:hover {
          background: ${(p) => p.theme.palette.error[100]};
        }
      `}
  }
`

function Order({
  message,
  is_urgent,
  created_at,
  short_address,
  type,
  id,
  number,
  archived,
  serial_number,
  ...props
}) {
  const dispatch = useDispatch()
  return (
    <Wrapper
      button
      disableRipple
      // style={{ borderRadius: 8 }}
      component={Link}
      to={`/orders/${id}`}
      isUrgent={is_urgent}
    >
      <OrderIcon variant={type} />
      <ListItemText
        primary={message}
        secondary={`${short_address || serial_number} · ${formatDate(
          created_at
        )}`}
      />

      <ListItemSecondaryAction>
        {type === 'anomaly' && !archived ? (
          <IconButton onClick={() => dispatch(completeOrder(id))}>
            <Archive />
          </IconButton>
        ) : (
          <>
            <Typography color="textSecondary">#{number}</Typography>

            {is_urgent && (
              <span role="img" aria-label="urgent">
                🔥
              </span>
            )}
          </>
        )}
      </ListItemSecondaryAction>
    </Wrapper>
  )
}

export default Order
