import * as types from './types'
import { CLEAR_STATUS } from '../ui/types'

const initialState = {
  list: null,
  msg: null,
  error: null,
}

export default function codes(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_ACCESS_CODES_SUCCESS:
      return { ...state, list: payload.accessCodes }
    case types.ADD_ACCESS_CODE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.ADD_ACCESS_CODE_FAILURE:
      return { ...state, error: payload.error }
    case types.REMOVE_ACCESS_CODE_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.REMOVE_ACCESS_CODE_FAILURE:
      return { ...state, error: payload.error }
    case CLEAR_STATUS:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
