function warrantyGetColor(status) {
  switch (status) {
    case 'repaired':
      return '#00CE5F'

    case 'repair':
      return '#FFD600'
    case 'taken_in_repair':
      return '#FFD600'

    case 'review':
      return '#0EA7FF'

    case 'refusal':
      return '#FF0166'

    default:
      return '#ccc'
  }
}

export default warrantyGetColor
