import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import { FileCopy, DeleteForever } from '@material-ui/icons'
import { ReactComponent as KeyIcon } from 'assets/Nfc.svg'
import { showInfoMessage } from 'store/ui/actions'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'
import { useTranslation } from 'react-i18next'

import { removeKey, getKeys } from 'store/keys/operations'

const Key = ({ parentUuid, isFlat = false, hex, onClick, children }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['keys', 'info'])
  const copyKey = useCallback(() => {
    const el = document.createElement('textarea')
    el.value = children
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    dispatch(showInfoMessage(t('info:copy.key')))
  }, [children, dispatch, t])
  const [open, setOpen] = useState(false)
  const handleDelete = () => {
    dispatch(removeKey(parentUuid, hex)).then(() => {
      dispatch(getKeys(parentUuid, 1, 1000, isFlat))
      setOpen(false)
    })
  }
  return (
    <ListItem>
      <ListItemIcon>
        <KeyIcon />
      </ListItemIcon>
      <ListItemText primary={children} />
      <ListItemSecondaryAction>
        <IconButton disableRipple onClick={copyKey}>
          <FileCopy />
        </IconButton>
        <IconButton disableRipple onClick={() => setOpen(true)}>
          <DeleteForever color="error" />
        </IconButton>
      </ListItemSecondaryAction>

      <ConfirmActionModal
        action={t('keys:actions.remove_key')}
        handleAction={handleDelete}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </ListItem>
  )
}
export default Key
