import { Box, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useTheme } from 'styled-components'

// werwolf component

export const PageMenuList = ({ children, ...props }) => {
  const theme = useTheme()
  const query = useMediaQuery(`(min-width:${theme.sizes.tablet})`)
  return (
    <>
      {query ? (
        <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }} {...props}>
          {children}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }} {...props}>
          {children}
        </Box>
      )}
    </>
  )
}
