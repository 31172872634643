// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DomainIcon from '@material-ui/icons/Domain'

import { getHouses } from 'store/operations'
import { selectHouse } from 'store/actions'
import { useTranslation } from 'react-i18next'

const searchHouses = (props, query) => {
  const { selected, dispatch } = props

  const parent_uuid = selected.street.uuid
  const q = query || null

  dispatch(
    getHouses({
      parent_uuid,
      q,
    })
  )
}

const Houses = ({ houses, err, dispatch, history, ...props }) => {
  const { t } = useTranslation('geo')

  return (
    <Card
      title={t('geo:locations.house_plural')}
      titleIcon={<DomainIcon />}
      {...props}
    >
      {houses ? (
        houses.map((h) => (
          <ListItem
            key={h.uuid}
            button
            onClick={() => {
              dispatch(selectHouse(h))
              history.push(`/h/${h.uuid}`)
            }}
            disableRipple
          >
            <ListItemIcon>
              <DomainIcon />
            </ListItemIcon>
            <ListItemText primary={h.full_name} />
            <ListItemSecondaryAction style={{ paddingRight: '1rem' }}>
              <ArrowForwardIcon />
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.houses')} err={err} />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  houses: resources.houses.list,
  err: resources.houses.err,
})

let toExp = withSearch(searchHouses)(Houses)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
