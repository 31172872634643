import React from 'react'
import { Box, TableBody, TableContainer } from '@material-ui/core'
import {
  TableHead,
  TableRow,
  Table,
  TableCell,
  Search,
  Input,
  Typography,
  Checkbox,
  formatDeviceId,
  Button,
} from 'sputnik-ui'
import styled from 'styled-components'
import { copyToClipboard } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { showInfoMessage } from 'store/ui/actions'
import { PageMenu, StatusDeviceIndicator } from 'shared/ui'
import { ReactComponent as CopyIcon } from 'assets/Copy.svg'

const StyledAboutUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media screen and (min-width: ${(p) => p.theme.sizes.screen}) {
    align-items: flex-end;
  }
`

export const DeviceZone = ({ ...props }) => {
  const dispatch = useDispatch()
  const handleCopyDeviceId = (uuid) => {
    copyToClipboard(uuid)
    dispatch(showInfoMessage('Номер устройства скопирован'))
  }

  return (
    <>
      <PageMenu>
        <div>
          <Typography
            variant="note"
            color="textSecondary"
            style={{ marginBottom: '5px' }}
          >
            Калитка
          </Typography>
          <Typography
            variant="title"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ marginRight: 8 }}>
              {formatDeviceId(String(2228345443))}
            </div>
            <StatusDeviceIndicator />
            <CopyIcon
              style={{ marginLeft: 14, cursor: 'pointer' }}
              onClick={() => handleCopyDeviceId('uuid')}
            />
          </Typography>
        </div>
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
          <Button variant="v2" subtle>
            Забрать доступ
          </Button>
          <Button variant="v2" subtle>
            Дать доступ
          </Button>
          <Button variant="v2" primary onClick={() => console.log('kek')}>
            Сохранить и продолжить
          </Button>
        </div>
      </PageMenu>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: '34px',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
        {...props}
      >
        <Box>
          <Typography variant="headline" style={{ marginBottom: 6 }}>
            Редактирование зоны доступа к устройствам
          </Typography>
          <Typography color="textSecondary">
            Выберите подъезды, жители которых получат доступ к камере <br /> в
            мобильном приложении
          </Typography>
        </Box>
        <StyledAboutUser>
          <Box sx={{ display: 'flex', mb: '7px', alignItems: 'baseline' }}>
            <Typography
              color="textSecondary"
              variant="caption"
              style={{ marginRight: 8 }}
            >
              Добавил:
            </Typography>
            <Typography>Олег Палыч</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginRight: 8 }}
            >
              Когда был добавлен:
            </Typography>
            <Typography>22.02.2002</Typography>
          </Box>
        </StyledAboutUser>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Input label="Заметка" fullWidth style={{ marginBottom: '8px' }} />
        <Typography
          variant="note"
          color="textSecondary"
          fullWidth
          style={{ marginBottom: '24px' }}
        >
          Укажите дополнительную информацию о камере, ее увидят только ваши
          сотрудники
        </Typography>
        <Search
          placeholder="Поиск по адресам"
          fullWidth
          style={{ marginBottom: '1rem' }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    // checked={selected?.includes(key.hex)}
                    // onClick={() => selectOne(key.hex)}
                    color="blue"
                    style={{
                      margin: '0px',
                      padding: '0px',
                      marginRight: 16,
                    }}
                  />
                  Адрес
                </TableCell>
                <TableCell>Устройства</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {testData.map(({ address, count, uuid, access, type }) => (
                <TableRow key={uuid}>
                  <TableCell>
                    <Checkbox
                      // checked={selected?.includes(key.hex)}
                      // onClick={() => selectOne(key.hex)}
                      color="blue"
                      style={{
                        margin: '0px',
                        padding: '0px',
                        marginRight: 16,
                      }}
                    />
                    <div>
                      <Typography variant="note" color="textSecondary">
                        {type}
                      </Typography>
                      <Typography>{address}</Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary">{count}</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      display: 'flex', // fixme
                      cursor: 'pointer',
                      justifyContent: 'flex-end',
                      color: `${access ? '#FF3D71' : '#0EA7FF'}`, // fixme
                    }}
                  >
                    {access ? 'Забрать доступ' : 'Дать доступ'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

let testData = [
  {
    address: 'Казань, козина 3а',
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: false,
    type: 'Калитка',
  },
  {
    address: 'Казань, козина 3а',
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: true,
    type: 'Камера',
  },
  {
    type: 'camera',
    address: `test${Math.floor(Math.random() * 10)}`,
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: true,
  },
  {
    type: 'gate',
    address: `test${Math.floor(Math.random() * 10)}`,
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: true,
  },
  {
    type: 'gate',
    address: `test${Math.floor(Math.random() * 10)}`,
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: true,
  },
  {
    type: 'gate',
    address: `test${Math.floor(Math.random() * 10)}`,
    count: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: true,
  },
]
