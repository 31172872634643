// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { searchInstallationPoint } from 'store/operations'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

// type Props = {
//   match: Object,
//   dispatch,
//   Header: React.ComponentType<{}>,
//   history: Object,
//   handleClose,
//   open: boolean,
//   error: string,
// }

class SearchAddressPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intercom: '',
      error: null,
    }
  }

  handleChange = (name) => (e) => {
    this.setState({
      [name]: e.target.value,
      error: null,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { intercom } = this.state
    const { props } = this
    if (intercom) {
      props
        .dispatch(searchInstallationPoint(intercom))
        .then((entryUuid) => props.history.push(`/e/${entryUuid}`))
        .catch((error) => this.setState({ error }))
    }
  }

  render() {
    const { props, state } = this
    return (
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        PaperProps={{ style: styles.content }}
      >
        <DialogTitle>Поиск адреса</DialogTitle>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <TextField
              value={state.intercom}
              fullWidth
              label="Номер панели"
              onChange={this.handleChange('intercom')}
              placeholder="Например, A083AA"
              margin="dense"
              autoFocus
            />

            {!!state.error && (
              <Typography
                align="center"
                color="error"
                style={{ marginTop: '1rem' }}
              >
                {state.error}
              </Typography>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} secondary rounded tabIndex={-1}>
            отмена
          </Button>
          <Button
            primary
            rounded
            onClick={this.handleSubmit}
            disabled={!state.intercom}
          >
            искать
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = {
  content: { overflow: 'visible', width: 418 },
  submitBtn: { marginLeft: 'auto', marginTop: 16 },
  input: {},
  divider: {
    backgroundColor: 'black',
    width: 30,
    height: 2,
    marginRight: '0.625rem',
    marginLeft: '0.625rem',
  },
}

const mapStateToProps = ({ devices }) => ({
  error: devices.error,
})

export default connect(mapStateToProps)(withRouter(SearchAddressPage))
