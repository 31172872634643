// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { TwoChildrenTemplate, Citizens, ConfirmActionModal } from 'components'
import { withRouter } from 'react-router-dom'
import Keys from 'components/organisms/Keys'

import { unlinkCitizen } from 'store/operations'
import { clearResources } from 'store/actions'
import { fetchFlat, getCitizens } from 'store/resources/operations'
import { getKeys } from 'store/keys/operations'
import { useTranslation } from 'react-i18next'

function FlatPage({
  dispatch,
  match: {
    params: { flat },
  },
}) {
  const { t } = useTranslation('people')
  const [userToUnlink, setUserToUnlink] = React.useState(null)
  React.useEffect(() => {
    if (flat) {
      dispatch(fetchFlat(flat))
      dispatch(getCitizens({ flat }))
      dispatch(getKeys(flat))
    }
    return () => dispatch(clearResources())
  }, [dispatch, flat])

  return (
    <TwoChildrenTemplate>
      <Citizens confirmAction={(user) => () => setUserToUnlink(user)} />
      <div>
        <Keys />
      </div>
      <ConfirmActionModal
        action={t('people:unbind_person')}
        open={!!userToUnlink}
        handleClose={() => setUserToUnlink(null)}
        handleAction={() =>
          dispatch(unlinkCitizen(userToUnlink)).then(() => {
            setUserToUnlink(null)
            dispatch(fetchFlat(flat))
            dispatch(getCitizens({ flat }))
          })
        }
      />
    </TwoChildrenTemplate>
  )
}

export default connect()(withRouter(FlatPage))
