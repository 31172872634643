import React from 'react'
import { Typography } from '@material-ui/core'
import { Domain, VolumeUp, DeveloperBoard } from '@material-ui/icons'
import styled from 'styled-components'
import useOrderColor from 'hooks/useOrderColor'
import NewMessage from 'components/molecules/NewMessage'
import DeviceIcon from 'components/atoms/DeviceIcon'
import ChatBubble from 'components/atoms/ChatBubble'
import MessageIcon from 'components/atoms/MessageIcon'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const styles = {
  sectionIcon: {
    // position: 'absolute',
    left: -60,
    top: 0,
    transform: 'translateY(50%)',
  },
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  background: ${(p) =>
    p.secondary
      ? p.theme.palette.background.secondary
      : p.theme.palette.background.primary};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 24px repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  position: relative;

  @media screen and (min-width: ${(p) => p.theme.sizes.screen}) {
    margin-left: -40px;
  }
`

const commutators = {
  0: 'Метаком',
  1: 'Визит',
  2: 'Цифрал',
  3: 'Элтис',
}

const getDeviceName = (deviceType) => {
  switch (deviceType) {
    case 'camera':
      return i18next.t('devices:units.camera')
    default:
      return i18next.t('devices:units.intercom')
  }
}

const Settings = ({
  message: { identifier, settings, device_type },
  iconStyle,
}) => {
  const { t } = useTranslation('devices')
  return (
    <>
      {identifier && (
        <Grid>
          <DeviceIcon style={iconStyle} deviceType={device_type} />
          <Row>
            <Typography style={{ fontWeight: 400 }} color="textSecondary">
              {getDeviceName(device_type)}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              {identifier}
            </Typography>
          </Row>
        </Grid>
      )}
      {settings && (
        <>
          {settings.first_flat && (
            <Grid>
              <Domain style={iconStyle} />
              <Row>
                <Typography style={{ fontWeight: 400 }} color="textSecondary">
                  {t('devices:params.flat_range')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {settings.first_flat} - {settings.last_flat}
                </Typography>
              </Row>
            </Grid>
          )}

          {settings.ext_com && (
            <Grid>
              <DeveloperBoard style={iconStyle} />
              <Row>
                <Typography style={{ fontWeight: 400 }} color="textSecondary">
                  {t('devices:params.com_type')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {commutators[settings.ext_com_num]}
                </Typography>
              </Row>
            </Grid>
          )}

          {settings.general !== null && settings.general !== undefined && (
            <Grid>
              <VolumeUp style={iconStyle} />

              <Row>
                <Typography style={{ fontWeight: 400 }} color="textSecondary">
                  {t('devices:params.general_volume')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {settings.general}
                </Typography>
              </Row>

              <Row>
                <Typography style={{ fontWeight: 400 }} color="textSecondary">
                  {t('devices:params.mic')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {settings.speak_handset_tx}
                </Typography>
              </Row>

              <Row>
                <Typography style={{ fontWeight: 400 }} color="textSecondary">
                  {t('devices:params.load_speaker')}
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  {settings.speak_loudspeaker}
                </Typography>
              </Row>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

function OrderBody({ order, ...props }) {
  const { t } = useTranslation(['devices'])
  const orderColor = useOrderColor(order.type, true)

  const iconStyle = { ...styles.sectionIcon, color: orderColor }
  switch (order.type) {
    case 'installation':
      return <Settings message={order.message} iconStyle={iconStyle} />
    case 'configuration':
      return <Settings message={order.message} iconStyle={iconStyle} />
    case 'anomaly':
      return order.message.serial_number ? (
        <Grid>
          <DeviceIcon
            deviceType={order.message.device_type}
            style={iconStyle}
          />

          <Row>
            <Typography style={{ fontWeight: 400 }} color="textSecondary">
              {t('devices:units.intercom')}
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              {order.message.serial_number}
            </Typography>
          </Row>
        </Grid>
      ) : null
    case 'citizen_request':
      return (
        <>
          {order.message.messages.map(({ body, created_at, type }) => (
            <Grid key={created_at}>
              <Typography color="textSecondary">
                <MessageIcon type={type} style={styles.sectionIcon} />
              </Typography>
              <ChatBubble date={created_at} type={type}>
                {body}
              </ChatBubble>
            </Grid>
          ))}
          <NewMessage />
        </>
      )
    default:
      return null
  }
}

export default OrderBody
