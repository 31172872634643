import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Loader, Wrapper, Search, Mascotte } from 'sputnik-ui'
import Flat from 'components/molecules/Flat'
import styled from 'styled-components'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import { LocationCity } from '@material-ui/icons'
import NewFlatButton from 'components/atoms/NewFlatButton'
import { useTranslation } from 'react-i18next'
import { Card } from 'shared/ui'

const Body = styled(Wrapper)`
  && {
    flex-direction: column;
    padding: 0;
    position: relative;
  }
`

const Flats = ({
  flats,
  firstFlat,
  lastFlat,
  err,
  dispatch,
  history,
  entry,
  setChangeFlatRangeModal,
  subscriptions,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('')

  const flatNotFound = flats
    ? flats.every((f) => f.number !== Number(searchValue))
    : false

  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const [curFlat, setCurFlat] = useState(null)
  // const closeAnchor = useCallback((e) => {
  //   setAnchorEl(null)
  // }, [])
  // const showAnchor = (event, flat) => {
  //   setAnchorEl(event.currentTarget)
  //   setCurFlat(flat)
  // }

  const handleSearch = useCallback((e) => {
    // if (anchorEl) closeAnchor()

    setSearchValue(Number(e.target.value) || '')
  }, [])

  if (flats && subscriptions) {
    flats.forEach((el) => {
      // mutate flats add subscriptions to data
      const subscription = subscriptions.find((elem) => el.uuid === elem.uuid)
      if (el.uuid === subscription?.uuid) {
        return { ...el, ...subscription }
      }
      return el
    })
  }

  const filteredFlats = flats
    ? flats.filter((f) => f.number.toString().includes(searchValue))
    : []

  // const open = Boolean(anchorEl)

  const { t } = useTranslation(['geo', 'messages'])

  const [addKeyModal, setAddKeyModal] = useState(false)

  const [isNew, setIsNew] = useState(false)

  return (
    <Card style={{ padding: 0 }} {...props}>
      <Typography
        variant="h5"
        style={{
          margin: '1rem',
          marginLeft: '2rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {t('geo:apartments.unit_plural')} ({firstFlat} - {lastFlat})
        <Tooltip
          title={t('devices:actions.change_flat_range')}
          placement="bottom"
        >
          <IconButton
            color="inherit"
            onClick={() => setChangeFlatRangeModal(true)}
            disableRipple
          >
            <LocationCity />
          </IconButton>
        </Tooltip>
      </Typography>
      <Search
        inputProps={{
          min: 1,
          max: 999,
          step: 1,
          // eslint-disable-next-line
        }}
        style={{ borderRadius: 0 }}
        value={searchValue}
        onChange={handleSearch}
        placeholder={t('geo:apartments.search_placeholder')}
      />
      {searchValue &&
        flatNotFound &&
        (isNew ? (
          <Flat
            number={searchValue}
            // isNew
            onClose={() => setIsNew(false)}
            resetSearch={() => setSearchValue('')}
          />
        ) : (
          <NewFlatButton
            style={{ marginBottom: '.625rem' }}
            // onClick={open ? closeAnchor : showAnchor}
            // cancel={open}
            disabled={searchValue < firstFlat || searchValue > lastFlat}
            // onClick={(e) => showAnchor(e, { number: searchValue, isNew: true })}
            onClick={() => setIsNew(true)}
          >
            {searchValue}
          </NewFlatButton>
        ))}
      {flats ? (
        filteredFlats.length < 1 && !isNew ? (
          <div style={{ maxHeight: '30%', margin: '1rem auto' }}>
            <Mascotte
              emote="404"
              style={{ height: 165, marginBottom: '1rem' }}
            />
            <Typography variant="h6" color="textSecondary" align="center">
              {t('messages:search.empty')}
            </Typography>
          </div>
        ) : (
          filteredFlats.map((flat) => (
            <Flat
              // onClick={(e) => showAnchor(e, flat)}
              {...flat}
              // onAddKey={() => setAddKeyModal(true)}
              resetSearch={() => setSearchValue('')}
              key={flat.uuid}
              // expanded={expanded === flat.uuid}
              // setExpanded={setExpanded}
              // onClose={() => setExpanded(null)}
            />
          ))
        )
      ) : (
        <Loader
          msg={t('geo:loading.apartments')}
          err={err}
          style={{ margin: '1rem auto' }}
          fluid
        />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  flats: resources.flats.list,
  firstFlat: resources.entries.current && resources.entries.current.first_flat,
  lastFlat: resources.entries.current && resources.entries.current.last_flat,
  err: resources.flats.err,
  entry: resources.entries.current,
  subscriptions: resources.entries.current?.flats,
})

export default connect(mapStateToProps)(Flats)
