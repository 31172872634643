import React from 'react'
import styled from 'styled-components'

const StatusCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: #c4c4c4; //fixme
`
export const IconWrapper = ({ children, ...props }) => (
  <StatusCircle {...props}>{children}</StatusCircle>
)
