import * as types from './types'

export const getCitizensRequest = () => ({
  type: types.GET_CITIZENS_REQUEST,
})
export const getCitizensSuccess = (citizens) => ({
  type: types.GET_CITIZENS_SUCCESS,
  payload: citizens,
})
export const getCitizensError = (error) => ({
  type: types.GET_CITIZENS_ERROR,
  payload: error,
})

export const unlinkCitizenRequest = () => ({
  type: types.UNLINK_CITIZEN_REQUEST,
})
export const unlinkCitizenSuccess = () => ({
  type: types.UNLINK_CITIZEN_SUCCESS,
})
export const unlinkCitizenError = (error) => ({
  type: types.UNLINK_CITIZEN_ERROR,
  payload: error,
})
