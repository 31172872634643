import React, { useState, useEffect, useCallback } from 'react'
import { Button, Select, Loader } from 'sputnik-ui'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import {
  getUnlinkedCameras,
  linkDevice,
  getIntercomsByAddress,
  getCamerasByAddress,
} from 'store/operations'
import { useTranslation } from 'react-i18next'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

export default function AttachNewCameraModal({ handleClose, open, ...props }) {
  const [selected, setSelected] = useState()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUnlinkedCameras())
  }, [dispatch])
  const unlinkedCameras = useSelector(({ devices }) => devices.unlinkedCameras)
  const { entry, house } = useParams()

  const attachCamera = useCallback(() => {
    dispatch(
      linkDevice({
        house_uuid: house,
        entry_uuid: entry,
        device_uuid: selected.value,
        device_type: 'camera',
      })
    ).then(() => {
      if (entry) {
        dispatch(getIntercomsByAddress(entry))
        dispatch(getCamerasByAddress(entry))
      }
      if (house) {
        dispatch(getIntercomsByAddress(house))
        dispatch(getCamerasByAddress(house))
      }
      dispatch(getUnlinkedCameras())
      setSelected(null)
      handleClose()
    })
  }, [dispatch, entry, handleClose, house, selected])

  const { t } = useTranslation(['common', 'devices'])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: { overflow: 'visible', minWidth: '40%' } }}
    >
      <DialogTitle>{t('devices:cameras.attach_new')}</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        {unlinkedCameras ? (
          <Select
            options={unlinkedCameras.map((x) => ({
              label: x.serial_number,
              value: x.uuid,
            }))}
            handleSelect={(s) => setSelected(s)}
            selected={selected}
            placeholder={t('devices:cameras.choose')}
            isMulti={false}
            autoFocus
          />
        ) : (
          <Loader msg={t('devices:cameras.loading')} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} secondary rounded>
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={attachCamera}
          primary
          rounded
          variant="contained"
          disabled={!selected || (selected && !selected.value)}
        >
          {t('common:button:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
