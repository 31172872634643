import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import GroupIcon from '@material-ui/icons/Group'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'

import { getCitizens } from 'store/operations'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const searchCitizens = (props, query) => {
  const { dispatch } = props

  const flat = props.selected.flat.uuid
  const q = query || null

  dispatch(
    getCitizens({
      flat,
      q,
    })
  )
}

const Citizens = ({
  citizens,
  err,
  dispatch,
  history,
  toggleDrawer,
  confirmAction,
  ...props
}) => {
  const { t } = useTranslation(['geo', 'people'])
  return (
    <Card
      title={t('people:living_person_list')}
      titleIcon={<GroupIcon />}
      {...props}
    >
      {citizens ? (
        citizens.map((_) => (
          <ListItem key={_.uuid}>
            <ListItemIcon>
              <SentimentVerySatisfiedIcon />
            </ListItemIcon>
            <ListItemText primary={`${_.surname} ${_.name}`} />
            <ListItemSecondaryAction>
              <Tooltip placement="top" title={t('people:unbind_person')}>
                <IconButton onClick={confirmAction(_.uuid)}>
                  <ClearIcon color="error" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.residents')} err={err} fluid />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  citizens: resources.citizens.list,
  err: resources.citizens.err,
})

let toExp = withSearch(searchCitizens)(Citizens)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
