import React from 'react'
import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

const ifColor = css`
  background: ${(p) => p.theme.palette.other[p.color]};
  color: ${(p) => p.theme.palette.other[`${p.color}Dark`]};
  border-color: ${(p) => p.theme.palette.other[p.color]};

  &:hover {
    background: ${(p) => p.theme.palette.other[p.color]};
  }
`

const FilterText = styled(Typography)`
  && {
    display: inline-flex;
    padding: 0.125rem 0.325rem;
    cursor: pointer;
    border: 1px dashed ${(p) => p.theme.palette.text.secondary};
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    transition-property: background-color, border-color;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    will-change: background-color, border-color;

    color: ${(p) => p.theme.palette.text.secondary};

    &:hover,
    &:focus,
    &:active {
      background: ${(p) => p.theme.palette.grayscale[2]};
    }
    ${(p) =>
      p.selected &&
      css`
        background: ${(p) => p.theme.palette.primary[500]};
        color: ${(p) => p.theme.palette.primary.contrastText};
        border-color: ${(p) => p.theme.palette.primary[500]};

        &:hover {
          background: ${(p) => p.theme.palette.primary[500]};
        }

        ${(p) => p.color && ifColor}
      `}
  }
`

const Count = styled(Typography)`
  && {
    margin-left: 0.325rem;
    border-radius: 50%;
    background-color: ${(p) =>
      p.selected ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.05)'};
    padding: 0.325rem;
    line-height: 1.125rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`

function FilterButton({ children, count, selected, ...props }) {
  return (
    <FilterText selected={selected} {...props}>
      {children}
      {count && <Count selected={selected}>{count}</Count>}
    </FilterText>
  )
}

export default FilterButton
