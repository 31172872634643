import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'
import * as types from './types'

const initialState = {
  list: null,
}

export default function citizens(state = initialState, { type, payload }) {
  switch (type) {
    // citizens
    case types.GET_CITIZENS_SUCCESS:
      return { ...state, list: payload }
    case types.GET_CITIZENS_ERROR:
      return { ...state, error: payload }
    case types.UNLINK_CITIZEN_ERROR:
      return { ...state, error: payload }
    case CLEAR_RESOURCES:
      return initialState
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
