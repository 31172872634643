import { useState } from 'react'
import config from 'config'
import api from 'utils/api'
import { buildUrl } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { showInfoMessage, showErrorMessage } from 'store/ui/actions'

export const useVideoArchive = () => {
  const [pending, setPending] = useState(false)
  const dispatch = useDispatch()
  async function fetchArchive(params) {
    setPending(true)
    const { datetime, duration, device_uuid, isCamera } = params
    try {
      const cameraUuid = isCamera
        ? await api.request(
            buildUrl(`/cameras/${device_uuid}/archives`, {
              duration,
              datetime,
            }),
            {
              method: 'POST',
            },
            config.baseUri
          )
        : await api
            .request(
              buildUrl(`/cameras?intercom_uuid=${device_uuid}`),
              {},
              config.baseUri
            )
            .then((el) =>
              api.request(
                buildUrl(`/cameras/${el[0].uuid}/archives`, {
                  duration,
                  datetime,
                }),
                {
                  method: 'POST',
                },
                config.baseUri
              )
            )

      const url = cameraUuid.download_url

      setPending(false)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'video_archive')
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      return Promise.resolve(url)
    } catch (err) {
      if (err.status >= 500) {
        dispatch(showInfoMessage('Server Error'))
      }
      setPending(false)
      const { error, description } = await err.json()
      dispatch(showInfoMessage(error || description?.message))
      return Promise.reject(err)
    }
  }

  return { pending, fetchArchive }
}

export default useVideoArchive
