import React from 'react'

function UpdateLabel({ children }) {
  const showUpdateModal = () => {
    alert('kek')
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.updated}>new</div>
      {children}
    </div>
  )
}

const styles = {
  updated: {
    position: 'absolute',
    top: '-20px',
    right: '-35px',
    fontSize: '10px',
    textTransform: 'uppercase',
    color: '#fff',
    background: 'red',
    padding: '3px 10px',
    borderRadius: '5px',
  },
}

export default UpdateLabel
