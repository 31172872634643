import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'components/App'

import { Provider } from 'react-redux'
import {
  theme,
  Intercom,
  i18n,
  Loader,
  ThemeProvider,
  Metrika,
  ErrorHandlerHoc,
} from 'sputnik-ui'
import { I18nextProvider } from 'react-i18next'
import store from './store/store'

// service worker shows update msg
// import { showUpdateMessage } from 'store/ui/actions'
// import registerServiceWorker, { unregister } from './registerServiceWorker'

import 'rc-slider/assets/index.css'

const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <React.Suspense fallback={<Loader fullPage />}>
            <ErrorHandlerHoc>
              <App />
            </ErrorHandlerHoc>
            <Intercom />
            <Metrika />
          </React.Suspense>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

const node = document.getElementById('root')

if (node === null) {
  throw new Error('no node element')
}

ReactDOM.render(<Root />, node)

// const showUpdateToast = (action) => store.dispatch(showUpdateMessage(action))
// registerServiceWorker(showUpdateToast)

// unregister()
