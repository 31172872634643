// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(p) => p.theme.palette.background.primary};
  height: min-content;
`

const Content = styled(Container)`
  height: auto;
  max-width: 50rem;
`

const Header = styled.div``
const Subheader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;

  @media (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
    align-items: center;

    & > *:first-child {
      margin-bottom: 1rem;
    }
  }
`

const OrderPageTemplate = ({ header, subheader, children, ...props }) => (
  <Wrapper {...props}>
    <Content>
      <Header>{header}</Header>
      <Subheader>{subheader}</Subheader>
      {children}
    </Content>
  </Wrapper>
)

export default OrderPageTemplate
