export const GET_FLATS_REQUEST = 'resources/flats/GET_FLATS_REQUEST'
export const GET_FLATS_SUCCESS = 'resources/flats/GET_FLATS_SUCCESS'
export const GET_FLATS_ERROR = 'resources/flats/GET_FLATS_ERROR'

export const FETCH_FLAT_REQUEST = 'resources/flats/FETCH_FLAT_REQUEST'
export const FETCH_FLAT_SUCCESS = 'resources/flats/FETCH_FLAT_SUCCESS'
export const FETCH_FLAT_FAILURE = 'resources/flats/FETCH_FLAT_FAILURE'

export const CHANGE_FLAT_RANGE_REQUEST =
  'resources/flats/CHANGE_FLAT_RANGE_REQUEST'
export const CHANGE_FLAT_RANGE_SUCCESS =
  'resources/flats/CHANGE_FLAT_RANGE_SUCCESS'
export const CHANGE_FLAT_RANGE_ERROR = 'resources/flats/CHANGE_FLAT_RANGE_ERROR'

export const CHANGE_FLAT_CONFIG_REQUEST =
  'resources/flats/CHANGE_FLAT_CONFIG_REQUEST'
export const CHANGE_FLAT_CONFIG_SUCCESS =
  'resources/flats/CHANGE_FLAT_CONFIG_SUCCESS'
export const CHANGE_FLAT_CONFIG_FAILURE =
  'resources/flats/CHANGE_FLAT_CONFIG_FAILURE'

export const UPDATE_FLAT_REQUEST = 'resources/flats/UPDATE_FLAT_REQUEST'
export const UPDATE_FLAT_SUCCESS = 'resources/flats/UPDATE_FLAT_SUCCESS'
export const UPDATE_FLAT_FAILURE = 'resources/flats/UPDATE_FLAT_FAILURE'
