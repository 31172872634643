import React, { useState, useCallback, useRef } from 'react'
import { IconButton, MenuItem, Typography } from '@material-ui/core'
import { Schedule } from '@material-ui/icons'
import { Wrapper } from 'sputnik-ui'
import styled from 'styled-components'
import i18next from 'i18next'

const Dropdown = styled(Wrapper)`
  position: absolute;
  top: 0;
  flex-wrap: wrap;
  height: fit-content;
  width: 250px;
  z-index: 10;
  padding-left: 0;
  padding-right: 0;
  & > li {
    width: 100%;
  }
`

function OpenTimeSelect({ value, onChange, style = {}, ...props }) {
  const timeOptions = [
    {
      text: `5 ${i18next.t('time:units.sec_2')}`,
      value: 5,
      unit: 'sec',
      valueInUnits: 5,
    },
    {
      text: `1 ${i18next.t('time:units.min_0')}`,
      value: 60,
      unit: 'min',
      valueInUnits: 1,
    },
    {
      text: `10 ${i18next.t('time:units.min_2')}`,
      value: 600,
      unit: 'min',
      valueInUnits: 10,
    },
    {
      text: `1 ${i18next.t('time:units.hour_0')}`,
      value: 3600,
      unit: 'hour',
      valueInUnits: 1,
    },
    {
      text: `6 ${i18next.t('time:units.hour_2')}`,
      value: 21600,
      unit: 'hour',
      valueInUnits: 6,
    },
    {
      text: `12 ${i18next.t('time:units.hour_2')}`,
      value: 43200,
      unit: 'hour',
      valueInUnits: 12,
    },
    {
      text: `24 ${i18next.t('time:units.hour_1')}`,
      value: 86400,
      unit: 'hour',
      valueInUnits: 24,
    },
  ]
  const dropdownRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  const hideDropdown = useCallback((e) => {
    setIsOpen(false)
    document.removeEventListener('click', hideDropdown)
  }, [])

  const handleOptionClick = (value) => {
    onChange && onChange(value)
    setIsOpen(false)
    document.removeEventListener('click', hideDropdown)
  }

  const showDropdown = useCallback(
    (e) => {
      setIsOpen(true)
      document.addEventListener('click', hideDropdown)
      e.stopPropagation()
    },
    [hideDropdown]
  )

  const selectedOption = timeOptions.find((option) => option.value === value)
  return (
    <div style={{ position: 'relative', ...style }} {...props}>
      <IconButton onClick={showDropdown} style={{ padding: 0 }}>
        <Schedule style={{ marginRight: 8 }} />
        <Typography color="textPrimary">
          {selectedOption.text}{' '}
          {/* {t(`time:units.${selectedOption.unit}`, {
            count: selectedOption.valueInUnits,
          })} */}
        </Typography>
      </IconButton>
      {isOpen && (
        <Dropdown
          style={{ position: 'absolute', bottom: 0 }}
          background="primary"
          ref={dropdownRef}
        >
          {timeOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.text}
              {/* {option.valueInUnits}{' '} // посмтореть че задумывал Эрик
              {t(`time:units.${option.unit}`, { count: option.valueInUnits })} */}
            </MenuItem>
          ))}
        </Dropdown>
      )}
    </div>
  )
}

export default OpenTimeSelect
