// @flow

export const SYNC_INSTALLATION_POINT_REQUEST =
  'installations/SYNC_INSTALLATION_POINT_REQUEST'
export const SYNC_INSTALLATION_POINT_SUCCESS =
  'installations/SYNC_INSTALLATION_POINT_SUCCESS'
export const SYNC_INSTALLATION_POINT_ERROR =
  'installations/SYNC_INSTALLATION_POINT_ERROR'

export const CLOSE_COLLECTING_KEYS_REQUEST =
  'installations/CLOSE_COLLECTING_KEYS_REQUEST'
export const CLOSE_COLLECTING_KEYS_SUCCESS =
  'installations/CLOSE_COLLECTING_KEYS_SUCCESS'
export const CLOSE_COLLECTING_KEYS_ERROR =
  'installations/CLOSE_COLLECTING_KEYS_ERROR'

export const OPEN_COLLECTING_KEYS_REQUEST =
  'installations/OPEN_COLLECTING_KEYS_REQUEST'
export const OPEN_COLLECTING_KEYS_SUCCESS =
  'installations/OPEN_COLLECTING_KEYS_SUCCESS'
export const OPEN_COLLECTING_KEYS_ERROR =
  'installations/OPEN_COLLECTING_KEYS_ERROR'

export const PROLONG_COLLECTING_KEYS_REQUEST =
  'installations/PROLONG_COLLECTING_KEYS_REQUEST'
export const PROLONG_COLLECTING_KEYS_SUCCESS =
  'installations/PROLONG_COLLECTING_KEYS_SUCCESS'
export const PROLONG_COLLECTING_KEYS_ERROR =
  'installations/PROLONG_COLLECTING_KEYS_ERROR'

export const SEARCH_INSTALLATION_POINT_REQUEST =
  'installations/SEARCH_INSTALLATION_POINT_REQUEST'
export const SEARCH_INSTALLATION_POINT_SUCCESS =
  'installations/SEARCH_INSTALLATION_POINT_SUCCESS'
export const SEARCH_INSTALLATION_POINT_ERROR =
  'installations/SEARCH_INSTALLATION_POINT_ERROR'

export const GENERATE_QR_REQUEST = 'installations/GENERATE_QR_REQUEST'
export const GENERATE_QR_SUCCESS = 'installations/GENERATE_QR_SUCCESS'
export const GENERATE_QR_ERROR = 'installations/GENERATE_QR_ERROR'

export const INVALIDATE_QR_REQUEST = 'installations/INVALIDATE_QR_REQUEST'
export const INVALIDATE_QR_SUCCESS = 'installations/INVALIDATE_QR_SUCCESS'
export const INVALIDATE_QR_ERROR = 'installations/INVALIDATE_QR_ERROR'

export const FETCH_INSTALLATION_POINTS_REQUEST =
  'installations/FETCH_INSTALLATION_POINTS_REQUEST'
export const FETCH_INSTALLATION_POINTS_SUCCESS =
  'installations/FETCH_INSTALLATION_POINTS_SUCCESS'
export const FETCH_INSTALLATION_POINTS_FAILURE =
  'installations/FETCH_INSTALLATION_POINTS_FAILURE'
