import React, { useState, useEffect } from 'react'
import { ReactComponent as ISee } from 'assets/ISee.svg'
import { ReactComponent as Sputnik } from 'assets/Sputnik.svg'

const DeviceIcon = ({ deviceType, ...props }) => {
  const [Icon, setIcon] = useState(null)
  useEffect(() => {
    switch (deviceType) {
      case 'camera':
        return setIcon(ISee)
      default:
        return setIcon(Sputnik)
    }
  }, [deviceType])

  return Icon ? <Icon {...props} /> : null
}

export default DeviceIcon
