import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'
import * as types from './types'

const initialState = {
  list: null,
  current: null,
  entryPending: false,
}

export default function entries(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_ENTRIES_SUCCESS:
      return { ...state, list: payload, error: null }
    case types.GET_ENTRIES_ERROR:
      return { ...state, error: payload }
    case types.FETCH_ENTRY_REQUEST:
      return { ...state, entryPending: true }
    case types.FETCH_ENTRY_SUCCESS:
      return {
        ...state,
        current: payload.entry,
        error: null,
        entryPending: false,
      }
    case types.FETCH_ENTRY_FAILURE:
      return { ...state, error: payload.error, entryPending: false }
    case CLEAR_RESOURCES:
      return initialState
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    default:
      return state
  }
}
