import camelCase from 'lodash/camelCase'
import { combineReducers } from 'redux'
import devicesNew from 'features/Devices/devicesSlice'
import installationsNew from 'features/Devices/installationsSlice'
import camerasNew from 'features/Cameras/camerasSlice'
import warranty from 'features/Warranty/warrantySlice'
import keysPro from 'features/Keys/keysProSlice'
import { citizensProReducer } from 'features/CitizensListPro'

const reducers = {
  devicesNew,
  installationsNew,
  camerasNew,
  warranty,
  keysPro,
  citizensPro: citizensProReducer,
}

const req = require.context('.', true, /\.\/.+\/reducers\.js$/)

req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'))
  reducers[storeName] = req(key).default
})

export default combineReducers(reducers)
