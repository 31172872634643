import React from 'react'
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import DropdownIcon from 'components/atoms/DropdownIcon'
import { useTranslation } from 'react-i18next'

const ifDisabled = css`
  opacity: 0.5;
  pointer-events: none;
  background: ${(p) => p.theme.palette.background.secondary};
`

const Body = styled(ListItem)`
  && {
    background: ${(p) =>
      p.cancel ? p.theme.palette.error['500'] : p.theme.palette.primary['500']};
    padding-left: 1.325rem;
    color: ${(p) =>
      p.cancel
        ? p.theme.palette.error.contrastText
        : p.theme.palette.primary.contrastText};

    &:hover {
      background: ${(p) =>
        p.cancel
          ? p.theme.palette.error['500']
          : p.theme.palette.primary['500']};
    }

    ${(p) => p.disabled && ifDisabled}
  }
`

export default function NewFlatButton({ cancel, children, ...props }) {
  const { t } = useTranslation(['geo', 'messages'])

  return (
    <Body {...props} button disableRipple cancel={cancel}>
      <ListItemIcon style={{ color: 'inherit' }}>
        <DropdownIcon open={cancel} />
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={
          <Typography color="inherit" display="block">
            {cancel
              ? t('geo:apartments.unit_numbered', { number: children })
              : t('geo:apartments.add_numbered', { number: children })}
          </Typography>
        }
      />
    </Body>
  )
}
