import React from 'react'
import { FiberManualRecord, Power, PowerOff } from '@material-ui/icons'
import styled, { keyframes, css } from 'styled-components'

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledOnlineIcon = styled.span`
  color: ${(p) =>
    p.isOnline ? p.theme.palette.success[500] : p.theme.palette.error[500]};
  && {
    width: 0.625rem;
    display: inline-flex;
    align-items: center;
    ${(p) =>
      p.variant === 'circle' &&
      css`
        animation: ${fade} 2s ease-in-out infinite alternate;
      `};
  }
`

const icons = {
  circle: FiberManualRecord,
}

export default function OnlineIcon({
  variant = 'circle',
  isOnline = false,
  ...props
}) {
  const Icon =
    variant === 'power' ? (isOnline ? Power : PowerOff) : icons[variant]
  return (
    <StyledOnlineIcon variant={variant} isOnline={isOnline} {...props}>
      <Icon color="inherit" style={{ width: 'inherit', height: 'inherit' }} />
    </StyledOnlineIcon>
  )
}
