// @flow
import * as React from 'react'
import { Button } from 'sputnik-ui'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { MainTemplate, Countries } from 'components'
import AddAddressModal from 'features/Devices/AddAddressModal'
import DevicesSearchList from 'components/molecules/DevicesSearchList'
import { getCountries } from 'store/resources/operations'
import { clearResources } from 'store/resources/actions'
import ProfileModal from 'components/organisms/ProfileModal'
import AddThirdPartyCameras from 'features/AddThirdPartyCameras'
import { ExcludeTurkey } from 'utils/helpers'

const StyledButtonsList = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`

function HomePage({
  devicesLoading,
  currentOrdersCount,
  userCompanyUuid,
  dataCurrent,
  dispatch,
  history,
  match,
}) {
  const { t } = useTranslation(['orders', 'common', 'devices'])

  // general

  const [profileModal, setProfileModal] = React.useState(false)

  const [AddThirdPartyCamerasModal, setAddThirdPartyCamerasModal] =
    React.useState(false)

  const dateCalc = (e) => {
    const interval = 45 // day

    if (e?.updated_at) {
      const date1 = dayjs(e.updated_at * 1000)
      const date2 = dayjs()

      const result = date2.diff(date1, 'day')
      if (result >= interval) setProfileModal(true)
    } else {
      setProfileModal(true)
    }
  }

  React.useEffect(() => {
    if (dataCurrent) dateCalc(dataCurrent)
  }, [dataCurrent])

  React.useEffect(() => {
    dispatch(getCountries())

    return () => {
      clearResources()
    }
  }, [currentOrdersCount, dispatch])

  return (
    <>
      <MainTemplate>
        <StyledButtonsList>
          <ExcludeTurkey>
            <Button
              secondary
              rounded
              component={Link}
              to="/orders"
              style={{
                position: 'relative',
              }}
            >
              {t('orders:units.order', { count: 2 })}
            </Button>
          </ExcludeTurkey>
          <Button
            secondary
            rounded
            component={Link}
            to="/add_address"
            style={{
              position: 'relative',
            }}
          >
            {t('devices:actions.install')}
          </Button>
          <Button
            secondary
            rounded
            style={{
              position: 'relative',
            }}
            onClick={() => setProfileModal(true)}
          >
            {t('devices:actions.change_service_data.first_text')}
            <br />
            {t('devices:actions.change_service_data.second_text')}
          </Button>
          <Button
            secondary
            rounded
            component={Link}
            to="/warranty"
            style={{
              position: 'relative',
            }}
          >
            {t('devices:actions.warranty')}
          </Button>
          <Button
            secondary
            rounded
            onClick={() => setAddThirdPartyCamerasModal(true)}
            style={{
              position: 'relative',
            }}
          >
            {t('devices:actions.add_third_party_camera')}
          </Button>
        </StyledButtonsList>

        <Countries />
        <div>
          <Typography variant="h5">{t('devices:my')}</Typography>
          <DevicesSearchList />
        </div>
      </MainTemplate>
      <AddAddressModal
        handleClose={() => history.push('/')}
        open={match.url === '/add_address'}
      />
      <ProfileModal
        open={profileModal}
        handleClose={() => setProfileModal(false)}
      />
      <AddThirdPartyCameras
        open={AddThirdPartyCamerasModal}
        handleClose={() => setAddThirdPartyCamerasModal(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { devices, auth, orders, partners } = state

  return {
    devicesLoading: devices.loading,
    userCompanyUuid: auth.user.company.uuid,
    currentOrdersCount: orders.count,
    dataCurrent: partners.current,
  }
}

export default connect(mapStateToProps)(withRouter(HomePage))
