import { Box } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { IconWrapper, StatusDeviceIndicator } from 'shared/ui'
import {
  DeviceModel,
  DropList,
  DropListItem,
  formatDeviceId,
  Typography,
} from 'sputnik-ui'
import { ReactComponent as MoreOptions } from 'assets/MoreOptions.svg'
import styled from 'styled-components'
import { Camera, IconColors, Intercom } from 'sputnik-icons'
import { useTranslation } from 'react-i18next'
import { keyDownWithClick } from 'utils/helpers'

const DeviceCard = styled.div`
  width: 100%;
  /* height: 54px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.palette.stroke.line};
  padding-right: 24px;
  margin-top: 12px;
  padding-bottom: 7px;
  padding-top: 7px;
  /* &:first-child {
    margin-top: 0px;
  } */
  height: fit-content;
`

export const AddressDeviceElement = ({
  device,
  makeMain,
  goToDeviceAccess,
  disconnectDevice,
  goToDeviceZone,
  selectArchiveDevice,
  goToDevicePage,
  openCameraModal,
  isCamera,
  selected,
  ...props
}) => {
  const anchorEl = useRef()
  const { t } = useTranslation('devices')
  const [isMenuOpen, setMenuOpen] = useState(false)
  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleClick = (event) => {
    keyDownWithClick(event, () =>
      isCamera ? openCameraModal(device) : goToDevicePage(device.uuid)
    )
  }

  return (
    <>
      <DropList
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={isMenuOpen}
        close={handleToggleMenu}
        anchorEl={anchorEl?.current}
      >
        {isCamera && (
          <DropListItem
            label={t('devices:cameras.unlink')}
            onClick={() => disconnectDevice(device)}
          />
        )}
        <DropListItem
          label={t('devices:actions.download_archive')}
          onClick={() => selectArchiveDevice(device.uuid, isCamera, true)}
        />
        {/* <DropListItem label="Изменить зону действия устройства" onClick={goToDeviceZone} />
        <DropListItem label="Сделать основной" onClick={makeMain} />
        <DropListItem label="Перейти к доступам квартир" onClick={goToDeviceAccess} /> */}
      </DropList>
      <DeviceCard {...props}>
        <Box
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '10px 0px',
            cursor: 'pointer',
          }}
        >
          {/* <Radio
            checked={selected === device.uuid}
            color="blue"
            style={{ marginRight: 19, padding: 0 }}
            onClick={() => selectArchiveDevice(device.uuid)}
          /> */}
          <IconWrapper style={{ marginRight: 16, height: 40, width: 40 }}>
            {isCamera ? (
              <Camera color={IconColors.white} />
            ) : (
              <Intercom color={IconColors.white} />
            )}
          </IconWrapper>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 7 }}>
              <Typography>
                {device && !isCamera
                  ? formatDeviceId(device?.serial_number)
                  : device?.serial_number}
              </Typography>
              <StatusDeviceIndicator online={device.is_online} />
              <DeviceModel model={device.model} />
            </div>
            <Typography variant="note" color="textSecondary">
              {device.short_address}
            </Typography>
          </div>
        </Box>
        <MoreOptions
          style={{ cursor: 'pointer' }}
          ref={anchorEl}
          onClick={handleToggleMenu}
        />
      </DeviceCard>
    </>
  )
}
