import * as types from './types'

export const fetchMapIntercomsRequest = () => ({
  type: types.FETCH_MAP_INTERCOMS_REQUEST,
})
export const fetchMapIntercomsSuccess = (intercoms) => ({
  type: types.FETCH_MAP_INTERCOMS_SUCCESS,
  payload: { intercoms },
})
export const fetchMapIntercomsFailure = (error) => ({
  type: types.FETCH_MAP_INTERCOMS_FAILURE,
  payload: error,
})
