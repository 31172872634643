import React, { useState, useCallback } from 'react'
import { List, Typography, CircularProgress } from '@material-ui/core'
import { Mascotte } from 'sputnik-ui'
import DeviceSearchSuggestion from 'components/atoms/DeviceSearchSuggestion'
import { useTranslation } from 'react-i18next'
import { EditThirdPartyCameras } from 'features/EditThirdPartyCameras'

const DevicesList = ({ loading, devices, firstInstalled }) => {
  const { t } = useTranslation(['messages', 'devices', 'common'])
  const [thirdPartyCameraModal, setThirdPartyCameraModal] = useState(false)

  const handleCloseModal = useCallback(() => {
    setThirdPartyCameraModal(false)
  }, [])

  const handleOpenModal = useCallback(({ uuid }) => {
    setThirdPartyCameraModal(uuid)
  }, [])

  return devices ? (
    devices.length > 0 ? (
      <>
        <List>
          {devices.map((device, idx) => (
            <DeviceSearchSuggestion
              handleOpenModal={handleOpenModal}
              firstInstalled={firstInstalled}
              key={device.uuid}
              {...device}
            />
          ))}
        </List>
        <EditThirdPartyCameras
          open={!!thirdPartyCameraModal}
          uuid={thirdPartyCameraModal}
          onClose={handleCloseModal}
        />
      </>
    ) : (
      !loading && (
        <>
          <Mascotte
            emote="404"
            style={{ maxHeight: 400, margin: '2rem auto' }}
          />
          <Typography align="center" variant="h6" color="textSecondary">
            {t('messages:content.empty')}
          </Typography>
        </>
      )
    )
  ) : (
    <div style={{ height: '8rem' }}>
      <CircularProgress />
    </div>
  )
}

export default DevicesList
