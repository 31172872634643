import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ConfirmModal } from 'sputnik-ui'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Wonder } from 'assets/Wonder.svg'
import { linkToPaidRepairLicense } from 'shared/const/externalLinks'

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.migration.text.blue};
  text-decoration: none;
`

export const OutOfWarrantyModal = ({ onClose, open, continueNext }) => {
  const { t } = useTranslation('warranty')

  return (
    <ConfirmModal
      open={open}
      title={t('warranty:title.access_limit')}
      label={
        <>
          <span>{t('warranty:offer.start')} </span>
          <StyledLink
            to={{ pathname: linkToPaidRepairLicense }}
            target="_blank"
          >
            {t('warranty:offer.link')}
          </StyledLink>
          <span> {t('warranty:offer.end')}</span>
        </>
      }
      icon={<Wonder />}
      actions={
        <>
          <Button variant="v2" primary fullWidth onClick={onClose}>
            {t('warranty:action.enter_continue')}
          </Button>
          <Button variant="v2" tertiary onClick={continueNext}>
            {t('warranty:action.enter_another')}
          </Button>
        </>
      }
    />
  )
}
