import Api from 'utils/api'
import * as actions from './actions'

export const getCitizens =
  ({ flat, q }) =>
  async (dispatch) => {
    dispatch(actions.getCitizensRequest())
    try {
      const setParams = () => {
        let params = []
        if (q) params.push(`q=${q}`)
        params = params.join('&')
        return params.length > 0 ? `&${params}` : params
      }

      const flats = await Api.request(
        `/citizens/?flat_uuid=${flat}${setParams()}`
      )

      dispatch(actions.getCitizensSuccess(flats))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getCitizensError(message))
    }
  }

export const unlinkCitizen = (uuid) => async (dispatch) => {
  dispatch(actions.unlinkCitizenRequest())
  try {
    await Api.request(`/citizens/${uuid}`, { method: 'DELETE' })

    dispatch(actions.unlinkCitizenSuccess())
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()

    dispatch(actions.unlinkCitizenError(message))
    return Promise.reject(message)
  }
}
