import { Box } from '@material-ui/core'
import { Card } from 'shared/ui'
import { Edit, IconColors, Trash } from 'sputnik-icons'
import { useTranslation } from 'react-i18next'
import {
  FormModalActions,
  Button,
  ConfirmModal,
  FormModal,
  FormModalContent,
  FormModalTitle,
  Typography,
  Input,
} from 'sputnik-ui'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteExtraKeyReader,
  fetchIntercomByUuid,
  updateExtraKeyReader,
} from 'store/devices/operations'

export const ExtraKeyReader = ({
  kind,
  alias,
  currnentDeviceUuid: deviceUuid,
  normally_open,
}) => {
  const { t } = useTranslation(['common', 'messages', 'devices'])

  const dispatch = useDispatch()
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [aliasInput, setAliasInput] = useState(null)

  const handleOpenDeleteModal = () => {
    setDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false)
  }

  const handleDelete = async () => {
    dispatch(deleteExtraKeyReader({ uuid: deviceUuid })).then(() => {
      dispatch(fetchIntercomByUuid(deviceUuid))
      handleCloseDeleteModal()
    })
  }

  const handleOpenEditModal = () => {
    setEditModal(true)
  }

  const handleCloseEditModal = () => {
    setEditModal(false)
  }

  const handleUpdateAlias = () => {
    dispatch(
      updateExtraKeyReader({ uuid: deviceUuid, alias: aliasInput })
    ).then(() => {
      dispatch(fetchIntercomByUuid(deviceUuid))
      handleCloseEditModal()
    })
  }
  const handleChange = (e) => {
    setAliasInput(e.target.value)
  }

  const currentReader = t(`devices:extraKeyReader.${kind}`)

  return (
    <Card
      style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FormModal open={editModal} onClose={handleCloseEditModal}>
        <FormModalTitle>
          {t(`devices:extraKeyReader.edit_text_comment`)}
        </FormModalTitle>
        <FormModalContent>
          <Input
            maxLength={20}
            onChange={handleChange}
            value={aliasInput}
            fullWidth
            label={t('devices:extraKeyReader.text_comment')}
          />
        </FormModalContent>
        <FormModalActions>
          <Button variant="v2" primary fullWidth onClick={handleUpdateAlias}>
            {t('common:button.save')}
          </Button>
          <Button variant="v2" tertiary onClick={handleCloseEditModal}>
            {t('common:button.back')}
          </Button>
        </FormModalActions>
      </FormModal>
      <ConfirmModal
        open={deleteModal}
        title={t('common:button.delete')}
        label={t('messages:action.undone_alternative')}
        onClose={handleCloseDeleteModal}
        actions={
          <>
            <Button variant="v2" primary onClick={handleDelete} fullWidth>
              {t('common:button.delete')}
            </Button>
            <Button variant="v2" tertiary onClick={handleCloseDeleteModal}>
              {t('common:button.back')}
            </Button>
          </>
        }
      />
      <Box display="flex" gridGap="1rem" flexDirection="column">
        <Typography>{currentReader}</Typography>
        {alias && <Typography color="textColor">{alias}</Typography>}
      </Box>
      <Box display="flex" gridGap="1rem">
        <Edit
          color={IconColors.grey}
          cursor="pointer"
          onClick={handleOpenEditModal}
        />
        <Trash
          color={IconColors.grey}
          cursor="pointer"
          onClick={handleOpenDeleteModal}
        />
      </Box>
    </Card>
  )
}
