import * as types from './types'

const initialState = {
  intercoms: null,
}

export default function maps(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_MAP_INTERCOMS_SUCCESS:
      return { ...state, intercoms: payload.intercoms }
    default:
      return state
  }
}
