import React from 'react'
import { Box, TableBody, TableContainer } from '@material-ui/core'
import {
  TableHead,
  TableRow,
  Table,
  TableCell,
  Search,
  Input,
  Typography,
  Checkbox,
  formatDeviceId,
  Button,
} from 'sputnik-ui'
import styled from 'styled-components'
import { copyToClipboard } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { showInfoMessage } from 'store/ui/actions'
import {
  PageMenu,
  PageMenuButton,
  PageMenuList,
  StatusDeviceIndicator,
} from 'shared/ui'
import { ReactComponent as CopyIcon } from 'assets/Copy.svg'

const StyledAboutUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media screen and (min-width: ${(p) => p.theme.sizes.screen}) {
    align-items: flex-end;
  }
`

export const AccessDeviceFlat = ({ ...props }) => {
  const dispatch = useDispatch()
  const handleCopyDeviceId = (uuid) => {
    copyToClipboard(uuid)
    dispatch(showInfoMessage('Номер устройства скопирован'))
  }

  return (
    <>
      <PageMenu>
        <div>
          <Typography
            variant="note"
            color="textSecondary"
            style={{ marginBottom: '5px' }}
          >
            Калитка
          </Typography>
          <Typography
            variant="title"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ marginRight: 8 }}>
              {formatDeviceId(String(2228345443))}
            </div>
            <StatusDeviceIndicator />
            <CopyIcon
              style={{ marginLeft: 14, cursor: 'pointer' }}
              onClick={() => handleCopyDeviceId('uuid')}
            />
          </Typography>
        </div>
        <PageMenuList>
          <PageMenuButton variant="v2" subtle>
            Забрать доступ
          </PageMenuButton>
          <PageMenuButton variant="v2" subtle>
            Дать доступ
          </PageMenuButton>
          <PageMenuButton
            variant="v2"
            primary
            onClick={() => console.log('kek')}
          >
            Сохранить и продолжить
          </PageMenuButton>
        </PageMenuList>
      </PageMenu>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: '34px',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
        {...props}
      >
        <Box>
          <Typography variant="headline" style={{ marginBottom: 6 }}>
            Выдача доступов квартирам
          </Typography>
          <Typography color="textSecondary">
            На данной странице отображаются только те квартиры, чьи жители
            зарегистрировались в <br />
            мобильном приложении. Вы можете выдать им доступ к камерам.
          </Typography>
        </Box>
        <StyledAboutUser>
          <Typography color="textSecondary">Адрес:</Typography>
          <Typography>Сочи пер. Павлова дом 16 п 2</Typography>
        </StyledAboutUser>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    // checked={selected?.includes(key.hex)}
                    // onClick={() => selectOne(key.hex)}
                    color="blue"
                    style={{
                      margin: '0px',
                      padding: '0px',
                      marginRight: 16,
                    }}
                  />
                  Квартира
                </TableCell>
                <TableCell>Заметка</TableCell>
                <TableCell>Долг / Блокировка</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {testData.map(({ flat, uuid, comment, access, dolg }) => (
                <TableRow key={uuid}>
                  <TableCell>
                    <Checkbox
                      // checked={selected?.includes(key.hex)}
                      // onClick={() => selectOne(key.hex)}
                      color="blue"
                      style={{
                        margin: '0px',
                        padding: '0px',
                        marginRight: 16,
                      }}
                    />
                    <Typography>{flat}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{comment}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ color: '#FF3D71' }}>{dolg}</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      display: 'flex', // fixme
                      cursor: 'pointer',
                      justifyContent: 'flex-end',
                      color: `${access ? '#FF3D71' : '#0EA7FF'}`, // fixme
                    }}
                  >
                    {access ? 'Забрать доступ' : 'Дать доступ'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

let testData = [
  {
    comment: 'Казань, козина 3а',
    flat: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: false,
    dolg: '323р',
  },
  {
    comment: 'Казань, козина 3а',
    flat: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: false,
    dolg: '323р',
  },
  {
    comment: 'Казань, козина 3а',
    flat: Math.floor(Math.random() * 10),
    uuid: Math.random(),
    access: false,
    dolg: '323р',
  },
]
