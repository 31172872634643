// @flow

export const GET_INTERCOMS_BY_IDENTIFIER_REQUEST =
  'devices/GET_INTERCOMS_BY_IDENTIFIER_REQUEST'
export const GET_INTERCOMS_BY_IDENTIFIER_SUCCESS =
  'devices/GET_INTERCOMS_BY_IDENTIFIER_SUCCESS'
export const GET_INTERCOMS_BY_IDENTIFIER_ERROR =
  'devices/GET_INTERCOMS_BY_IDENTIFIER_ERROR'

export const GET_INTERCOMS_BY_ADDRESS_REQUEST =
  'devices/GET_INTERCOMS_BY_ADDRESS_REQUEST'
export const GET_INTERCOMS_BY_ADDRESS_SUCCESS =
  'devices/GET_INTERCOMS_BY_ADDRESS_SUCCESS'
export const GET_INTERCOMS_BY_ADDRESS_ERROR =
  'devices/GET_INTERCOMS_BY_ADDRESS_ERROR'

export const GET_CAMERAS_BY_ADDRESS_REQUEST =
  'devices/GET_CAMERAS_BY_ADDRESS_REQUEST'
export const GET_CAMERAS_BY_ADDRESS_SUCCESS =
  'devices/GET_CAMERAS_BY_ADDRESS_SUCCESS'
export const GET_CAMERAS_BY_ADDRESS_ERROR =
  'devices/GET_CAMERAS_BY_ADDRESS_ERROR'

export const GET_UNLINKED_INTERCOMS_REQUEST =
  'devices/GET_UNLINKED_INTERCOMS_REQUEST'
export const GET_UNLINKED_INTERCOMS_SUCCESS =
  'devices/GET_UNLINKED_INTERCOMS_SUCCESS'
export const GET_UNLINKED_INTERCOMS_ERROR =
  'devices/GET_UNLINKED_INTERCOMS_ERROR'

export const GET_UNLINKED_CAMERAS_REQUEST =
  'devices/GET_UNLINKED_CAMERAS_REQUEST'
export const GET_UNLINKED_CAMERAS_SUCCESS =
  'devices/GET_UNLINKED_CAMERAS_SUCCESS'
export const GET_UNLINKED_CAMERAS_ERROR = 'devices/GET_UNLINKED_CAMERAS_ERROR'

export const LINK_DEVICE_REQUEST = 'devices/LINK_DEVICE_REQUEST'
export const LINK_DEVICE_SUCCESS = 'devices/LINK_DEVICE_SUCCESS'
export const LINK_DEVICE_ERROR = 'devices/LINK_DEVICE_ERROR'

export const UNLINK_DEVICE_REQUEST = 'devices/UNLINK_DEVICE_REQUEST'
export const UNLINK_DEVICE_SUCCESS = 'devices/UNLINK_DEVICE_SUCCESS'
export const UNLINK_DEVICE_ERROR = 'devices/UNLINK_DEVICE_ERROR'

export const GET_CAMERA_REQUEST = 'devices/GET_CAMERA_REQUEST'
export const GET_CAMERA_SUCCESS = 'devices/GET_CAMERA_SUCCESS'
export const GET_CAMERA_ERROR = 'devices/GET_CAMERA_ERROR'

export const SYNC_INTERCOM_REQUEST = 'devices/SYNC_INTERCOM_REQUEST'
export const SYNC_INTERCOM_SUCCESS = 'devices/SYNC_INTERCOM_SUCCESS'
export const SYNC_INTERCOM_ERROR = 'devices/SYNC_INTERCOM_ERROR'

export const SYNC_CAMERA_REQUEST = 'devices/SYNC_CAMERA_REQUEST'
export const SYNC_CAMERA_SUCCESS = 'devices/SYNC_CAMERA_SUCCESS'
export const SYNC_CAMERA_ERROR = 'devices/SYNC_CAMERA_ERROR'

export const FETCH_INTERCOM_BY_UUID_REQUEST =
  'devices/FETCH_INTERCOM_BY_UUID_REQUEST'
export const FETCH_INTERCOM_BY_UUID_SUCCESS =
  'devices/FETCH_INTERCOM_BY_UUID_SUCCESS'
export const FETCH_INTERCOM_BY_UUID_ERROR =
  'devices/FETCH_INTERCOM_BY_UUID_ERROR'

export const OPEN_DOOR_REQUEST = 'devices/OPEN_DOOR_REQUEST'
export const OPEN_DOOR_SUCCESS = 'devices/OPEN_DOOR_SUCCESS'
export const OPEN_DOOR_ERROR = 'devices/OPEN_DOOR_ERROR'

export const CLEAR_CURRENT_DEVICE = 'devices/CLEAR_CURRENT_DEVICE'

export const UPDATE_DEVICE_SETTINGS_REQUEST =
  'devices/UPDATE_DEVICE_SETTINGS_REQUEST'
export const UPDATE_DEVICE_SETTINGS_SUCCESS =
  'devices/UPDATE_DEVICE_SETTINGS_SUCCESS'
export const UPDATE_DEVICE_SETTINGS_FAILURE =
  'devices/UPDATE_DEVICE_SETTINGS_FAILURE'

export const UPDATE_INTERCOMS_LIST_STATUS =
  'devices/UPDATE_INTERCOMS_LIST_STATUS'

export const UPDATE_CURRENT_INTERCOM_STATUS =
  'devices/UPDATE_CURRENT_INTERCOM_STATUS'

export const UPDATE_CURRENT_INTERCOM_DOOR_STATUS =
  'devices/UPDATE_CURRENT_INTERCOM_DOOR_STATUS'

export const UPDATE_COMPANY_INTERCOMS_LIST_STATUS =
  'devices/UPDATE_COMPANY_INTERCOMS_LIST_STATUS'

export const FETCH_DEVICE_TYPE_REQUEST = 'devices/FETCH_DEVICE_TYPE_REQUEST'
export const FETCH_DEVICE_TYPE_SUCCESS = 'devices/FETCH_DEVICE_TYPE_SUCCESS'
export const FETCH_DEVICE_TYPE_FAILURE = 'devices/FETCH_DEVICE_TYPE_FAILURE'

export const FETCH_UNLINKED_DEVICES_REQUEST =
  'devices/FETCH_UNLINKED_DEVICES_REQUEST'
export const FETCH_UNLINKED_DEVICES_SUCCESS =
  'devices/FETCH_UNLINKED_DEVICES_SUCCESS'
export const FETCH_UNLINKED_DEVICES_FAILURE =
  'devices/FETCH_UNLINKED_DEVICES_FAILURE'

export const FETCH_SOUND_PRESETS_REQUEST = 'devices/FETCH_SOUND_PRESETS_REQUEST'
export const FETCH_SOUND_PRESETS_SUCCESS = 'devices/FETCH_SOUND_PRESETS_SUCCESS'
export const FETCH_SOUND_PRESETS_FAILURE = 'devices/FETCH_SOUND_PRESETS_FAILURE'

export const UPDATE_INTERCOM_SOUNDS_REQUEST =
  'devices/UPDATE_INTERCOM_SOUNDS_REQUEST'
export const UPDATE_INTERCOM_SOUNDS_SUCCESS =
  'devices/UPDATE_INTERCOM_SOUNDS_SUCCESS'
export const UPDATE_INTERCOM_SOUNDS_FAILURE =
  'devices/UPDATE_INTERCOM_SOUNDS_FAILURE'
