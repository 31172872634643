import Api from 'utils/api'
import config from 'config'
import * as actions from './actions'

export const checkAuth =
  (ignoreSessionStorage) => async (dispatch, getState) => {
    dispatch(actions.checkAuth())
    // if (getState().auth.isLoggedIn && !ignoreSessionStorage) return
    try {
      const user = await Api.request(
        `/users/me?app=${config.redirectUri}`,
        {},
        config.ssoUri
      )
      dispatch(actions.signIn(user))
    } catch (err) {
      const { redirect_to } = await err.json()
      if (redirect_to) {
        window.location = config.ssoUri + redirect_to
      }
      dispatch(actions.signInError())
    }
  }

export const logOut = () => async (dispatch) => {
  dispatch(actions.logOutRequest())
  try {
    const response = await Api.request(
      '/logout',
      { method: 'DELETE' },
      config.redirectUri
    )
    sessionStorage.clear()
    window.location.reload()
    dispatch(actions.logOutSuccess(response))
    return Promise.resolve()
  } catch (err) {
    dispatch(actions.logOutError(err))
    return Promise.reject(err)
  }
}
