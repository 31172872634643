// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - (100vw - 100%));
  height: 100%;
`

const Content = styled(Container)`
  display: flex;
  padding: 1.625rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  height: fit-content;

  & > div {
    width: 70%;
    margin-bottom: 1.625rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

const SingleChildTemplate = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

// SingleChildTemplate.propTypes = {
//   children: PropTypes.any.isRequired,
// }

export default SingleChildTemplate
