import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCamera, camerasActions } from 'features/Cameras/camerasSlice'
import {
  Container,
  FullscreenExitButton,
  Loader,
  Tooltip,
  Typography,
  formatDeviceId,
} from 'sputnik-ui'
import { useHistory, useParams } from 'react-router'
import { fetchIntercomByUuid, getCamera } from 'store/devices/operations'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@material-ui/core'
import { clearCurrentDevice } from 'store/devices/actions'
import { IconColors, Info } from 'sputnik-icons'
import {
  screenOrientations,
  useScreenOrientation,
} from 'hooks/useScreenOrientation'
import { Link } from 'react-router-dom'
import { linkToAchiveInstructions } from 'shared/const/externalLinks'
import styled from 'styled-components'

const BlueText = styled.span`
  color: ${(p) => p.theme.palette.blue.main};
`

export default function StreamPage() {
  const history = useHistory()
  const paramas = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation(['devices'])
  const [stream, setStream] = useState(undefined)
  const screenOrientation = useScreenOrientation()
  const currentCamera = useSelector((state) => state.camerasNew.current)
  const device = useSelector((state) => state.devices?.intercom)
  const deviceStream = useSelector((state) => state.devices?.camera)

  const [isLoading, setLoading] = useState(false)
  const { device: deviceUuid, gate: gateUuid, camera: cameraUuid } = paramas

  const fetchCurrentDeviceStream = () => {
    if (cameraUuid) {
      dispatch(fetchCamera(cameraUuid))
    }
    if (deviceUuid || gateUuid) {
      dispatch(fetchIntercomByUuid(deviceUuid || gateUuid))
      dispatch(getCamera(deviceUuid || gateUuid))
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchCurrentDeviceStream()
    return () => {
      dispatch(camerasActions.clearCamerasStore())
      return () => dispatch(clearCurrentDevice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((deviceUuid || gateUuid) && deviceStream) {
      setLoading(false)
      setStream(deviceStream)
    }
    if (cameraUuid && currentCamera) {
      setLoading(false)
      setStream(currentCamera)
    }
  }, [deviceStream, currentCamera, deviceUuid, cameraUuid, gateUuid])

  const handleGoBack = () => {
    if (history.length) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  const text = deviceUuid
    ? t('devices:video_archive.archive_intercom')
    : gateUuid
    ? t('devices:video_archive.archive_gate')
    : cameraUuid
    ? t('devices:video_archive.archive_camera')
    : ''

  return (
    <Dialog
      transitionDuration={0}
      fullScreen
      open
      PaperProps={{
        style: { padding: 20 },
      }}
    >
      <header>
        <FullscreenExitButton variant="back" onClick={handleGoBack} />
      </header>
      <Container>
        {screenOrientation !== screenOrientations.landscapeSecondary && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '2rem auto',
              gap: 12,
            }}
          >
            <Typography variant="title" style={{ textAlign: 'center' }}>
              {text}{' '}
              <span style={{ whiteSpace: 'nowrap' }}>
                #
                {formatDeviceId(
                  currentCamera?.serial_number || device?.serial_number
                )}
              </span>
            </Typography>
            <Tooltip
              interactive
              placement="bottom-start"
              title={
                <>
                  <Typography color="textSecondary">
                    {t('devices:video_archive.watch_and_download_page')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{ marginTop: '1rem', cursor: 'pointer' }}
                  >
                    <Link
                      target="_blank"
                      to={{ pathname: linkToAchiveInstructions }}
                      style={{ textDecoration: 'none' }}
                    >
                      <BlueText>{t('devices:usage_instruction')}</BlueText>
                    </Link>
                  </Typography>
                </>
              }
            >
              <div>
                <Info color={IconColors.grey} style={{ cursor: 'pointer' }} />
              </div>
            </Tooltip>
          </div>
        )}

        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ aspectRatio: '16 / 9' }}>
            {stream ? (
              stream.embed ? (
                <iframe
                  style={{ height: '100%', width: '100%', border: 0 }}
                  title="camera-stream"
                  src={`${stream.embed}&dvr=true`}
                  autoPlay={false}
                />
              ) : (
                <Typography color="error" align="center">
                  {t('devices:cameras.not_available')}
                </Typography>
              )
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '100px 0',
                }}
              >
                Error
              </div>
            )}
          </div>
        )}
      </Container>
    </Dialog>
  )
}
