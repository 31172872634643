// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { SingleChildTemplate, Streets } from 'components'
import { clearResources } from 'store/actions'
import { fetchLocality, getStreets } from 'store/resources/operations'

function LocalityPage({
  dispatch,
  match: {
    params: { locality },
  },
}) {
  React.useEffect(() => {
    if (locality) {
      dispatch(fetchLocality(locality))
      dispatch(getStreets({ parentUuid: locality }))
    }
    return () => dispatch(clearResources())
  }, [dispatch, locality])
  return (
    <SingleChildTemplate>
      <Streets />
    </SingleChildTemplate>
  )
}

export default connect()(withRouter(LocalityPage))
