import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input, Radio, TextArea, Button, Typography } from 'sputnik-ui'
import { FormControlLabel, RadioGroup } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDeviceTypeByNumber, isDeviceUuidValid } from 'utils/helpers'
import { showInfoMessage } from 'store/ui/actions'
import Toggle from './WarrantyToggle'
import useWarrantyInputsData from './useWarrantyInputsData'
import {
  checkWarrantyAvailable,
  createWarranty,
  fetchWarranty,
} from './warrantySlice'
import { OutOfWarrantyModal } from './OutOfWarrantyModal'

const StyledErrorTypography = styled(Typography)`
  color: ${(p) => p.theme.palette.danger.main} !important;
`

const SectionWrapper = styled.div`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #ededed;
  border-radius: 20px;
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 15px;
  white-space: nowrap;
  &:first-letter {
    text-transform: uppercase;
  }
`

const SectionInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`

const SectionCheck = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 6fr);
  grid-gap: 2rem;
  @media screen and (max-width: ${(p) => p.theme.sizes.screen}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const INTERCOM_OTHER = 'intercom_other'
const CAMERA_OTHER = 'camera_other'

const validationSchema = Yup.object().shape({
  device_number: Yup.string()
    .required()
    .test('len', 'serial required', (val) => {
      const len = val?.toString().trim().length
      return len === 16 || len === 10
    }),
  partner_name: Yup.string().trim().required(),
  full_name: Yup.string().trim().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().phone().required(),
})

const StyledTypography = styled(Typography)`
  && {
    font-family: ${(p) => p.theme.fonts.openSans};
    font-size: 16px;
    font-weight: 17.6px;
  }
`

const variables = {
  intercom: 'intercom',
  camera: 'camera',
}

function WarrantyForm({ closeModal, ...props }) {
  const { t } = useTranslation(['warranty', 'devices'])
  const [toggle, setToggle] = useState(variables.intercom)

  const { deviceTroubles, inputsData } = useWarrantyInputsData()
  const [isWarrantyModal, setIsWarrantyModal] = useState(false)
  const user = useSelector(({ auth }) => auth.user)
  const buttonPending = useSelector(({ warranty }) => warranty.createPending)
  const dispatch = useDispatch()

  const initialValues = {
    device_number: '',
    partner_name: user?.company.name || '',
    full_name: user?.full_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    sound: 'not_recognized',
    lock_v2: 'not_recognized',
    call: 'not_recognized',
    intercom_general_v2: 'not_recognized',
    intercom_camera_v2: 'not_recognized',
    camera: 'not_recognized',
    net: 'not_recognized',
    intercom_other: undefined,
    camera_other: undefined,
  }

  function isNotRecognized([, value]) {
    return value === 'not_recognized'
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        sound,
        lock_v2,
        call,
        intercom_general_v2,
        intercom_camera_v2,
        camera,
        email,
        phone,
        device_number,
        partner_name,
        full_name,
        net,
        intercom_other,
        camera_other,
      } = values

      const intercomTroubles = {
        sound,
        lock_v2,
        call,
        intercom_general_v2,
        intercom_camera_v2,
        net,
        intercom_other,
      }
      const cameraTroubles = { camera, camera_other }

      const nextTroubles =
        toggle === variables.intercom ? intercomTroubles : cameraTroubles

      try {
        await handleValidateArea(toggle, nextTroubles)
      } catch (error) {
        dispatch(showInfoMessage(error.message))
        throw new Error(error)
      }

      dispatch(
        createWarranty({
          values: {
            device_kind: toggle,
            email,
            phone,
            device_number,
            partner_name,
            full_name,
          },
          troubles: {
            ...nextTroubles,
          },
        })
      )
        .then(unwrapResult)
        .then(closeModal)
        .then(() => dispatch(fetchWarranty()))
    },
  })

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    touched,
    handleBlur,
    setValues,
    resetForm,
  } = formik

  const customHandleBlur = (e) => {
    if (
      e.target.name === 'device_number' &&
      isDeviceUuidValid(e.target.value)
    ) {
      dispatch(checkWarrantyAvailable({ hex: e.target.value }))
        .then(unwrapResult)
        .then((isWarrantyAvailable) => {
          setIsWarrantyModal(!isWarrantyAvailable)
        })
    }
    handleBlur(e)
  }

  const radioToggleHandler = (radioVal, id) => {
    const val = radioVal.target.value
    if (val === 'true' || val === 'false') {
      const value = val === 'true'
      setFieldValue(id, !!value)
    } else {
      setFieldValue(id, val)
    }
  }

  const handleToggle = () => {
    setToggle(
      toggle === variables.intercom ? variables.camera : variables.intercom
    )
    setValues({
      ...initialValues,
      device_number: values.device_number,
      email: values.email,
      phone: values.phone,
      partner_name: values.partner_name,
      full_name: values.full_name,
    })
  }

  const closeOutWarrantyModal = () => {
    setIsWarrantyModal(false)
  }

  const continueNextOutWarrantyModal = () => {
    setIsWarrantyModal(false)
    resetForm()
  }

  const disabledSubmit =
    Object.keys(errors).length > 0 ||
    !Object.keys(touched).length > 0 ||
    buttonPending ||
    getDeviceTypeByNumber(values.device_number) !== toggle

  const handleValidateArea = useCallback(
    async (deviceType, values) => {
      if (deviceType === variables.intercom) {
        if (values[INTERCOM_OTHER]?.trim().length > 0) {
          return Promise.resolve()
        }

        const withoutOther = { ...values }
        delete withoutOther[INTERCOM_OTHER]

        const isEveryNotRecognized = Object.entries(withoutOther)
          .filter(([key]) => key !== INTERCOM_OTHER)
          .every(isNotRecognized)

        if (!isEveryNotRecognized) {
          return Promise.resolve()
        }
        if (isEveryNotRecognized && !values[INTERCOM_OTHER]) {
          setFieldError(INTERCOM_OTHER, true)
          return Promise.reject(
            new Error(t('warranty:form.validation_message'))
          )
        }

        if (isEveryNotRecognized && values[INTERCOM_OTHER]?.trim().length < 1) {
          setFieldError(INTERCOM_OTHER, true)
          return Promise.reject(
            new Error(t('warranty:form.validation_message'))
          )
        }
        return Promise.reject(new Error('Error'))
      }
      if (deviceType === variables.camera) {
        if (values[CAMERA_OTHER]?.trim().length > 0) {
          return Promise.resolve()
        }

        const withoutOther = { ...values }
        delete withoutOther[CAMERA_OTHER]

        const isEveryNotRecognized = Object.entries(withoutOther)
          .filter(([key]) => key !== CAMERA_OTHER)
          .every(isNotRecognized)

        if (!isEveryNotRecognized) {
          return Promise.resolve()
        }
        if (isEveryNotRecognized && !values[CAMERA_OTHER]) {
          setFieldError(CAMERA_OTHER, true)
          return Promise.reject(
            new Error(t('warranty:form.validation_message'))
          )
        }

        if (isEveryNotRecognized && values[CAMERA_OTHER]?.trim().length < 1) {
          setFieldError(CAMERA_OTHER, true)
          return Promise.reject(
            new Error(t('warranty:form.validation_message'))
          )
        }
        return Promise.reject(new Error('Error'))
      }
      return Promise.reject(new Error('Error'))
    },
    [setFieldError, t]
  )

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <OutOfWarrantyModal
        open={isWarrantyModal}
        onClose={closeOutWarrantyModal}
        continueNext={continueNextOutWarrantyModal}
      />
      <SectionWrapper>
        <SectionTitle>{t('warranty:title.contact_info')}</SectionTitle>
        <SectionInput>
          {inputsData.map(({ fields, localId }) => (
            <Column key={localId}>
              {fields.map((input) => (
                <Input
                  key={input.name}
                  style={{ marginBottom: 12 }}
                  fullWidth
                  label={input?.label}
                  type={input?.type}
                  name={input?.name}
                  error={errors[input?.name] && touched[input?.name]}
                  onBlur={customHandleBlur}
                  required={input?.required}
                  onChange={handleChange}
                  value={values[input?.name]}
                  autoComplete="on"
                />
              ))}
            </Column>
          ))}
        </SectionInput>
      </SectionWrapper>
      <SectionTitle> {t('warranty:title.info')}</SectionTitle>
      <Toggle
        style={{ maxWidth: 320, marginBottom: '1.5rem' }}
        label1={t('devices:units.intercom')}
        label2={t('warranty:form.videcam')}
        checked={toggle === variables.camera}
        onChange={handleToggle}
      />
      {toggle === variables.intercom ? (
        <SectionWrapper>
          <SectionCheck>
            {deviceTroubles.intercom.map(({ label, localId, options }) => (
              <Column key={localId}>
                <SectionTitle>{label}</SectionTitle>

                <RadioGroup
                  style={{ gap: '0.5rem' }}
                  onChange={(e) => radioToggleHandler(e, localId)}
                  value={values[localId]}
                >
                  {options.map((option) => (
                    <FormControlLabel
                      style={{
                        alignItems: 'flex-start',
                        gap: '0.5rem',
                        marginLeft: 0,
                      }}
                      key={option.label}
                      label={
                        <StyledTypography>{option.label}</StyledTypography>
                      }
                      value={option.errNum}
                      control={<Radio color="blue" style={{ padding: 0 }} />}
                    />
                  ))}
                </RadioGroup>
              </Column>
            ))}
          </SectionCheck>
        </SectionWrapper>
      ) : (
        <SectionWrapper>
          {deviceTroubles.camera.map(({ localId, label, options }) => (
            <Column key={localId}>
              <SectionTitle>{label}</SectionTitle>
              <RadioGroup
                style={{ gap: '0.5rem' }}
                onChange={(e) => radioToggleHandler(e, localId)}
                value={values[localId]}
              >
                {options.map((option) => (
                  <FormControlLabel
                    style={{
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                      marginLeft: 0,
                    }}
                    key={option.label}
                    label={<StyledTypography>{option.label}</StyledTypography>}
                    value={option.errNum}
                    control={<Radio color="blue" style={{ padding: 0 }} />}
                  />
                ))}
              </RadioGroup>
            </Column>
          ))}
        </SectionWrapper>
      )}

      {toggle === variables.intercom && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <SectionTitle>{t('warranty:form.other')}</SectionTitle>
          <TextArea
            placeholder={t('warranty:form.comment_placeholder')}
            name={INTERCOM_OTHER}
            value={values[INTERCOM_OTHER]}
            error={errors[INTERCOM_OTHER]}
            onChange={handleChange}
            rows="4"
          />
          {errors[INTERCOM_OTHER] && (
            <StyledErrorTypography variant="note">
              {t('warranty:form.other_helper_text')}
            </StyledErrorTypography>
          )}
        </div>
      )}

      {toggle === variables.camera && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <SectionTitle>{t('warranty:form.other')}</SectionTitle>
          <TextArea
            placeholder={t('warranty:form.comment_placeholder')}
            name={CAMERA_OTHER}
            value={values[CAMERA_OTHER]}
            error={errors[CAMERA_OTHER]}
            onChange={handleChange}
            rows="4"
          />
          {errors[CAMERA_OTHER] && (
            <StyledErrorTypography variant="note">
              {t('warranty:form.other_helper_text')}
            </StyledErrorTypography>
          )}
        </div>
      )}
      <div>
        <Button
          type="submit"
          primary
          variant="v2"
          disabled={disabledSubmit}
          style={{ marginTop: '1.5rem' }}
        >
          {t('warranty:action.register')} {buttonPending && '...'}
        </Button>
      </div>
    </StyledForm>
  )
}

export default WarrantyForm
