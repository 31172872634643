// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(p) => p.theme.palette.background.primary};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

const Content = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: auto;
`

const MainTemplate = ({ header, children, subheader, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

export default MainTemplate
