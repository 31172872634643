// @flow
import * as types from './types'

export const getIntercomsByIdentifierRequest = (identifier, limit, page) => ({
  type: types.GET_INTERCOMS_BY_IDENTIFIER_REQUEST,
  payload: { identifier, limit, page },
})
export const getIntercomsByIdentifierSuccess = (intercoms, firstPage) => ({
  type: types.GET_INTERCOMS_BY_IDENTIFIER_SUCCESS,
  payload: { intercoms, firstPage },
})
export const getIntercomsByIdentifierError = (error) => ({
  type: types.GET_INTERCOMS_BY_IDENTIFIER_ERROR,
  payload: error,
})

export const getIntercomsByAddressRequest = () => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_REQUEST,
})
export const getIntercomsByAddressSuccess = (intercoms) => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_SUCCESS,
  payload: { intercoms },
})
export const getIntercomsByAddressError = (error) => ({
  type: types.GET_INTERCOMS_BY_ADDRESS_ERROR,
  payload: error,
})

export const getCamerasByAddressRequest = () => ({
  type: types.GET_CAMERAS_BY_ADDRESS_REQUEST,
})
export const getCamerasByAddressSuccess = (cameras) => ({
  type: types.GET_CAMERAS_BY_ADDRESS_SUCCESS,
  payload: { cameras },
})
export const getCamerasByAddressError = (error) => ({
  type: types.GET_CAMERAS_BY_ADDRESS_ERROR,
  payload: error,
})

export const getUnlinkedIntercomsRequest = () => ({
  type: types.GET_UNLINKED_INTERCOMS_REQUEST,
})
export const getUnlinkedIntercomsSuccess = (intercoms) => ({
  type: types.GET_UNLINKED_INTERCOMS_SUCCESS,
  payload: { intercoms },
})
export const getUnlinkedIntercomsError = (error) => ({
  type: types.GET_UNLINKED_INTERCOMS_ERROR,
  payload: error,
})

export const getUnlinkedCamerasRequest = () => ({
  type: types.GET_UNLINKED_CAMERAS_REQUEST,
})
export const getUnlinkedCamerasSuccess = (cameras) => ({
  type: types.GET_UNLINKED_CAMERAS_SUCCESS,
  payload: { cameras },
})
export const getUnlinkedCamerasError = (error) => ({
  type: types.GET_UNLINKED_CAMERAS_ERROR,
  payload: error,
})

export const linkDeviceRequest = () => ({
  type: types.LINK_DEVICE_REQUEST,
})
export const linkDeviceSuccess = (msg) => ({
  type: types.LINK_DEVICE_SUCCESS,
  payload: msg,
})
export const linkDeviceError = (error) => ({
  type: types.LINK_DEVICE_ERROR,
  payload: error,
})

export const unlinkDeviceRequest = () => ({
  type: types.UNLINK_DEVICE_REQUEST,
})
export const unlinkDeviceSuccess = (msg) => ({
  type: types.UNLINK_DEVICE_SUCCESS,
  payload: msg,
})
export const unlinkDeviceError = (error) => ({
  type: types.UNLINK_DEVICE_ERROR,
  payload: error,
})

export const getCameraRequest = () => ({
  type: types.GET_CAMERA_REQUEST,
})
export const getCameraSuccess = (camera) => ({
  type: types.GET_CAMERA_SUCCESS,
  payload: camera,
})
export const getCameraError = (error) => ({
  type: types.GET_CAMERA_ERROR,
  payload: error,
})

export const syncIntercomRequest = () => ({
  type: types.SYNC_INTERCOM_REQUEST,
})
export const syncIntercomSuccess = (msg) => ({
  type: types.SYNC_INTERCOM_SUCCESS,
  payload: msg,
})
export const syncIntercomError = (error) => ({
  type: types.SYNC_INTERCOM_ERROR,
  payload: error,
})

export const syncCameraRequest = () => ({
  type: types.SYNC_CAMERA_REQUEST,
})
export const syncCameraSuccess = (msg) => ({
  type: types.SYNC_CAMERA_SUCCESS,
  payload: {
    msg,
  },
})
export const syncCameraError = (error) => ({
  type: types.SYNC_CAMERA_ERROR,
  payload: {
    error,
  },
})

export const fetchIntercomByUuidRequest = () => ({
  type: types.FETCH_INTERCOM_BY_UUID_REQUEST,
})
export const fetchIntercomByUuidSuccess = (intercom) => ({
  type: types.FETCH_INTERCOM_BY_UUID_SUCCESS,
  payload: {
    intercom,
  },
})
export const fetchIntercomByUuidError = (error) => ({
  type: types.FETCH_INTERCOM_BY_UUID_ERROR,
  payload: {
    error,
  },
})

export const openDoorRequest = () => ({
  type: types.OPEN_DOOR_REQUEST,
})
export const openDoorSuccess = (msg) => ({
  type: types.OPEN_DOOR_SUCCESS,
  payload: {
    msg,
  },
})
export const openDoorError = (error) => ({
  type: types.OPEN_DOOR_ERROR,
  payload: {
    error,
  },
})

export const updateDeviceSettingsRequest = () => ({
  type: types.UPDATE_DEVICE_SETTINGS_REQUEST,
})
export const updateDeviceSettingsSuccess = (msg) => ({
  type: types.UPDATE_DEVICE_SETTINGS_SUCCESS,
  payload: {
    msg,
  },
})
export const updateDeviceSettingsError = (error) => ({
  type: types.UPDATE_DEVICE_SETTINGS_FAILURE,
  payload: {
    error,
  },
})

export const updateIntercomsListStatus = ({ uuid, is_online }) => ({
  type: types.UPDATE_INTERCOMS_LIST_STATUS,
  payload: {
    uuid,
    is_online,
  },
})

export const updateCurrentIntercomStatus = (is_online) => ({
  type: types.UPDATE_CURRENT_INTERCOM_STATUS,
  payload: {
    is_online,
  },
})

export const updateCurrentIntercomDoorStatus = (door_opened) => ({
  type: types.UPDATE_CURRENT_INTERCOM_DOOR_STATUS,
  payload: {
    door_opened,
  },
})

export const clearCurrentDevice = () => ({
  type: types.CLEAR_CURRENT_DEVICE,
})

export const fetchDeviceTypeRequest = () => ({
  type: types.FETCH_DEVICE_TYPE_REQUEST,
})
export const fetchDeviceTypeSuccess = (deviceType) => ({
  type: types.FETCH_DEVICE_TYPE_SUCCESS,
  payload: {
    deviceType,
  },
})
export const fetchDeviceTypeError = (error) => ({
  type: types.FETCH_DEVICE_TYPE_FAILURE,
  payload: {
    error,
  },
})

export const fetchUnlinkedDevicesRequest = () => ({
  type: types.FETCH_UNLINKED_DEVICES_REQUEST,
})
export const fetchUnlinkedDevicesSuccess = (unlinkedDevices) => ({
  type: types.FETCH_UNLINKED_DEVICES_SUCCESS,
  payload: {
    unlinkedDevices,
  },
})
export const fetchUnlinkedDevicesError = (error) => ({
  type: types.FETCH_UNLINKED_DEVICES_FAILURE,
  payload: {
    error,
  },
})

export const fetchSoundPresetsRequest = () => ({
  type: types.FETCH_SOUND_PRESETS_REQUEST,
})
export const fetchSoundPresetsSuccess = (presets) => ({
  type: types.FETCH_SOUND_PRESETS_SUCCESS,
  payload: {
    presets,
  },
})
export const fetchSoundPresetsFailure = (error) => ({
  type: types.FETCH_SOUND_PRESETS_FAILURE,
  payload: {
    error,
  },
})

export const updateCompanyIntercomsList = (intercom) => ({
  type: types.UPDATE_COMPANY_INTERCOMS_LIST_STATUS,
  payload: {
    intercom,
  },
})

export const updateIntercomSoundsRequest = () => ({
  type: types.UPDATE_INTERCOM_SOUNDS_REQUEST,
})
export const updateIntercomSoundsSuccess = (msg) => ({
  type: types.UPDATE_INTERCOM_SOUNDS_SUCCESS,
  payload: {
    msg,
  },
})
export const updateIntercomSoundsFailure = (error) => ({
  type: types.UPDATE_INTERCOM_SOUNDS_FAILURE,
  payload: {
    error,
  },
})
