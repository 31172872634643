import React, { useCallback, useState, useEffect } from 'react'
import merge from 'lodash/merge'
import styled, { useTheme } from 'styled-components'
import { Typography, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet'
import {
  Map as LeafletMap,
  Marker,
  TileLayer,
  Tooltip as LeafletTooltip,
  Popup,
} from 'react-leaflet'
import { leafletLocale, Button, formatDeviceId } from 'sputnik-ui'

import SputnikIcon from 'assets/Sputnik.svg'
import GateIcon from 'assets/gate.svg'
import GateYellowIcon from 'assets/gate_yellow.svg'
import ISee from 'assets/ISee.svg'
import { Restore } from '@material-ui/icons'

require('react-leaflet-markercluster/dist/styles.min.css')

L.drawLocal = merge(L.drawLocal, leafletLocale)

const sputnik = L.icon({
  iconUrl: SputnikIcon,
  iconSize: [32, 32],
  iconAnchor: [12, 0],
})

const gate = L.icon({
  iconUrl: GateIcon,
  iconSize: [24, 24],
  iconAnchor: [12, 0],
})

const gateYellow = L.icon({
  iconUrl: GateYellowIcon,
  iconSize: [24, 24],
  iconAnchor: [12, 0],
})

const cameraIcon = L.icon({
  iconUrl: ISee,
  iconSize: [32, 32],
  iconAnchor: [12, 0],
})

const StyledPopup = styled(Popup)`
  .leaflet-popup-content-wrapper {
    box-shadow: ${(p) => p.theme.shadows.main};
    border-radius: ${(p) => p.theme.sizes.borderRadius};
  }

  .leaflet-popup-content {
    margin: 0;
    padding: 0.625rem;

    & p {
      margin: 0;
    }
  }
`

const StyledMap = styled(LeafletMap)`
  width: 100%;
  height: 100%;
  z-index: 0;

  .leaflet-tile-pane {
    filter: grayscale(100%);
  }

  .leaflet-bar {
    box-shadow: ${(p) => p.theme.shadows.main};
    border-radius: 15px;
    overflow: hidden;
    border: none;

    a {
      border-bottom-color: ${(p) => p.theme.palette.other.border};
      color: ${(p) => p.theme.palette.text.primary};
    }
  }

  .marker-cluster {
    background: ${(p) => p.theme.palette.other.green};
    color: ${(p) => p.theme.palette.other.greenDark};
    /* background: ${(p) => p.theme.palette.other.pink};
    color: ${(p) => p.theme.palette.other.pinkDark}; */

    & div {
      background: transparent;
      font-family: ${(p) => p.theme.fonts.primary};
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`

const Tooltip = styled(LeafletTooltip)`
  background: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.contrastText};
  border: none;
  border-radius: ${(p) => p.theme.sizes.borderRadius};
  font-family: ${(p) => p.theme.fonts.primary};

  &::before {
    border-top-color: ${(p) => p.theme.palette.primary.main};
  }
`

const getIcon = (address_type, device_type, isUpdated) => {
  if (isUpdated) return gateYellow
  if (device_type === 'camera') return cameraIcon
  switch (address_type) {
    case 'gate':
      return gate
    default:
      return sputnik
  }
}

const MAX_DISTANCE = 500

const Map = ({
  initialMarkers = [],
  withConfirm = false,
  children,
  onUpdate,
  center,
  ...props
}) => {
  const [markers, setMarkers] = useState(initialMarkers)
  const [error, setError] = useState('')
  const [updatedUuids, setUpdatedUuids] = useState([])

  useEffect(() => {
    setMarkers(initialMarkers)
  }, [initialMarkers])

  const { t } = useTranslation(['common', 'devices'])

  const handleReset = useCallback(() => {
    setMarkers(initialMarkers)
    setUpdatedUuids([])
  }, [initialMarkers])

  // useEffect(() => {
  //   if (withConfirm) {
  //     handleUpdateMarkers()
  //   }
  // }, [handleUpdateMarkers, updatedUuids,withConfirm])

  const onDragEnd = useCallback(
    (e) => {
      // const latLng = event.target.getLatLng() //get updated marker LatLng
      // const markerIndex = event.target.options.markerIndex //get marker index
      // console.log(latLng, markerIndex)

      const {
        target: {
          options: { markerIndex },
        },
      } = e
      let originalMarker = initialMarkers.find(
        (m, idx) => m.uuid === markerIndex
      )
      originalMarker = { ...originalMarker, lng: originalMarker.lon }
      const newPos = e.target.getLatLng()
      const distanceToOriginal = newPos.distanceTo(originalMarker)

      if (distanceToOriginal > MAX_DISTANCE) {
        setError(t('devices:map.long_away'))
        setMarkers(markers)
        setTimeout(() => {
          setError('')
        }, 1500)
      } else {
        setMarkers(
          markers.map((m) =>
            m.uuid === markerIndex
              ? { ...m, lat: newPos.lat, lon: newPos.lng, lng: newPos.lng }
              : m
          )
        )
        setUpdatedUuids([...new Set([...updatedUuids, markerIndex])])
        if (!withConfirm) {
          onUpdate(markers)
        }
      }
    },
    [initialMarkers, markers, onUpdate, updatedUuids, withConfirm, t]
  )

  const {
    palette: {
      other: { greenDark: accentClusterColor },
    },
  } = useTheme()

  return markers?.length > 0 && markers[0]?.lat ? (
    <>
      <StyledMap
        center={
          center?.lat ? center : { lat: markers[0]?.lat, lon: markers[0]?.lon }
        }
        zoom={17}
        // zoomControl={f
        scrollWheelZoom={false}
        touchZoom={false}
        // dragging={false}
        {...props}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {/* <MarkerClusterGroup
          maxClusterRadius={100}
          spiderLegPolylineOptions={{ color: accentClusterColor }}
          polygonOptions={{
            color: accentClusterColor,
          }}
        > */}
        {markers.map(
          ({
            lat,
            lon,
            address_type,
            address_uuid,
            serial_number,
            uuid,
            short_address,
            device_type,
          }) => {
            const isUpdated = updatedUuids.some((i) => i === uuid)
            return (
              <Marker
                key={uuid}
                draggable={address_type === 'gate'}
                onDragEnd={onDragEnd}
                position={{ lat, lon }}
                icon={getIcon(address_type, device_type, isUpdated)}
                // TODO
                markerIndex={uuid}
                zIndexOffset={address_type === 'gate' ? 2 : 1}
              >
                <StyledPopup minWidth={90}>
                  <Typography
                    variant="body1"
                    style={{ marginBottom: '.325rem' }}
                  >
                    {device_type === 'camera'
                      ? t('devices:units.camera')
                      : address_type === 'gate'
                      ? t('devices:units.gate')
                      : t('devices:units.intercom')}{' '}
                    {device_type === 'intercom'
                      ? formatDeviceId(serial_number)
                      : serial_number}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {' '}
                    {short_address}
                  </Typography>
                  {isUpdated && withConfirm && (
                    <Button
                      primary
                      style={{ marginTop: '.625rem', width: '100%' }}
                      onClick={() =>
                        onUpdate({ uuid: address_uuid, lat, lon }).then(() =>
                          setUpdatedUuids([])
                        )
                      }
                      size="small"
                    >
                      {t('devices:any.save_geo')}
                    </Button>
                  )}
                </StyledPopup>
                <Tooltip direction="top">
                  {device_type === 'camera' ? (
                    `${t('devices:units.camera')} ${serial_number}`
                  ) : address_type === 'gate' ? (
                    `${t('devices:map.drag_gate')}${
                      withConfirm ? ` ${t('devices:map.move_save')}` : ''
                    }`
                  ) : (
                    <span>
                      {t('devices:units.intercom')}{' '}
                      {formatDeviceId(serial_number)}
                    </span>
                  )}
                </Tooltip>
              </Marker>
            )
          }
        )}
        {/* </MarkerClusterGroup> */}
      </StyledMap>
      {error && (
        <Typography
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            transform: 'translate(0,-50%)',
            zIndex: 1000,
          }}
          align="center"
          variant="h6"
          color="error"
        >
          {error}
        </Typography>
      )}
      {updatedUuids?.length > 0 && withConfirm && (
        <div
          style={{
            position: 'absolute',
            bottom: '4rem',
            left: '1rem',
            display: 'flex',
          }}
        >
          <IconButton
            secondary
            style={{ marginRight: '.325rem' }}
            onClick={handleReset}
            color="primary"
          >
            <Restore />
          </IconButton>
        </div>
      )}
    </>
  ) : null
}

export default Map
