import { RECEIVE_NEW_ORDER } from 'store/orders/types'
import * as types from './types'

const initialState = {
  msg: null,
  info: null,
  error: null,
  updateAction: null,
  newOrder: null,
}

export default function ui(state = initialState, { type, payload }) {
  switch (type) {
    case types.SHOW_INFO_MESSAGE:
      return { ...state, info: payload.msg }
    case RECEIVE_NEW_ORDER:
      return { ...state, newOrder: payload.order.id }
    case types.SHOW_ERROR_MESSAGE:
      return { ...state, error: payload.msg }
    case types.CLEAR_STATUS:
      return {
        msg: null,
        info: null,
        error: null,
        updateAction: null,
        newOrder: null,
      }
    case types.SHOW_UPDATE_MESSAGE:
      return { ...state, updateAction: payload.updateAction }
    default:
      return state
  }
}
