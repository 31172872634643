import { createSlice } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query'
import { fetchCitizensList } from '../services/fetchCitizensList'
import { tables } from '../const/const'

const initialState = {
  activeTable: tables.all,
  citizens: undefined,
  meta: undefined,
  pending: false,
  selected: [],
}

const citizensProSlice = createSlice({
  name: 'citizensPro',
  initialState,
  reducers: {
    clearCitizenPro: () => initialState,
    changeTable: (state, { payload }) => {
      state.activeTable = payload
    },
    selectCitizens: (state, { payload }) => {
      state.selected = payload
    },
    clearSelectedCitizens: (state) => {
      state.selected = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCitizensList.pending, (state, { meta }) => {
      state.pending = !meta.arg.update
    })
    builder.addCase(fetchCitizensList.fulfilled, (state, { payload }) => {
      state.citizens = payload.body.data
      state.meta = payload.body.metadata.meta
      state.pending = false
    })
    builder.addCase(fetchCitizensList.rejected, (state, { meta }) => {
      state.pending = meta.aborted
    })
  },
})

export const { actions: citizensProActions } = citizensProSlice
export const { reducer: citizensProReducer } = citizensProSlice
