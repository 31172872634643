import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Typography, Dialog, IconButton, Tooltip } from '@material-ui/core'
import { Archive, SubdirectoryArrowRight, Check } from '@material-ui/icons'
import {
  FullscreenExitButton,
  FullscreenDialogTitle,
  Button,
  Mascotte,
  Loader,
} from 'sputnik-ui'
import OrderTemplate from 'components/templates/OrderTemplate'
import { formatDate } from 'utils/helpers'
import OrderHeader from 'components/atoms/OrderHeader'
import OrderBody from 'components/atoms/OrderBody'
import { useSelector, useDispatch } from 'react-redux'

import { fetchOrder, completeOrder } from 'store/orders/operations'
import { clearCurrentOrder, updateCurrentOrder } from 'store/orders/actions'
import useOrderStatus from 'hooks/useOrderStatus'
import { useTranslation } from 'react-i18next'

function OrderPage({ match, history }) {
  const dispatch = useDispatch()
  const { t } = useTranslation('orders')
  useEffect(() => {
    dispatch(fetchOrder(match.params.order))
    return () => dispatch(clearCurrentOrder())
  }, [dispatch, match.params.order])
  const order = useSelector(({ orders }) => orders.current)
  const { archivingOrder, notFound } = useSelector(({ orders }) => ({
    archivingOrder: orders.archivingOrder,
    notFound: orders.notFound,
  }))

  const handleClose = React.useCallback(() => {
    history.length > 0 ? history.goBack() : history.push('/orders')
  }, [history])

  const handleArchive = React.useCallback(() => {
    if (order && !order.archived) {
      dispatch(completeOrder(match.params.order)).then(() => {
        // dispatch(fetchOrder(match.params.order))
      })
    }
  }, [dispatch, match.params.order, order])

  // websockets
  const onMsgReceived = React.useCallback(
    ({ event, order }) => {
      switch (event) {
        case 'created':
          return null
        case 'updated':
          return dispatch(updateCurrentOrder(order))
        default:
          return null
      }
    },
    [dispatch]
  )
  useOrderStatus(match.params.order, onMsgReceived)

  return (
    <Dialog
      scroll="paper"
      fullScreen
      open
      onClose={handleClose}
      transitionDuration={0}
      disableEnforceFocus
    >
      {/* {!order ? (
        <Loader></Loader>
      ) : ( */}
      <OrderTemplate
        header={
          <>
            <FullscreenExitButton variant="back" onClick={handleClose} />

            <FullscreenDialogTitle
              subtitle={order && formatDate(order.created_at, true)}
            >
              {notFound && t('orders:not_found')}
              {order && order.short_address}{' '}
              {order &&
                order.type === 'activation' &&
                t('orders:device_activation')}
              {order && order.address_uuid && (
                <Tooltip title={t('orders:to_address')}>
                  <IconButton component={Link} to={`/e/${order.address_uuid}`}>
                    <SubdirectoryArrowRight
                      style={{
                        marginLeft: 8,
                        position: 'absolute',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {order && (
                <Typography
                  style={{
                    position: 'fixed',
                    right: '2rem',
                    top: '2rem',
                    fontSize: '2rem',
                    color: '#8b959e',
                  }}
                  color="textSecondary"
                >
                  #{order.number}
                </Typography>
              )}
            </FullscreenDialogTitle>
          </>
        }
        subheader={
          order && (
            <>
              <OrderHeader order={order} />
              <Button
                rounded
                color={order.archived ? 'success' : 'primary'}
                variant={order.archived ? 'outlined' : 'contained'}
                onClick={handleArchive}
                disabled={archivingOrder || order.archived}
              >
                {order.archived ? (
                  <>
                    {t('orders:order_archived')}
                    <div
                      style={{
                        marginLeft: 8,
                        borderRadius: '50%',
                        width: 48,
                        height: 48,
                        background: 'rgba(255,255,255,0.3)',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: -20,
                      }}
                    >
                      <Check />
                    </div>
                  </>
                ) : (
                  <>
                    {t('orders:order_archive')} {archivingOrder && '...'}
                    <div
                      style={{
                        marginLeft: 8,
                        borderRadius: '50%',
                        width: 48,
                        height: 48,
                        background: 'rgba(255,255,255,0.3)',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: -20,
                      }}
                    >
                      <Archive />
                    </div>
                  </>
                )}
              </Button>
            </>
          )
        }
      >
        {!order && !notFound && <Loader />}
        {notFound && <Mascotte />}
        {order && <OrderBody order={order} />}
      </OrderTemplate>
      {/* )} */}
    </Dialog>
  )
}

export default withRouter(OrderPage)
