/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react'
import {
  Typography,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as SputnikIcon } from 'assets/Sputnik.svg'
import { ReactComponent as ISee } from 'assets/ISee.svg'
import {
  Archive14,
  Archive3,
  Archive30,
  Archive5,
  Archive7,
  IconColors,
  StrangeCamera,
} from 'sputnik-icons' // fix me
import { Power, PowerOff, Archive, FileCopy } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import { DeviceModel, formatDeviceId, Input } from 'sputnik-ui'
// import cam from 'assets/cam.png'
// import sputnik from 'assets/sputnik.png'
import { ReactComponent as FaceRec } from 'features/services/face_rec.svg'
import useHover from 'hooks/useHover'
import { useDispatch, useSelector } from 'react-redux'
import { betaTestInProduction, copyToClipboard } from 'utils/helpers'
import { showInfoMessage } from 'store/ui/actions'
import { layerGroup } from 'leaflet'
import i18next from 'i18next'
import { ReactComponent as EditOutline } from '../../../assets/EditOutline.svg'

const Tag = styled.span`
  background: ${(p) => {
    switch (p.color) {
      case 'error':
        return p.theme.palette.error[50]

      default:
        return p.theme.palette.primary[500]
    }
  }};
  color: ${(p) => p.theme.palette[p.color].contrastText};
  padding: 0.125rem 0.325rem;
  border-radius: 8px;
  display: inline-flex;
`

const OnlineIcon = styled(Power)`
  color: ${(p) => p.theme.palette.success[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`
const OfflineIcon = styled(PowerOff)`
  color: ${(p) => p.theme.palette.error[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`

const styles = {
  icon: { fontSize: '0.625rem', marginRight: '.325rem', width: 16, height: 16 },
}

const serviceIcons = {
  archive_3: <Archive3 color={IconColors.blue} style={styles.icon} />,
  archive_5: <Archive5 color={IconColors.blue} style={styles.icon} />,
  archive_7: <Archive7 color={IconColors.blue} style={styles.icon} />,
  archive_14: <Archive14 color={IconColors.blue} style={styles.icon} />,
  archive_30: <Archive30 color={IconColors.blue} style={styles.icon} />,
  face_rec: <FaceRec style={styles.icon} />,
}

const isThirdPartyCamera = (serial_number) =>
  serial_number?.slice(0, 2) === 'DC'

export default function DeviceSearchSuggestion({
  firstInstalled,
  secondaryAction,
  to,
  type,
  withAddress = true,
  installed,
  uuid,
  address_type,
  address_uuid,
  serial_number,
  short_address,
  is_online,
  services,
  handleOpenModal,
  alias,
  ...props
}) {
  const { t } = useTranslation(['devices', 'info'])
  const { hovered, bind } = useHover()
  const dispatch = useDispatch()
  const companyUuid = useSelector(({ auth }) => auth.user?.company?.uuid)

  const serviceText = {
    archive_3: i18next.t('devices:helper_text.archive_day', { days: 3 }),
    archive_5: i18next.t('devices:helper_text.archive_day', { days: 5 }),
    archive_7: i18next.t('devices:helper_text.archive_day', { days: 7 }),
    face_rec: i18next.t('devices:helper_text.face_rec'),
  }

  const handleEditThirdPartyCamera = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      handleOpenModal({ uuid })
    },
    [handleOpenModal, uuid]
  )

  return (
    <ListItem
      disabled={!installed}
      key={uuid}
      component={Link}
      // to={`/e/${address_uuid}`}
      to={to || (address_type === 'gate' ? `/gate/` : '/e/') + address_uuid}
      button
      {...bind}
      {...props}
    >
      <ListItemIcon>
        {type === 'camera' ? (
          isThirdPartyCamera(serial_number) ? (
            <StrangeCamera style={{ fill: 'black' }} />
          ) : (
            <ISee />
          )
        ) : (
          <SputnikIcon />
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <span>
              {type === 'camera'
                ? serial_number
                : formatDeviceId(serial_number)}
            </span>
            {address_type === 'gate' ? ` [ ${t('devices:units.gate')} ]` : null}
            {type === 'camera' && isThirdPartyCamera(serial_number) ? (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={handleEditThirdPartyCamera}
              >
                ({alias || t('common:field.note')})
                <EditOutline style={{ width: 20, height: 20 }} />
              </div>
            ) : null}
            <DeviceModel model={props?.model} />
            {hovered && (
              <FileCopy
                style={{ width: 14, height: 14 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyToClipboard(serial_number)
                  dispatch(
                    showInfoMessage(
                      t('info:copy.device', { number: serial_number })
                    )
                  )
                }}
              />
            )}
          </div>
        }
        secondary={
          <>
            {installed && (
              <>
                <Tooltip
                  title={
                    is_online
                      ? t('devices:params.online')
                      : t('devices:params.offline')
                  }
                >
                  {is_online ? (
                    <OnlineIcon style={styles.icon} />
                  ) : (
                    <OfflineIcon style={styles.icon} />
                  )}
                </Tooltip>
                {/* <Archive style={styles.icon} /> */}
              </>
            )}
            {services?.map((identifier) => {
              const Component = serviceIcons[identifier]
              const text = serviceText[identifier]
              return (
                <Tooltip title={text} key={identifier}>
                  {Component}
                </Tooltip>
              )
            })}
            {withAddress &&
              (short_address || t('devices:params.not_installed'))}
          </>
        }
        secondaryTypographyProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
      <ListItemSecondaryAction>
        {firstInstalled && firstInstalled.uuid === uuid && (
          <Tag color="primary" style={{ marginLeft: 'auto' }}>
            <Typography color="inherit">enter</Typography>
          </Tag>
        )}
        {secondaryAction}
        {/* {!installed && (
        <Tag color="error" style={{ marginLeft: 'auto' }}>
          <Typography color="textSecondary">не установлена</Typography>
        </Tag>
      )} */}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

/*

  primary={
          <>
            {type === 'camera'
              ? serial_number
              : `${formatDeviceId(serial_number)} · ${short_serial}`}
          </>
        }
        secondary={
          <>
            {typeof is_online === 'boolean' && (
              <Tooltip title={is_online ? 'в сети' : 'не в сети'}>
                {is_online ? (
                  <OnlineIcon style={styles.icon} />
                ) : (
                  <OfflineIcon style={styles.icon} />
                )}
              </Tooltip>
            )}
            {services?.map((identifier) => {
              const Component = serviceIcons[identifier]

              return <Component style={styles.icon} />
            })}
            {`${address || 'Не установлена'}
            ${service_partner ? ` · Обслуживается «${service_partner}»` : ''}`}
          </>
        }
        secondaryTypographyProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        }}

        */
