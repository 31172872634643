import * as types from './types'

/* regions */

export const getRegionsRequest = () => ({
  type: types.GET_REGIONS_REQUEST,
})
export const getRegionsSuccess = (regions) => ({
  type: types.GET_REGIONS_SUCCESS,
  payload: regions,
})
export const getRegionsError = (error) => ({
  type: types.GET_REGIONS_ERROR,
  payload: error,
})

export const fetchRegionRequest = () => ({
  type: types.FETCH_REGION_REQUEST,
})
export const fetchRegionSuccess = (region) => ({
  type: types.FETCH_REGION_SUCCESS,
  payload: { region },
})
export const fetchRegionFailure = (error) => ({
  type: types.FETCH_REGION_FAILURE,
  payload: { error },
})
