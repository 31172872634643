import React from 'react'
import { withRouter } from 'react-router-dom'
import { keyDownWithClick } from 'utils/helpers'
import SputnikIntercom from '../SputnikIntercom'

const Device = ({ match, history, kind, ...props }) => {
  let DeviceComponent = null

  switch (kind) {
    case 'sputnik':
      DeviceComponent = SputnikIntercom
      break

    default:
      DeviceComponent = null
      break
  }

  const handleRedirect = (event) => {
    keyDownWithClick(event, () =>
      history.push(`/intercom/${props.device.uuid}`)
    )
  }

  return (
    <>
      <div
        style={{ width: '100%' }}
        onKeyDown={handleRedirect}
        onClick={handleRedirect}
        tabIndex="0"
        role="button"
      >
        <DeviceComponent {...props} />
      </div>
      {/* <Route component={DeviceModal} path="/e/:entry/:device" /> */}
    </>
  )
}

export default withRouter(Device)
