import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  display: flex;
  min-height: 125px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  margin: 12px 0px;

  overflow: hidden;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
`

// перекинуть в компоненты если будет часто использоваться

function WarrantyCard({ children, ...props }) {
  return <Card {...props}>{children}</Card>
}

export default WarrantyCard
