export const FETCH_ACCESS_CODES_REQUEST = 'codes/FETCH_ACCESS_CODES_REQUEST'
export const FETCH_ACCESS_CODES_SUCCESS = 'codes/FETCH_ACCESS_CODES_SUCCESS'
export const FETCH_ACCESS_CODES_FAILURE = 'codes/FETCH_ACCESS_CODES_FAILURE'

export const ADD_ACCESS_CODE_REQUEST = 'codes/ADD_ACCESS_CODE_REQUEST'
export const ADD_ACCESS_CODE_SUCCESS = 'codes/ADD_ACCESS_CODE_SUCCESS'
export const ADD_ACCESS_CODE_FAILURE = 'codes/ADD_ACCESS_CODE_FAILURE'

export const REMOVE_ACCESS_CODE_REQUEST = 'codes/REMOVE_ACCESS_CODE_REQUEST'
export const REMOVE_ACCESS_CODE_SUCCESS = 'codes/REMOVE_ACCESS_CODE_SUCCESS'
export const REMOVE_ACCESS_CODE_FAILURE = 'codes/REMOVE_ACCESS_CODE_FAILURE'
