import * as types from './types'

export const fetchOrdersRequest = () => ({
  type: types.FETCH_ORDERS_REQUEST,
})
export const fetchOrdersSuccess = ({ orders, count, isFirstPage }) => ({
  type: types.FETCH_ORDERS_SUCCESS,
  payload: { orders, count, isFirstPage },
})
export const fetchOrdersFailure = (error) => ({
  type: types.FETCH_ORDERS_FAILURE,
  payload: { error },
})

export const fetchOrderRequest = () => ({
  type: types.FETCH_ORDER_REQUEST,
})
export const fetchOrderSuccess = (order) => ({
  type: types.FETCH_ORDER_SUCCESS,
  payload: { order },
})
export const fetchOrderFailure = (error, notFound) => ({
  type: types.FETCH_ORDER_FAILURE,
  payload: { notFound },
})

export const completeOrderRequest = () => ({
  type: types.COMPLETE_ORDER_REQUEST,
})
export const completeOrderSuccess = (msg) => ({
  type: types.COMPLETE_ORDER_SUCCESS,
  payload: { msg },
})
export const completeOrderFailure = (error) => ({
  type: types.COMPLETE_ORDER_FAILURE,
  payload: { error },
})

export const sendMessageRequest = () => ({
  type: types.SEND_MESSAGE_REQUEST,
})
export const sendMessageSuccess = () => ({
  type: types.SEND_MESSAGE_SUCCESS,
})
export const sendMessageFailure = (error) => ({
  type: types.SEND_MESSAGE_FAILURE,
  payload: { error },
})

export const receiveNewOrder = (order) => ({
  type: types.RECEIVE_NEW_ORDER,
  payload: { order },
})

export const updateCurrentOrder = (order) => ({
  type: types.UPDATE_CURRENT_ORDER,
  payload: { order },
})

export const updateOrdersList = (order) => ({
  type: types.UPDATE_ORDERS_LIST,
  payload: { order },
})

export const fetchArchivedOrdersRequest = () => ({
  type: types.FETCH_ARCHIVED_ORDERS_REQUEST,
})
export const fetchArchivedOrdersSuccess = ({
  orders,
  count,
  isFirstPage,
  archivePages,
}) => ({
  type: types.FETCH_ARCHIVED_ORDERS_SUCCESS,
  payload: { orders, count, isFirstPage, archivePages },
})
export const fetchArchivedOrdersFailure = (error) => ({
  type: types.FETCH_ARCHIVED_ORDERS_FAILURE,
  payload: { error },
})

export const clearOrders = () => ({ type: types.CLEAR_ORDERS })
export const clearCurrentOrder = () => ({ type: types.CLEAR_CURRENT_ORDER })
