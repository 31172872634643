import { Portal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from 'sputnik-ui'
import styled from 'styled-components'
import { getScrollbarWidth } from 'utils/helpers'

const MOBILE_HIDE_HEIGHT = 38

const ShowMenu = styled.div`
  display: none;
  position: absolute;
  height: 4px;
  top: ${(MOBILE_HIDE_HEIGHT - 4) / 2}px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  background-color: ${(p) => (p.mobileShow ? '#fff' : `#ccc`)};
  border-radius: 4px;
  width: 60px;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    display: block;
    transition: transform 0.2s;
    transform: ${(p) =>
      p.mobileShow && `translate(-50%, -${MOBILE_HIDE_HEIGHT / 1.5}px);`};
  }
`

const ClickArea = styled.div`
  z-index: 1;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: ${(p) => (p.mobileShow ? '0px' : `${MOBILE_HIDE_HEIGHT}px`)};
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    display: block;
  }
`

const WhiteStylePlace = styled.div`
  position: absolute;
  height: 100%;
  width: 50vw;
  background: #ffffff;
  left: 100%;
  box-shadow: 24px -12px 24px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    display: none;
  }
`

const FooterSide = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
  }
`

const BackPaper = styled.div`
  display: block;
  transition: 0.2s;
  position: fixed;
  pointer-events: none;
  z-index: 1000;
  pointer-events: ${(p) => (p.mobileShow ? 'auto' : `none`)};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${(p) => (p.mobileShow ? 'rgba(0, 0, 0, 0.3)' : `transparent`)};
`

const StyledMiddleWare = styled(Container)`
  min-height: 90px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #ffffff;
  border-radius: 40px 0px 0px 0px;
  padding: 20px 1.625rem;
  padding-left: 40px;
  box-shadow: 0px -12px 24px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    margin: 0px;
    border-radius: 40px 40px 0px 0px;
    padding: 20px 2rem;
    padding-top: 38px;
  }
`

const StyledPageMenu = styled.div`
  && {
    position: fixed;
    z-index: 1001;
    bottom: 0px;
    height: fit-content;
    min-height: 90px;
    overflow: visible;
    left: 0;
    right: 0;
    padding-left: 3.25rem;
    padding-right: ${(p) =>
      p.isScroll && !p.mobileShow ? p.scrollWidth : '0'}px;
    @media screen and (max-width: ${(p) => p.theme.sizes.bigscreen}) {
      padding-left: 1.625rem;
    }
    @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      bottom: 0px;
      padding: 0px;
      margin: 0;

      transition: transform 0.2s;
      transform: ${(p) =>
        p.mobileShow
          ? 'translateY(100%) translateY(-100%)'
          : `translateY(100%) translateY(-${MOBILE_HIDE_HEIGHT}px)`};
    }
  }
`

export const PageMenu = ({ children, ...props }) => {
  const [mobileShow, setMobileShow] = useState(false)
  const [isScroll, setScroll] = useState(false)
  const [scrollWidth, setScrollWidth] = useState(getScrollbarWidth())

  const handleToggleMobile = () => {
    setMobileShow(!mobileShow)
  }
  const handleOpen = () => {
    setMobileShow(true)
  }
  useEffect(
    () => (document.body.style.overflowY = mobileShow ? 'hidden' : 'auto'),
    [mobileShow]
  )

  // body scroll observer
  useEffect(() => {
    const targetNode = document.body
    const config = { attributes: true }
    const callback = (mutationList, observer) => {
      mutationList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          const hasVerticalScrollbar =
            document.body.scrollHeight > window.innerHeight
          if (
            mutation.target.style.overflowY === 'hidden' &&
            hasVerticalScrollbar
          ) {
            setScrollWidth(getScrollbarWidth())
            setScroll(true)
            return
          }
          setScroll(false)
        }
      })
    }
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
    return () => observer.disconnect()
  }, [])

  return (
    <>
      <Portal>
        <BackPaper onClick={handleToggleMobile} mobileShow={mobileShow} />
        <StyledPageMenu
          mobileShow={mobileShow}
          isScroll={isScroll}
          scrollWidth={scrollWidth}
        >
          <StyledMiddleWare>
            <WhiteStylePlace />
            <ClickArea mobileShow={mobileShow} onClick={handleOpen} />
            <ShowMenu mobileShow={mobileShow} onClick={handleToggleMobile} />
            <FooterSide>{children}</FooterSide>
          </StyledMiddleWare>
        </StyledPageMenu>
      </Portal>
    </>
  )
}
