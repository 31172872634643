import { useEffect, useRef, useCallback } from 'react'
import config from 'config'
import ActionCable from 'actioncable'

export default function useIntercomStatus(intercomUuid, onReceived) {
  const cable = useRef()
  const IntercomStatusSubscription = useRef()
  const createSocket = useCallback(() => {
    cable.current = ActionCable.createConsumer(config.socketUrl)
    IntercomStatusSubscription.current = cable.current.subscriptions.create(
      {
        channel: 'IntercomStatusChannel',
        room: `intercom_${intercomUuid}`,
        intercom_uuid: intercomUuid,
      },
      {
        received: onReceived,
      }
    )
  }, [intercomUuid, onReceived])

  useEffect(() => {
    if (intercomUuid && !cable.current) createSocket()

    return () => {
      if (cable.current && IntercomStatusSubscription.current) {
        cable.current.subscriptions.remove(IntercomStatusSubscription.current)
      }
    }
  }, [createSocket, intercomUuid])

  return cable
}
