import Api from 'utils/api'
import * as actions from './actions'

export const getCountries = () => async (dispatch) => {
  dispatch(actions.getCountriesRequest())
  try {
    const countries = await Api.request('/countries')

    dispatch(actions.getCountriesSuccess(countries))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.getCountriesError(message))
  }
}

export const fetchCountry = (countryUuid) => async (dispatch) => {
  dispatch(actions.fetchCountryRequest())
  try {
    const countries = await Api.request(`/countries/${countryUuid}`)

    dispatch(actions.fetchCountrySuccess(countries))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchCountryFailure(message))
  }
}
