export default {
  baseUri:
    process.env.REACT_APP_BASE_URL ||
    'https://api-control.stage.sputnik.systems/api/v1',
  redirectUri:
    process.env.REACT_APP_REDIRECT_URL ||
    'https://api-control.stage.sputnik.systems',
  socketUrl:
    process.env.REACT_APP_SOCKET_URL ||
    'wss://api-control.sputnik.systems/cable',
  ssoUri: process.env.REACT_APP_SSO_URL || 'https://sso.sputnik.systems',
  notifyUrl: 'https://notifier.sputnik.systems/api/v1/emails',
  intercomId: 'ControlUserWeb',
  brand: 'Спутник',
  app: 'Контроль',
}
