import React, { useEffect, useState, useCallback } from 'react'
import { Button } from 'sputnik-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'
import Range from 'rc-slider/lib/Range'
import TextField from '@material-ui/core/TextField'

import { changeFlatRange, fetchEntry } from 'store/resources/operations'
import { useTranslation } from 'react-i18next'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

const MAX = 10000
const MIN = 1

function ChangeFlatRangeModal(props) {
  const { entry: entryUuid } = useParams()

  const [state, setState] = useState({
    flatRange: [0, 0],
    didSetInitialRange: false,
  })

  const entry = useSelector(({ resources: { entries } }) => entries.current)

  const dispatch = useDispatch()

  useEffect(() => {
    if (entry && !state.didSetInitialRange) {
      setState({
        flatRange: [entry.first_flat, entry.last_flat],
        didSetInitialRange: true,
      })
    }
  }, [entry, state])

  const handleChange = useCallback(
    (flatRange) => setState({ ...state, flatRange }),
    [state]
  )

  const onFirstFlatChange = useCallback(
    ({ target: { value } }) =>
      setState({
        ...state,
        flatRange: [value, state.flatRange[1]],
      }),
    [state]
  )

  const onLastFlatChange = useCallback(
    ({ target: { value } }) =>
      setState({
        ...state,
        flatRange: [state.flatRange[0], value],
      }),
    [state]
  )

  const updateFlatRange = useCallback(() => {
    const { flatRange } = state

    dispatch(
      changeFlatRange({
        entry: entryUuid,
        firstFlat: flatRange[0],
        lastFlat: flatRange[1],
      })
    ).then(() => {
      dispatch(fetchEntry(entryUuid))
      props.handleClose()
    })
  }, [dispatch, entryUuid, props, state])

  const { t } = useTranslation(['devices', 'common'])

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.handleClose}
      PaperProps={{ style: { minWidth: 500 } }}
    >
      <DialogTitle>{t('devices:params.flat_range')}</DialogTitle>
      <DialogContent>
        <Range
          min={MIN}
          step={1}
          max={MAX}
          value={state.flatRange}
          onChange={handleChange}
          pushable={1}
          trackStyle={{
            background: 'yellow',
          }}
          activeDotStyle={{ boxShadow: 'none' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            alignItems: 'center',
          }}
        >
          <TextField
            // label="Первая квартира"
            value={state.flatRange[0]}
            type="number"
            onChange={onFirstFlatChange}
            inputProps={{
              style: {
                textAlign: 'center',
              },
              min: MIN,
              step: 1,
              max: MAX - 1,
            }}
          />
          <div style={styles.divider} />
          <TextField
            // label="Последняя квартира"
            value={state.flatRange[1]}
            type="number"
            onChange={onLastFlatChange}
            inputProps={{
              style: {
                textAlign: 'center',
              },
              min: MIN + 1,
              step: '1',
              max: MAX,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} rounded secondary>
          {t('common:button.cancel')}
        </Button>
        <Button rounded onClick={updateFlatRange} variant="contained" primary>
          {t('common:button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  divider: {
    backgroundColor: 'black',
    width: 30,
    height: 2,
    marginRight: '0.625rem',
    marginLeft: '0.625rem',
  },
}

export default ChangeFlatRangeModal
