import React from 'react'
import { ListItemText, ListItem, ListItemIcon } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import useHover from 'hooks/useHover'
import { copyToClipboard } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { showInfoMessage } from 'store/ui/actions'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import camera from './camera.png'
import intercom from './sputnik.png'
import gate from './gate.png'

const images = {
  camera,
  gate,
  intercom,
}

const StyledListItemText = styled(ListItemText)`
  && {
    /* max-height: 28px;
    line-height: .4; */
  }
`

export default function DeviceListItem({
  label,
  type,
  secondaryLabel,
  ...props
}) {
  const { hovered, bind } = useHover()
  const dispatch = useDispatch()
  const { t } = useTranslation('info')

  return (
    <ListItem
      style={{ padding: 0, margin: '-0.625rem -1rem', width: '100%' }}
      {...bind}
    >
      <ListItemIcon style={{ minWidth: 0 }}>
        <img
          src={images[type]}
          alt="device"
          style={{
            height: 32,
            width: 56,
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
      </ListItemIcon>
      <StyledListItemText
        primary={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // fontSize: '1rem',
              lineHeight: 1,
            }}
          >
            {label}
            {hovered && (
              <FileCopy
                style={{
                  width: 14,
                  height: 14,
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyToClipboard(label)
                  dispatch(
                    showInfoMessage(t('info:copy.device', { number: label }))
                  )
                }}
              />
            )}
          </div>
        }
        secondary={<span style={{ lineHeight: 1 }}>{secondaryLabel}</span>}
      />
    </ListItem>
  )
}
