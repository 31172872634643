import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core'
import { DeleteForever, Dialpad } from '@material-ui/icons'
import ConfirmActionModal from 'components/organisms/ConfirmActionModal'
// import { showInfoMessage } from 'store/ui/actions'
import { formatDate } from 'utils/helpers'
import { removeAccessCode, fetchAccessCodes } from 'store/codes/operations'
import { useTranslation } from 'react-i18next'

const AccessCode = ({ onClick, props, uuid, expTime, children }) => {
  const dispatch = useDispatch()
  const { entry: entryUuid } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)

  // const action = () => {
  //   dispatch(showInfoMessage(`test`))
  // }
  const handleDelete = useCallback(
    () =>
      dispatch(removeAccessCode({ codeUuid: uuid, entryUuid })).then(() => {
        setDeleteModal(false)
        dispatch(fetchAccessCodes(entryUuid))
      }),
    [dispatch, entryUuid, uuid]
  )

  const { t } = useTranslation(['time', 'keys'])
  return (
    <ListItem {...props}>
      <ListItemIcon>
        <Dialpad />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="body2"
            style={{ fontWeight: 500, fontSize: '1.325rem' }}
          >
            {children}
          </Typography>
        }
        secondary={
          expTime
            ? `${t('keys:helper.valid_until')} ${formatDate(expTime)}`
            : t('keys:helper.valid_timeless')
        }
      />
      <ListItemSecondaryAction>
        {/* <IconButton onClick={action}>
          <Share></Share>
        </IconButton> */}
        <IconButton disableRipple onClick={() => setDeleteModal(true)}>
          <DeleteForever color="error" />
        </IconButton>
      </ListItemSecondaryAction>
      <ConfirmActionModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleAction={handleDelete}
        action={`${t('keys:actions.remove_access_code')} ${children}`}
      />
    </ListItem>
  )
}
export default AccessCode
