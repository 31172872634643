import React from 'react'
import { ReactComponent as CitizenIcon } from 'assets/Citizen.svg'
import { ReactComponent as EngineerInstallation } from 'assets/Engineer.svg'
import { ReactComponent as EngineerSettings } from 'assets/EngineerSettings.svg'
import { ReactComponent as AnomalyIcon } from 'assets/Robot.svg'
import styled from 'styled-components'
import { ListItemIcon } from '@material-ui/core'

const getOrderParams = (variant) => {
  switch (variant) {
    case 'installation':
      return { color: 'lightblue', icon: EngineerInstallation }
    case 'configuration':
      return { color: 'peach', icon: EngineerSettings }
    case 'citizen_request':
      return { color: 'green', icon: CitizenIcon }
    case 'activation':
      return { color: 'lightblue', icon: EngineerInstallation }

    default:
      return { color: 'pink', icon: AnomalyIcon }
  }
}

const IconWrapper = styled(ListItemIcon)`
  && {
    margin-right: 1rem;
    color: ${(p) =>
      p.theme.palette.other[`${getOrderParams(p.variant).color}Dark`]};
    background-color: ${(p) =>
      p.background
        ? p.theme.palette.other[getOrderParams(p.variant).color]
        : 'transparent'};
    border-radius: 50%;
    min-width: 0;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    & svg {
      width: 24px;
      height: 24px;
    }
  }
`

function OrderIcon({ variant = 'anomaly', background = true, ...props }) {
  const Icon = getOrderParams(variant).icon

  return (
    <IconWrapper background={background} variant={variant} {...props}>
      <Icon />
    </IconWrapper>
  )
}

export default OrderIcon
