export const GET_ENTRIES_REQUEST = 'resources/entries/GET_ENTRIES_REQUEST'
export const GET_ENTRIES_SUCCESS = 'resources/entries/GET_ENTRIES_SUCCESS'
export const GET_ENTRIES_ERROR = 'resources/entries/GET_ENTRIES_ERROR'

export const GET_ENTRY_REQUEST = 'resources/entries/GET_ENTRY_REQUEST'
export const GET_ENTRY_SUCCESS = 'resources/entries/GET_ENTRY_SUCCESS'
export const GET_ENTRY_ERROR = 'resources/entries/GET_ENTRY_ERROR'

export const FETCH_ENTRY_REQUEST = 'resources/entries/FETCH_ENTRY_REQUEST'
export const FETCH_ENTRY_SUCCESS = 'resources/entries/FETCH_ENTRY_SUCCESS'
export const FETCH_ENTRY_FAILURE = 'resources/entries/FETCH_ENTRY_FAILURE'
