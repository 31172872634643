import Api from 'utils/api'
import * as actions from './actions'

export const fetchCurrentPartner = () => async (dispatch) => {
  dispatch(actions.fetchCurrentRequest())
  try {
    const partner = await Api.request(`/service_partners/me`)
    dispatch(actions.fetchCurrentSuccess(partner))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchCurrentFailure(message))
    return Promise.reject()
  }
}

export const createPartner = (values) => async (dispatch) => {
  dispatch(actions.createPartnerRequest())

  try {
    const payload = await Api.request(`/service_partners/update_profile`, {
      method: 'PUT',
      body: JSON.stringify(values),
    })
    dispatch(actions.createPartnerSuccess(payload?.message))
    dispatch(fetchCurrentPartner())
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.createPartnerFailure(message))
    return Promise.reject()
  }
}
