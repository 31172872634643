import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import config from 'config'
import api from 'utils/api'
import { showErrorMessage, showInfoMessage } from 'store/ui/actions'
import { buildUrl } from 'utils/helpers'

/// /////////////////// Load KEys
// export const reFetchKeysProList = createAsyncThunk(
//   'keysPro/fetchList',
//   async ({ addressUuid, kind }, { getState, rejectWithValue, dispatch }) => {
//     const params = {
//       entry_uuid: addressUuid,
//       kind: kind,
//       sortBy: 'created_at',
//     }
//     try {
//       const payload = api.request(
//         buildUrl(`/key_mocks`, params),
//         {},
//         config.baseUri
//       )
//       return payload
//     } catch (err) {
//       const { error } = await err.json()
//       dispatch(showInfoMessage(error))
//       return rejectWithValue(error)
//     }
//   }
// )
// detached, attached, master
export const fetchConnectedKeysList = createAsyncThunk(
  'keysPro/fetchList/connected',
  async ({ addressUuid }, { rejectWithValue, dispatch, getState }) => {
    const {
      keysPro: { limit, sortBy, page },
    } = getState()

    const params = {
      entry_uuid: addressUuid,
      kind: 'attached',
      sort_by: sortBy,
      limit,
      page,
    }
    try {
      const payload = api.request(
        buildUrl(`/key_mocks`, params),
        {},
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchUnConnectedKeysList = createAsyncThunk(
  'keysPro/fetchList/unConnected',
  async ({ addressUuid }, { rejectWithValue, dispatch, getState }) => {
    const {
      keysPro: { limit, sortBy, page },
    } = getState()
    const params = {
      entry_uuid: addressUuid,
      kind: 'detached',
      sort_by: sortBy,
      limit,
      page,
    }
    try {
      const payload = api.request(
        buildUrl(`/key_mocks`, params),
        {},
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchServiceKeysList = createAsyncThunk(
  'keysPro/fetchList/service',
  async ({ addressUuid }, { rejectWithValue, dispatch, getState }) => {
    const {
      keysPro: { limit, sortBy, page },
    } = getState()
    const params = {
      entry_uuid: addressUuid,
      kind: 'master',
      limit,
      sort_by: sortBy,
      page,
    }
    try {
      const payload = api.request(
        buildUrl(`/key_mocks`, params),
        {},
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchInvalidKeysProList = createAsyncThunk(
  'keysPro/fetchList/invalid',
  async ({ addressUuid }, { rejectWithValue, dispatch, getState }) => {
    const {
      keysPro: { limit, page },
    } = getState()
    const params = {
      limit,
      page,
    }
    try {
      const payload = api.request(
        buildUrl(`/key_owners/${addressUuid}/invalid_keys`, params),
        {},
        config.baseUri
      )
      // const payload = api.request(`/key_owners/${addressUuid}/invalid_keys`) //todo
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)
/// /////////////////// Load KEys

export const createKeyPro = createAsyncThunk(
  'keysPro/create',
  async (
    { addressKind, addressUuid, hex, comment },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const payload = await api.request(`/key_mocks`, {
        method: 'POST',
        body: JSON.stringify({
          address_kind: addressKind,
          address_uuid: addressUuid,
          hexes: Array.isArray(hex) ? hex : [hex],
          comment,
        }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const changeKeyStatus = createAsyncThunk(
  'keysPro/changeStatus',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request(`/key_mocks`, {
        method: 'POST',
        body: JSON.stringify({
          ...args,
        }),
      })
      dispatch(payload?.message)
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const importKeysPro = createAsyncThunk(
  'keysPro/changeStatus',
  async ({ uuid, csv }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData()
      formData.append('file', csv)
      formData.append('address_kind', 'entry')
      formData.append('address_uuid', uuid)
      const payload = await api.request(`/key_mocks/import`, {
        method: 'POST',
        body: formData,
        headers: new Headers(),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const deleteKeysPro = createAsyncThunk(
  'keysPro/deleteKeys',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(`/key_mocks`, {
        method: 'DELETE',
        body: JSON.stringify({
          ...args,
        }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const disableKeysPro = createAsyncThunk(
  'keysPro/disableKeys',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(`/key_mocks/disable`, {
        method: 'POST',
        body: JSON.stringify({
          ...args,
        }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()

      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const enableKeysPro = createAsyncThunk(
  'keysPro/enableKeys',
  async ({ uuids }, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request(`/key_mocks/enable`, {
        method: 'POST',
        body: JSON.stringify({
          uuids,
        }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const exportKeysPro = createAsyncThunk(
  'keysPro/export',
  async ({ addressUuid }, { rejectWithValue, dispatch }) => {
    try {
      const csv = await api.request(
        `/key_mocks/export`,
        {
          method: 'POST',
          body: JSON.stringify({ entry_uuid: addressUuid }),
        },
        undefined,
        true
      )
      const url = window.URL.createObjectURL(new Blob([csv]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'keys.csv') // or any other extension
      document.body.appendChild(link)
      link.click()
      return Promise.resolve()
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const connectKeyPro = createAsyncThunk(
  'keysPro/connectFlat',
  async (
    { flat, hex, kind, addressUuid, addressKind, comment },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const payload = await api.request(
        `/key_mocks`,
        {
          method: 'POST',
          body: JSON.stringify({
            flat_num: flat,
            hexes: Array.isArray(hex) ? hex : [hex],
            address_kind: addressKind,
            kind,
            address_uuid: addressUuid,
            comment,
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const unConnectKeyPro = createAsyncThunk(
  'keysPro/keyProUnConnect',
  async (
    { hex, addressUuid, addressKind, comment },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const payload = await api.request(
        `/key_mocks`,
        {
          method: 'POST',
          body: JSON.stringify({
            kind: 'detached',
            hexes: Array.isArray(hex) ? hex : [hex],
            address_kind: addressKind,
            address_uuid: addressUuid,
            comment,
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const keysProEditComment = createAsyncThunk(
  'keysPro/editComment',
  async ({ uuid, comment }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_mocks/${uuid}`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            comment,
          }),
        },
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const removeInvalidKeyPro = createAsyncThunk(
  'keysPro/removeInvalid',
  async ({ addressUuid, id }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_owners/${addressUuid}/invalid_keys/${id}`,
        {
          method: 'DELETE',
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const removeInvalidKeyProPlural = createAsyncThunk(
  'keysPro/removeAllInvalid',
  async ({ addressUuid, hexes }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_owners/${addressUuid}/invalid_keys/destroy_set`,
        {
          body: JSON.stringify({ hexes }),
          method: 'DELETE',
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const addInvalidKeyPro = createAsyncThunk(
  'keysPro/addInvalid',
  async ({ addressUuid, ids }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_owners/${addressUuid}/invalid_keys/push_key`,
        {
          method: 'POST',
          body: JSON.stringify({
            ids: Array.isArray(ids) ? ids : [ids],
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const syncKeysPro = createAsyncThunk(
  'keysPro/sync',
  async ({ addressUuid }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_mocks/synchronize`,
        {
          method: 'POST',
          body: JSON.stringify({
            entry_uuid: addressUuid,
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)
// api/v1/key_mocks/synchronize
/// /////////////////// EditKeys //////////////////////

export const fetchAvaliableEntries = createAsyncThunk(
  'keysPro/fetchAvailableEntries',
  async ({ hex, fullAddress }, { rejectWithValue, dispatch }) => {
    const params = {
      hex,
      full_address: fullAddress,
    }
    try {
      const payload = api.request(
        `/key_mocks/available_entries`,
        {
          method: 'POST',
          body: JSON.stringify(params),
        },
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchPresentEntries = createAsyncThunk(
  'keysPro/fetchPresentEntries',
  async ({ hex }, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request(
        `/key_mocks/present_entries`,
        {
          method: 'POST',
          body: JSON.stringify({
            hex,
          }),
        },
        config.baseUri
      )
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const fetchCoverageArea = createAsyncThunk(
  'keysPro/coverageArea',
  async ({ keyUuid }, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request(`/key_mocks/${keyUuid}`)
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const changeCoverageAreaKeyInfo = createAsyncThunk(
  'keysPro/changeKeyInfo',
  async ({ info, keyUuid }, { rejectWithValue, dispatch }) => {
    try {
      const payload = api.request(
        `/key_mocks/${keyUuid}`,
        {
          method: 'PATCH',
          body: JSON.stringify(info),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

// export const fetchAllEntries = createAsyncThunk(
//   'keysPro/all_entries',
//   async (args, { rejectWithValue, dispatch }) => {
//     try {
//       const payload = api.request(`/entries`)
//       return payload
//     } catch (err) {
//       const { error } = await err.json()
//       dispatch(showInfoMessage(error))
//       return rejectWithValue(error)
//     }
//   }
// )

export const keysProLinkToAddress = createAsyncThunk(
  'keysPro/linkToAddress',
  async ({ keyUuid, addressUuid }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_mocks/change_master_key_address`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            uuid: keyUuid,
            address_uuids: Array.isArray(addressUuid)
              ? addressUuid
              : [addressUuid],
            operation: 'add',
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

export const keysProUnLinkAddress = createAsyncThunk(
  'keysPro/unLinkAddress',
  async ({ keyUuid, addressUuid }, { rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/key_mocks/change_master_key_address`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            uuid: keyUuid,
            address_uuids: Array.isArray(addressUuid)
              ? addressUuid
              : [addressUuid],
            operation: 'delete',
          }),
        },
        config.baseUri
      )
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(error)
    }
  }
)

/// /////////////////// EditKeys //////////////////////

const initialState = {
  limit: 9999,
  sortBy: 'created_at',
  page: 1,
  currentTable: null,
  connected: {
    firstLoad: false,
    pending: false,
    list: [],
  },
  unConnected: {
    firstLoad: false,
    pending: false,
    list: [],
  },
  service: {
    firstLoad: false,
    pending: false,
    list: [],
  },
  invalid: {
    firstLoad: false,
    pending: false,
    list: [],
  },
  selected: [],
  /// // EditKey
  coverageArea: {
    firstLoad: false,
    pending: false,
    info: null,
  },
  presentEntries: {
    firstLoad: false,
    pending: false,
    selected: [],
    list: [],
    // entries: {},
    // keyMock: [],
  }, // avaliableEntries
  avaliableEntries: {
    pending: false,
    selected: [],
    list: [],
  },
  // allEntries: {
  //   pending: false,
  //   selected: [],
  //   list: [],
  // },
}

export const keysProSlice = createSlice({
  name: 'keysPro',
  initialState,
  reducers: {
    clearKeys: () => initialState,
    clearSelectedKeys: (state) => {
      state.selected = []
    },
    keysProChangeSort: (state, { payload }) => {
      state.sortBy = payload
    },
    keysProSetTable: (state, { payload }) => {
      state.currentTable = payload
    },
    selectKeys: (state, { payload }) => {
      state.selected = payload.keys
    },
    editKeyCommentLocal: (state, { payload: { uuid, comment } }) => {
      const s = current(state)
      const index = s[s.currentTable].list.findIndex((el) => el.uuid === uuid)
      state[state.currentTable].list[index].comment = comment
    },
    /// //
    clearSelectedPresentEntries: (state) => {
      state.presentEntries.selected = [] // initialState.presentEntries
    },
    selectPresentEntries: (state, { payload }) => {
      state.presentEntries.selected = payload.entries
    },
    /// //
    clearSelectedAvaliAbleEntries: (state) => {
      state.avaliableEntries.selected = [] // initialState.avaliableEntries
    },
    selectAvaliableEntries: (state, { payload }) => {
      state.avaliableEntries.selected = payload.entries
    },
    clearAllCoverageArea: (state, { payload }) => {
      state.coverageArea = initialState.coverageArea
      state.avaliableEntries = initialState.avaliableEntries
      state.presentEntries = initialState.presentEntries
    },
  },
  extraReducers: {
    /// ////////////////////////////////
    [fetchConnectedKeysList.fulfilled]: (state, { payload }) => {
      state.connected.pending = false
      state.connected.list = payload
      state.connected.firstLoad = true
    },
    [fetchConnectedKeysList.rejected]: (state, { payload }) => {
      state.connected.pending = false
    },
    [fetchConnectedKeysList.pending]: (state, { payload }) => {
      state.connected.pending = !state.connected.firstLoad
    },
    /// ////////////////////////////////
    [fetchUnConnectedKeysList.fulfilled]: (state, { payload }) => {
      state.unConnected.pending = false
      state.unConnected.list = payload
      state.unConnected.firstLoad = true
    },
    [fetchUnConnectedKeysList.rejected]: (state, { payload }) => {
      state.unConnected.pending = false
    },
    [fetchUnConnectedKeysList.pending]: (state, { payload }) => {
      state.unConnected.pending = !state.unConnected.firstLoad
    },
    /// ////////////////////////////////
    [fetchServiceKeysList.fulfilled]: (state, { payload }) => {
      state.service.pending = false
      state.service.list = payload
      state.service.firstLoad = true
    },
    [fetchServiceKeysList.rejected]: (state) => {
      state.service.pending = false
    },
    [fetchServiceKeysList.pending]: (state, { payload }) => {
      state.service.pending = !state.service.firstLoad
    },
    /// ////////////////////////////////
    [fetchInvalidKeysProList.fulfilled]: (state, { payload }) => {
      state.invalid.firstLoad = true
      state.invalid.pending = false
      state.invalid.list = payload
    },
    [fetchInvalidKeysProList.rejected]: (state, { payload }) => {
      state.invalid.pending = false
    },
    [fetchInvalidKeysProList.pending]: (state, { payload }) => {
      state.invalid.pending = !state.invalid.firstLoad
    },
    /// ////////////////////////////////
    [fetchPresentEntries.fulfilled]: (state, { payload }) => {
      state.presentEntries.firstLoad = true
      state.presentEntries.pending = false
      state.presentEntries.list = payload
    },
    [fetchPresentEntries.rejected]: (state, { payload }) => {
      state.presentEntries.pending = false
    },
    [fetchPresentEntries.pending]: (state, { payload }) => {
      state.presentEntries.pending = !state.presentEntries.firstLoad
    },
    /// ////////////////////////////////
    [fetchCoverageArea.fulfilled]: (state, { payload }) => {
      state.coverageArea.pending = false
      state.coverageArea.info = payload
    },
    [fetchCoverageArea.rejected]: (state, { payload }) => {
      state.coverageArea.pending = false
    },
    [fetchCoverageArea.pending]: (state) => {
      state.coverageArea.pending = true
    },
    /// ////////
    [fetchAvaliableEntries.fulfilled]: (state, { payload }) => {
      state.avaliableEntries.pending = false
      state.avaliableEntries.list = payload
      state.avaliableEntries.firstLoad = true
    },
    [fetchAvaliableEntries.rejected]: (state, { payload }) => {
      state.avaliableEntries.pending = false
    },
    [fetchAvaliableEntries.pending]: (state, { payload }) => {
      state.avaliableEntries.pending = !state.avaliableEntries.firstLoad
    },

    // [fetchAllEntries.fulfilled]: (state, { payload }) => {
    //   state.allEntries.pending = false
    //   state.allEntries.list = payload
    // },
    // [fetchAllEntries.rejected]: (state, { payload }) => {
    //   state.allEntries.pending = false
    // },
    // [fetchAllEntries.pending]: (state, { payload }) => {
    //   state.allEntries.pending = true
    // },
  },
})

export const {
  clearKeys,
  clearSelectedKeys,
  selectKeys,
  clearSelectedPresentEntries,
  selectPresentEntries,
  clearSelectedAvaliAbleEntries,
  selectAvaliableEntries,
  // clearSelectedAllEntries,
  // selectAllEntries,
  keysProChangeSort,
  editKeyCommentLocal,
  keysProSetTable,
  clearAllCoverageArea,
} = keysProSlice.actions

export default keysProSlice.reducer
