import React, { Suspense, useCallback } from 'react'
import {
  Route,
  Switch,
  Redirect,
  NavLink,
  withRouter,
  useRouteMatch,
} from 'react-router-dom'
import { Navbar, Toast, Loader } from 'sputnik-ui'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Typography, IconButton, Tooltip } from '@material-ui/core'
import { KeyboardArrowRight, FileCopy, Update } from '@material-ui/icons'

import { AppTemplate } from 'components'
import { clearStatus, showInfoMessage } from 'store/ui/actions'
import { receiveNewOrder, updateOrdersList } from 'store/orders/actions'
import { getMsg, getError } from 'store/resources/selectors'

import useOrdersListStatus from 'hooks/useOrdersListStatus'
import { useTranslation } from 'react-i18next'
import requireAuth from '../utils/requireAuth'
import ProfileModal from './organisms/ProfileModal'
import { PrivateRouteUser } from './atoms/PrivateRoutes'

const DeviceZonePage = React.lazy(() => import('./pages/DeviceZonePage'))
const AccessFlatDevicePage = React.lazy(() =>
  import('./pages/AccessFlatDevicePage')
)
const HomePage = React.lazy(() => import('./pages/HomePage'))
const CountryPage = React.lazy(() => import('./pages/CountryPage'))
const RegionPage = React.lazy(() => import('./pages/RegionPage'))
const AreaPage = React.lazy(() => import('./pages/AreaPage'))
const LocalityPage = React.lazy(() => import('./pages/LocalityPage'))
const StreetPage = React.lazy(() => import('./pages/StreetPage'))
const HousePage = React.lazy(() => import('./pages/HousePage'))
const EntryPage = React.lazy(() => import('./pages/EntryPage'))
const FlatPage = React.lazy(() => import('./pages/FlatPage'))
const OrdersPage = React.lazy(() => import('./pages/OrdersPage'))
const EntryHistoryPage = React.lazy(() => import('./pages/EntryHistory'))
const DeviceModal = React.lazy(() => import('./molecules/DeviceModal'))
const WarrantyPage = React.lazy(() => import('./pages/WarrantyPage'))
const StreamPage = React.lazy(() => import('./pages/StreamPage'))

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px !important;
  width: 100%;
  color: ${(p) => p.theme.palette.text.secondary};
  white-space: nowrap;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const LocationLink = styled(Typography).attrs((p) => ({
  style: {
    color: p.theme.palette.text.secondary,
  },
  activeStyle: {
    color: p.theme.palette.text.primary,
  },
  component: NavLink,
}))`
  && {
    text-decoration: none;
    &:hover {
      color: ${(p) => p.theme.palette.text.primary};
    }
  }
`

const App = ({
  parents,
  currentAddress,
  updateAction,
  msg,
  info,
  error,
  dispatch,
  location,
  newOrder,
  history,
  isPro,
}) => {
  const { t } = useTranslation([
    'common',
    'orders',
    'geo',
    'info',
    'devices',
    'time',
  ])
  const WarrantyMath = useRouteMatch('/warranty/:number')?.params?.number
  const copyAddress = () => {
    // copy to clipboard
    const el = document.createElement('textarea')
    el.value = currentAddress
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    dispatch(showInfoMessage(t('info:copy.phone')))
  }

  // websockets
  const onMsgReceived = useCallback(
    ({ event, order }) => {
      switch (event) {
        case 'created':
          return dispatch(receiveNewOrder(order))
        case 'updated':
          return dispatch(updateOrdersList(order))
        default:
          return new Error('Order Message Error')
      }
    },
    [dispatch]
  )
  useOrdersListStatus(onMsgReceived)

  const copySome = (e) => {
    const el = document.createElement('textarea')
    el.value = e
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    dispatch(showInfoMessage(t('info:copy.phone')))
  }

  return (
    <AppTemplate
      header={
        <Navbar logo app shadow user copy={copySome}>
          <LocationWrapper>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <LocationLink exact to="/">
                {t('common:nav.home')}
              </LocationLink>
              {parents && parents.length > 0 && (
                <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
              )}
            </span>
            {location.pathname === '/orders' && (
              <>
                <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  <LocationLink to="/orders">
                    {t('orders:units.order', { count: 1322 })}
                  </LocationLink>
                </span>
              </>
            )}
            {location.pathname === '/warranty' && (
              <>
                <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  <LocationLink to="/warranty">
                    {t('devices:actions.warranty')}
                  </LocationLink>
                </span>
              </>
            )}
            {location.pathname.includes('/warranty/') && (
              <>
                <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  <LocationLink to="/warranty">
                    {t('devices:actions.warranty')}
                  </LocationLink>
                </span>
                <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                  }}
                >
                  <LocationLink to={`${location.pathname}`}>
                    {WarrantyMath}
                  </LocationLink>
                </span>
              </>
            )}

            {parents &&
              parents.map(({ type, uuid, full_name }, idx) => (
                <span
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  key={uuid}
                >
                  <LocationLink to={`/${type.slice(0, 1)}/${uuid}`}>
                    {full_name}
                  </LocationLink>
                  {idx !== parents.length - 1 && (
                    <KeyboardArrowRight style={{ margin: '0 .325rem' }} />
                  )}
                </span>
              ))}
            {parents && parents.length > 0 && (
              <Tooltip title={t('geo:actions.copy_address')} placement="bottom">
                <IconButton
                  style={{ marginLeft: 'auto' }}
                  color="inherit"
                  onClick={copyAddress}
                  disableRipple
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            )}
          </LocationWrapper>
        </Navbar>
      }
    >
      <Suspense
        fallback={
          <Loader
            fullPage
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          />
        }
      >
        <Switch>
          <Route component={HomePage} exact path="/" />
          <Route component={HomePage} exact path="/add_address" />
          <Route component={HomePage} exact path="/search_address" />

          <Route component={OrdersPage} path="/orders" />

          {/* <Route component={EntryHistoryPage} path="e/:entry/history" /> */}
          <Route component={WarrantyPage} exact path="/warranty" />
          <Route component={WarrantyPage} exact path="/warranty/:number" />
          <Route component={CountryPage} exact path="/c/:country" />
          <Route component={RegionPage} exact path="/r/:region" />
          <Route component={AreaPage} exact path="/a/:area" />
          <Route component={LocalityPage} exact path="/l/:locality" />
          <Route component={StreetPage} exact path="/s/:street" />
          <Route component={HousePage} exact path="/h/:house" />
          <Route component={EntryPage} exact path="/e/:entry" />
          {/* <Route component={EntryPage} exact path="/e/:entry/keys_pro" /> */}
          <Route component={FlatPage} exact path="/f/:flat" />
          {/* <Route component={DeviceModal} exact path="/bulatzaebal/:gate" /> */}
          <Route component={DeviceModal} exact path="/gate/:gate" />
          <Route component={DeviceModal} exact path="/intercom/:device" />

          {isPro && (
            <>
              <Route
                component={StreamPage}
                exact
                path="/intercom/stream/:device"
              />
              <Route
                component={StreamPage}
                exact
                path="/camera/stream/:camera"
              />
              <Route component={StreamPage} exact path="/gate/stream/:gate" />
            </>
          )}
          {/* <Route component={DeviceZonePage} exact path="/device_zone/:device" /> */}
          {/*  
           <Route
            component={AccessFlatDevicePage}
            exact
            path="/access_device/:device"
          /> */}
          <Redirect to="/" />
        </Switch>
      </Suspense>

      <ProfileModal />

      <Toast
        variant="info"
        open={!!newOrder}
        onClick={() => {
          history.push(`/orders/${newOrder}`)
          dispatch(clearStatus())
        }}
        handleClose={(e) => {
          e.stopPropagation()
          dispatch(clearStatus())
        }}
        autoHideDuration={null}
      >
        <div>
          <Typography variant="h6">{t('orders:notifications.new')}</Typography>

          <Typography color="textSecondary" component="span">
            {t('common:button.click_to_see')}
          </Typography>
        </div>
      </Toast>
      <Toast
        variant="info"
        open={!!updateAction}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            disableRipple
            style={{
              marginRight: -15,
            }}
          >
            <Update />
          </IconButton>
        }
        onClick={updateAction}
      >
        <div>
          <Typography variant="h6">{t('common:toast.new_version')}</Typography>

          <Typography color="textSecondary" component="span">
            {t('common:button.click_to_update')}
          </Typography>
        </div>
      </Toast>
      <Toast
        open={!!info}
        handleClose={() => dispatch(clearStatus())}
        variant="info"
      >
        {info}
      </Toast>
      <Toast
        title={t('common:toast.success')}
        open={!!msg}
        handleClose={() => dispatch(clearStatus())}
        variant="success"
      >
        {msg}
      </Toast>
      <Toast
        title={t('common:toast.error')}
        open={!!error}
        handleClose={() => dispatch(clearStatus())}
        variant="error"
      >
        {error}
      </Toast>
    </AppTemplate>
  )
}

const mapStateToProps = ({
  auth,
  resources,
  devices,
  keys,
  installations,
  installationsNew,
  ui,
  orders,
  partners,
  codes,
}) => ({
  isPro: auth?.user?.pro,
  parents: resources.current.parents,
  currentAddress: resources.current.fullAddress,
  error:
    devices.error ||
    keys.error ||
    installations.error ||
    orders.error ||
    partners.error ||
    installationsNew.error ||
    ui.error ||
    codes.error ||
    getError(resources),

  msg:
    devices.msg ||
    codes.msg ||
    keys.msg ||
    installations.msg ||
    installationsNew.msg ||
    orders.msg ||
    partners.msg ||
    getMsg(resources),
  info: ui.info,
  updateAction: ui.updateAction,
  newOrder: ui.newOrder,
})

export default connect(mapStateToProps)(withRouter(requireAuth(App)))
