import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const ToggleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: #6d6d6d;
  user-select: none;
  border-radius: 12px;
  background-color: #ededed;
  cursor: pointer;
  width: 100%;
  max-width: 320px;
`

const Label = styled.div`
  padding: 14px;
  width: 50%;
  text-align: center;
  z-index: 1;
  border-radius: 12;
  transition: 0.3s;
  &.active {
    color: white;
  }
`

const StyledMotion = styled(motion.div)`
  display: flex;
  align-items: center;
  position: absolute;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  background: #0ea7ff;
  white-space: nowrap;
`

function Toggle({ init, label1, label2, checked, onChange, ...props }) {
  const [isActive, setActive] = useState(init || checked || false)

  const handleClick = () => {
    setActive(!isActive)
    onChange()
  }

  useEffect(() => {
    setActive(checked)
  }, [checked])

  return (
    <ToggleWrapper onClick={handleClick} {...props}>
      <Label className={!isActive && 'active'}>{label1}</Label>
      <Label className={isActive && 'active'}>{label2}</Label>
      <StyledMotion
        animate={isActive ? { x: '100%' } : { x: '0%' }}
        transition={{ duration: 0.3 }}
      />
    </ToggleWrapper>
  )
}

export default Toggle
