import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import TerrainIcon from '@material-ui/icons/Terrain'

import { getRegions } from 'store/operations'
import { selectRegion } from 'store/actions'
import { useTranslation } from 'react-i18next'

const searchRegions = (props, query) => {
  const { selectedCountry, dispatch } = props

  const country_uuid = (selectedCountry && selectedCountry.uuid) || null
  const q = query || null

  dispatch(
    getRegions({
      country_uuid,
      q,
    })
  )
}

const Regions = ({
  regions,
  err,
  classes,
  dispatch,
  history,
  match,
  ...props
}) => {
  const { t } = useTranslation('geo')
  return (
    <Card
      title={t('geo:locations.region_plural')}
      titleIcon={<TerrainIcon />}
      {...props}
    >
      {regions ? (
        regions.map((r) => (
          <ListItem
            key={r.uuid}
            button
            onClick={() => {
              dispatch(selectRegion(r))
              history.push(`/r/${r.uuid}`)
            }}
          >
            <ListItemIcon>
              <TerrainIcon />
            </ListItemIcon>
            <ListItemText primary={r.name} />
            <ListItemSecondaryAction style={{ marginRight: '1rem' }}>
              <ArrowForwardIcon />
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.regions')} err={err} fluid />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  regions: resources.regions.list,
  err: resources.regions.err,
})

const searchComp = withSearch(searchRegions)(Regions)

export default connect(mapStateToProps)(withRouter(searchComp))
