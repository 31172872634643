import Api from 'utils/api'
import * as actions from './actions'

export const fetchAccessCodes = (entryUuid) => async (dispatch) => {
  dispatch(actions.fetchAccessCodesRequest())
  try {
    const accessCodes = await Api.request(
      `/installation_points/${entryUuid}/digital_keys`
    )
    dispatch(actions.fetchAccessCodesSuccess(accessCodes))
    return Promise.resolve()
  } catch (err) {
    const { error } = await err.json()
    dispatch(actions.fetchAccessCodesFailure(error))
    return Promise.reject()
  }
}

export const addAccessCode =
  ({ entryUuid, accessCode, expTime }) =>
  async (dispatch) => {
    dispatch(actions.addAccessCodeRequest())
    try {
      const payload = await Api.request(
        `/installation_points/${entryUuid}/digital_keys`,
        {
          method: 'POST',
          body: JSON.stringify({
            value: accessCode,
            exp_time: expTime,
          }),
        }
      )
      dispatch(actions.addAccessCodeSuccess(payload?.message))
      return Promise.resolve()
    } catch (err) {
      const { error } = await err.json()
      dispatch(actions.addAccessCodeFailure(error))
      return Promise.reject()
    }
  }

export const removeAccessCode =
  ({ entryUuid, codeUuid }) =>
  async (dispatch) => {
    dispatch(actions.removeAccessCodeRequest())
    try {
      const payload = await Api.request(
        `/installation_points/${entryUuid}/digital_keys/${codeUuid}`,
        {
          method: 'DELETE',
        }
      )
      dispatch(actions.removeAccessCodeSuccess(payload?.message))
      return Promise.resolve()
    } catch (err) {
      const { error } = await err.json()
      dispatch(actions.removeAccessCodeFailure(error))
      return Promise.reject()
    }
  }
