import * as types from './types'

export const getHousesRequest = () => ({
  type: types.GET_HOUSES_REQUEST,
})
export const getHousesSuccess = (houses) => ({
  type: types.GET_HOUSES_SUCCESS,
  payload: houses,
})
export const getHousesError = (error) => ({
  type: types.GET_HOUSES_ERROR,
  payload: error,
})

export const fetchHouseRequest = () => ({
  type: types.FETCH_HOUSE_REQUEST,
})
export const fetchHouseSuccess = (house) => ({
  type: types.FETCH_HOUSE_SUCCESS,
  payload: { house },
})
export const fetchHouseFailure = (error) => ({
  type: types.FETCH_HOUSE_FAILURE,
  payload: { error },
})
