import React from 'react'
import { IconButton } from '@material-ui/core'

import { ChevronLeft, Close } from '@material-ui/icons'
import styled from 'styled-components'

const Icons = {
  close: Close,
  back: ChevronLeft,
}

const Button = styled(IconButton)`
  && {
    position: ${(p) => (p.absolute ? 'absolute' : 'fixed')};
    z-index: 1000;
    top: 2rem;
    left: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #ced5db;
    transform-origin: 50% 50%;
    transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1);
    color: ${(p) =>
      p.theme.palette[p.color]?.[500] || p.theme.palette.text.secondary};

    & svg {
      width: 2rem;
      height: 2rem;
      transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover {
      transform: scale(1.035);
      background: transparent;

      color: ${(p) =>
        p.theme.palette[p.color]?.[700] || p.theme.palette.text.primary};
    }
  }
`

const FullscreenExitButton = ({
  variant = 'close',
  absolute = false,
  icon,
  children,
  ...props
}) => {
  const Icon = icon || Icons[variant]
  return (
    <Button
      disableRipple
      // style={{ position: 'fixed', top: '1rem', left: '1rem' }}
      // color="inherit"
      // onClick={() => history.push(`${}`)}
      absolute={absolute}
      {...props}
    >
      {children || <Icon />}
    </Button>
  )
}

export default FullscreenExitButton
