import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  FormModal,
  Input,
  Button,
  FormModalTitle,
  FormModalContent,
  FormModalActions,
  ConfirmModal,
  Typography,
} from 'sputnik-ui'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { addThirdPartyCamera } from 'features/Cameras/camerasSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAvailable } from 'features/Devices/installationsSlice'
import { copyToClipboard } from 'utils/helpers'
import { showInfoMessage } from 'store/ui/actions'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object().shape({
  alias: yup.string().max(60).required(),
  rtsp_link: yup.string().required(),
})

export default function AddThirdPartyCameras({ open, handleClose, ...props }) {
  const dispatch = useDispatch()
  const [hex, setHex] = useState('')
  const [confirmModal, setConfirm] = useState(false)
  // const history = useHistory()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      alias: '',
      rtsp_link: '',
      kind: 'camera',
    },
    onSubmit: (val) => {
      dispatch(addThirdPartyCamera(val))
        .then(unwrapResult)
        .then((el) => {
          setConfirm(true)
          setHex(el?.serial_number)
          handleClose()
        })
        .then(() => dispatch(fetchAvailable({ q: '' })))
      // .then(unwrapResult)
      // .catch(handleSubmitConfirmRedirect) //test
      // .then((el) => {
      //   console.log(el)
      //   setHex(el?.uuid)
      //   handleClose()
      //   setConfirm(true)
      // })
    },
  })

  const { t } = useTranslation(['common', 'device', 'info'])

  const handleSubmitConfirm = () => {
    setConfirm(false)
    copyToClipboard(hex)
    dispatch(showInfoMessage(t('info:copy.common')))
  }

  // const handleSubmitConfirmRedirect = () => {
  //   setConfirm(false)
  //   sessionStorage.setItem('third_party_cameraCameraId', '2125129109')
  //   history.push('/add_address')
  // }

  return (
    <>
      <ConfirmModal
        open={confirmModal}
        title={t('devices:cameras.third_party.helper.installation')}
        label={t('devices:helper_text.new_device', { hex })}
        actions={
          <>
            <Button
              primary
              fullWidth
              variant="v2"
              onClick={handleSubmitConfirm}
            >
              {t('common:button.copy')}
            </Button>
          </>
        }
      />
      <FormModal open={open} {...props}>
        <form onSubmit={formik.handleSubmit}>
          <FormModalTitle>
            {t('devices:cameras.third_party.helper.addition')}
          </FormModalTitle>
          <FormModalContent label={t('devices:cameras.third_party.info')}>
            <Input
              required
              name="rtsp_link"
              value={formik.values.rtsp_link}
              label={t('devices:cameras.third_party.rtsp')}
              onChange={formik.handleChange}
              error={formik.errors && formik.errors.rtsp_link}
              fullWidth
              style={{ marginBottom: 24 }}
            />
            <Input
              required
              name="alias"
              value={formik.values.alias}
              onChange={formik.handleChange}
              error={formik.errors && formik.errors.alias}
              label={t('devices:cameras.third_party.alias')}
              fullWidth
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 32,
              }}
            >
              <Typography>
                {t('devices:cameras.third_party.settings')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  marginBottom: 24,
                  marginTop: 16,
                }}
              >
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography color="textSecondary">
                    {t('devices:cameras.third_party.settings_video.resolution')}
                  </Typography>
                  &nbsp;
                  <Typography>1080</Typography>
                </Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography color="textSecondary">
                    {t(
                      'devices:cameras.third_party.settings_video.frames_per_sec'
                    )}
                  </Typography>
                  &nbsp;
                  <Typography>25</Typography>
                </Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography color="textSecondary">
                    {t('devices:cameras.third_party.settings_video.frame_type')}
                  </Typography>
                  &nbsp;
                  <Typography>CBR</Typography>
                </Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography color="textSecondary">
                    {t('devices:cameras.third_party.settings_video.bitrate')}
                  </Typography>
                  &nbsp;
                  <Typography>1024</Typography>
                </Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography color="textSecondary">
                    {t(
                      'devices:cameras.third_party.settings_video.frame_frequency'
                    )}
                  </Typography>
                  &nbsp;
                  <Typography>50</Typography>
                </Box>
              </Box>
              <Typography>
                {t('devices:cameras.third_party.stream_warning')}
              </Typography>
            </Box>
          </FormModalContent>
          <FormModalActions>
            <Button
              variant="v2"
              primary
              fullWidth
              type="submit"
              disabled={
                !(
                  formik.values.alias &&
                  formik.values.rtsp_link &&
                  !formik.errors.rtsp_link &&
                  !formik.errors.alias
                )
              }
            >
              {t('devices:cameras.add')}
            </Button>
            <Button fullWidth variant="v2" tertiary onClick={handleClose}>
              {t('common:button.back')}
            </Button>
          </FormModalActions>
        </form>
      </FormModal>
    </>
  )
}
