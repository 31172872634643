// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DirectionsIcon from '@material-ui/icons/Directions'

import { getEntries } from 'store/operations'
import { selectEntry } from 'store/actions'
import { useTranslation } from 'react-i18next'

const searchEntries = (props, query) => {
  const { selected, dispatch } = props

  const parent_uuid = selected.house.uuid
  const q = query || null

  dispatch(
    getEntries({
      parent_uuid,
      q,
    })
  )
}

const Entries = ({
  entries,
  err,
  dispatch,
  history,
  toggleDrawer,
  ...props
}) => {
  const { t } = useTranslation('geo')
  return (
    <Card
      title={t('geo:locations.entry_plural')}
      titleIcon={<TransferWithinAStationIcon />}
      {...props}
    >
      {entries ? (
        entries.map((entry) => (
          <ListItem
            key={entry.uuid}
            button
            onClick={() => {
              dispatch(selectEntry(entry))
              history.push(`/e/${entry.uuid}`)
            }}
            disableRipple
          >
            <ListItemIcon>
              <DirectionsIcon />
            </ListItemIcon>
            <ListItemText primary={entry.full_name} />
            <ListItemSecondaryAction style={{ paddingRight: '1rem' }}>
              <ArrowForwardIcon />
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.entries')} err={err} />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  entries: resources.entries.list,
  err: resources.entries.err,
})

let toExp = withSearch(searchEntries)(Entries)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
