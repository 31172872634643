import * as types from './types'

/* areas */

export const getAreasRequest = () => ({
  type: types.GET_AREAS_REQUEST,
})
export const getAreasSuccess = (areas) => ({
  type: types.GET_AREAS_SUCCESS,
  payload: areas,
})
export const getAreasError = (error) => ({
  type: types.GET_AREAS_ERROR,
  payload: error,
})

export const fetchAreaRequest = () => ({
  type: types.FETCH_AREA_REQUEST,
})
export const fetchAreaSuccess = (area) => ({
  type: types.FETCH_AREA_SUCCESS,
  payload: { area },
})
export const fetchAreaFailure = (error) => ({
  type: types.FETCH_AREA_FAILURE,
  payload: { error },
})
