import React from 'react'
import { Wrapper, Button } from 'sputnik-ui'
import { List, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Order from 'components/atoms/Order'
import { useTranslation } from 'react-i18next'

const Separator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: calc(50% - 44px);
    height: 1px;
    border-bottom: ${(p) => p.theme.palette.text.secondary} 1px solid;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: calc(50% - 44px);
    height: 1px;

    border-bottom: ${(p) => p.theme.palette.text.secondary} 1px solid;
    right: 0;
  }
`

const Orders = ({ orders, incrementPage, archived, loading, ...props }) => {
  const { t } = useTranslation(['messages', 'common', 'orders'])
  return (
    <Wrapper
      background="primary"
      style={{
        flexDirection: 'column',
        padding: 0,
        overflow: 'hidden',
        position: 'relative',
      }}
      border={false}
      shadow={false}
    >
      {archived && (
        <Separator>
          <Typography color="textSecondary">{t('orders:archive')}</Typography>
        </Separator>
      )}

      <List
        style={{
          padding: 0,
          opacity: archived ? 0.8 : 1,
          position: 'relative',
        }}
      >
        {orders &&
          (orders.length < 1 ? (
            <Typography variant="h6" style={{ margin: '2rem 0' }}>
              {t('messages:content.empty')}
            </Typography>
          ) : (
            orders.map((order) => <Order key={order.id} {...order} />)
          ))}
        {loading ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              background: 'rgba(255,255,255,.9)',
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          />
        ) : (
          <Button
            rounded={false}
            variant="text"
            primary
            onClick={incrementPage}
            fullWidth
          >
            {t('common:button.load_more')}
          </Button>
        )}
      </List>
    </Wrapper>
  )
}

export default Orders
