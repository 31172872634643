import Api from 'utils/api'
import * as actions from './actions'

export const getKeys =
  (uuid, page = 1, limit = 25, isFlat) =>
  async (dispatch) => {
    dispatch(actions.getKeysRequest())
    try {
      const {
        key_mocks,
        meta: { total: keysCount },
      } = await Api.request(
        `/key_mocks/entry_flats_keys?entry_uuid=${uuid}&page=${page}&limit=${limit}`
      )

      dispatch(actions.getEntryKeysSuccess(key_mocks, page === 1, keysCount))
      return Promise.resolve()
    } catch (err) {
      const isUnpaid = err.status === 402
      if (isFlat) {
        dispatch(actions.getFlatKeysError(isUnpaid))
      } else {
        dispatch(actions.getEntryKeysError(isUnpaid))
      }
      return Promise.reject()
    }
  }

export const getFlatKeys =
  (addressUuid, flat_num, flatUuid, page = 1, limit = 1000) =>
  async (dispatch) => {
    dispatch(actions.getKeysRequest())
    try {
      const {
        body: {
          data,
          metadata: {
            meta: { total: keysCount },
          },
        },
      } = await Api.request(`/key_mocks/flat_keys`, {
        method: 'POST',
        body: JSON.stringify({
          flat_num,
          entry_uuid: addressUuid,
          page,
          limit,
        }),
      })

      dispatch(
        actions.getFlatKeysSuccess(data, page === 1, keysCount, flatUuid)
      )

      return Promise.resolve()
    } catch (err) {
      const isUnpaid = err.status === 402
      dispatch(actions.getFlatKeysError(isUnpaid))
      return Promise.reject()
    }
  }

export const removeKey = (parentUuid, hex) => async (dispatch) => {
  dispatch(actions.removeKeyRequest())
  try {
    const payload = await Api.request(
      `/key_mocks/destroy_one?entry_uuid=${parentUuid}&hex=${hex}`,
      { method: 'DELETE' }
    )
    dispatch(actions.removeKeySuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.removeKeyError(message))
    return Promise.reject()
  }
}

export const addKey = (entry, hex, comment) => async (dispatch) => {
  dispatch(actions.addKeyRequest())
  try {
    const payload = await Api.request(`/key_mocks/create_key_for_entry`, {
      method: 'POST',
      body: JSON.stringify({
        address_uuid: entry,
        hexes: [hex],
        comment,
      }),
    })
    dispatch(actions.addKeySuccess(payload?.message))

    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.addKeyError(message))
    return Promise.reject()
  }
}

export const deleteAllKeys = (entry) => async (dispatch) => {
  dispatch(actions.deleteAllKeysRequest())
  try {
    const payload = await Api.request(
      `/key_mocks/destroy_all?entry_uuid=${entry}`,
      {
        method: 'DELETE',
      }
    )
    dispatch(actions.deleteAllKeysSuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.deleteAllKeysError(message))
    return Promise.reject(message)
  }
}

export const syncKeys = (addressUuid) => async (dispatch) => {
  dispatch(actions.syncKeysRequest())
  try {
    const payload = await Api.request(`/key_mocks/synchronize`, {
      method: 'POST',
      body: JSON.stringify({
        entry_uuid: addressUuid,
      }),
    })
    dispatch(actions.syncKeysSuccess(payload))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.syncKeysError(message))
    return Promise.reject(err)
  }
}

export const fetchInvalidKeys = (addressUuid) => async (dispatch) => {
  dispatch(actions.fetchInvalidKeysRequest())
  try {
    const keys = await Api.request(
      `/key_owners/${addressUuid}/invalid_keys?grouped=true`
    )
    dispatch(actions.fetchInvalidKeysSuccess(keys))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchInvalidKeysFailure(message))
    return Promise.reject(err)
  }
}

export const addInvalidKey = (addressUuid, keyId) => async (dispatch) => {
  dispatch(actions.addInvalidKeyRequest())
  try {
    const payload = await Api.request(
      `/key_owners/${addressUuid}/invalid_keys/push_key`,
      {
        method: 'POST',
        body: JSON.stringify({ ids: Array.isArray(keyId) ? keyId : [keyId] }),
      }
    )

    dispatch(actions.addInvalidKeySuccess(payload?.message))
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.addInvalidKeyFailure(message))
    return Promise.reject(err)
  }
}

export const removeInvalidKey = (addressUuid, keyId) => async (dispatch) => {
  dispatch(actions.removeInvalidKeyRequest())
  try {
    await Api.request(
      `/key_owners/${addressUuid}/invalid_keys/${keyId}
`,
      { method: 'DELETE' }
    )
    dispatch(actions.removeInvalidKeySuccess())
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.removeInvalidKeyFailure(message))
    return Promise.reject(err)
  }
}

export const importKeys = (addressUuid, csv) => async (dispatch) => {
  dispatch(actions.importKeysRequest())
  try {
    const formData = new FormData()
    formData.append('file', csv)
    formData.append('address_kind', 'entry')
    formData.append('address_uuid', addressUuid)
    await Api.request(`/key_mocks/import`, {
      method: 'POST',
      body: formData,
      headers: new Headers(),
    })
    dispatch(actions.importKeysSuccess())
    return Promise.resolve()
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.importKeysFailure(message))
    return Promise.reject(err)
  }
}

export const exportKeys = (addressUuid, limit) => async (dispatch) => {
  dispatch(actions.exportKeysRequest())
  try {
    // const csv = await Api.request(
    //   `/key_owners/${addressUuid}/keys/export?limit=${limit}`,
    //   {
    //     method: 'PATCH',
    //   },
    //   undefined,
    //   true
    // )
    // const url = window.URL.createObjectURL(new Blob([csv]))
    // const link = document.createElement('a')
    // link.href = url
    // link.setAttribute('download', 'keys.csv') //or any other extension
    // document.body.appendChild(link)
    // link.click()
    // dispatch(actions.exportKeysSuccess())
    // return Promise.resolve()
    const csv = await Api.request(
      `/key_mocks/export`,
      {
        method: 'POST',
        body: JSON.stringify({ entry_uuid: addressUuid }),
      },
      undefined,
      true
    )
    const url = window.URL.createObjectURL(new Blob([csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'keys.csv') // or any other extension
    document.body.appendChild(link)
    link.click()
    dispatch(actions.exportKeysSuccess())
    return Promise.resolve()
  } catch (err) {
    console.log(err)
    const { message } = await err.json()
    dispatch(actions.exportKeysFailure(message))
    return Promise.reject(err)
  }
}
