import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const HighlightText = styled(Typography)`
  /* opacity: ${(p) => (p.active ? 1 : 0.4)}; */
  /* cursor: ${({ active }) => (active ? 'unset' : 'pointer')}; */
  cursor: pointer;
  transition: all 200ms ease-in;
  color: ${(p) =>
    p.active ? p.theme.palette.blue.main : p.theme.palette.text.primary};
  /* &:hover {
    opacity: 1;
  } */
`

export default HighlightText
