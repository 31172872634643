import Api from 'utils/api'
import * as actions from './actions'

// streets

export const getStreets =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getStreetsRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const streets = await Api.request(`/streets${setParams()}`)

      dispatch(actions.getStreetsSuccess(streets))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getStreetsError(message))
    }
  }

export const fetchStreet = (streetUuid) => async (dispatch) => {
  dispatch(actions.fetchStreetRequest())
  try {
    const area = await Api.request(`/streets/${streetUuid}`)

    dispatch(actions.fetchStreetSuccess(area))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchStreetFailure(message))
  }
}
