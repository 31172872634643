import React from 'react'
import styled from 'styled-components'

const StatusDevicePoint = styled.div`
  width: 8px;
  height: 8px;
  background: ${(p) => (p.online ? '#0fba81' : '#FF3D71')}; //fixme //
  border-radius: 6px;
`

export const StatusDeviceIndicator = ({ online = false, ...props }) => (
  <StatusDevicePoint online={Boolean(online)} {...props} />
)
