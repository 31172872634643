import React, { useState, useCallback, useEffect } from 'react'
import { Button, Select } from 'sputnik-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import { fetchMapIntercoms } from 'store/maps/operations'
import { getIntercomsByAddress } from 'store/operations'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchAvailable, setSearch, linkDevice } from './installationsSlice'
import DeviceListItem from './DeviceListItem'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

export default function AttachNewDeviceModal({
  handleClose,
  open,
  lat,
  lon,
  ...props
}) {
  const [selected, setSelected] = useState()
  const dispatch = useDispatch()
  const { entry, house } = useParams()
  const linkPending = useSelector(
    ({ installationsNew }) => installationsNew.linkPending
  )

  const availableDevices = useSelector(
    ({ installationsNew }) => installationsNew.available
  )
  const search = useSelector(({ installationsNew }) => installationsNew.search)
  const loadingAvailable = useSelector(
    ({ installationsNew }) => installationsNew.loadingAvailable
  )

  const attachIntercom = useCallback(() => {
    const isGate = selected?.type === 'intercom' ? !entry : false
    dispatch(
      linkDevice({
        house_uuid: house,
        entry_uuid: entry,
        identifier: selected?.value,
        gate_lat: lat,
        gate_lon: lon,
        device_type: isGate ? 'gate' : selected.type,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchMapIntercoms(entry || house))
        dispatch(getIntercomsByAddress(entry || house))
        // dispatch(fetchAvailable())
        setSelected(null)
        handleClose()
      })
  }, [dispatch, entry, handleClose, house, lat, lon, selected])

  const { t } = useTranslation(['common', 'devices', 'messages'])

  const [handleSearch] = useDebouncedCallback(
    (q) => dispatch(setSearch(q)),
    150
  )

  useEffect(() => {
    dispatch(fetchAvailable({ q: search }))
  }, [dispatch, search, handleSearch])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ style: { overflow: 'visible', flexBasis: '420px' } }}
    >
      <DialogTitle>{t('devices:actions.install')}</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Select
          handleSelect={setSelected}
          selected={selected}
          placeholder={t('devices:placeholder.search')}
          isMulti={false}
          isLoading={loadingAvailable}
          options={availableDevices}
          onInputChange={handleSearch}
          filterOption={(o) => o}
          notFoundMessage={t('messages:search.empty')}
          // fixme
          // eslint-disable-next-line react/no-unstable-nested-components
          formatOptionLabel={(o) => (
            <DeviceListItem
              {...o}
              secondaryLabel={t(
                `devices:types.${
                  o.type === 'camera' ? 'camera' : entry ? 'intercom' : 'gate'
                }`
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        {!linkPending && (
          <Button onClick={handleClose} secondary rounded>
            {t('common:button.cancel')}
          </Button>
        )}

        <Button
          onClick={attachIntercom}
          rounded
          primary
          variant="contained"
          disabled={linkPending || !selected}
        >
          {linkPending
            ? house
              ? t('common:loaders.gate_connect')
              : t('common:loaders.device_connect')
            : t('common:button.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
