import React from 'react'
import BaseTemplate from 'components/templates/BaseTemplate'
import { AccessDeviceFlat } from 'features/AccessDeviceFlat'

function AccessFlatDevicePage({ children, ...props }) {
  return (
    <BaseTemplate menu>
      <AccessDeviceFlat />
    </BaseTemplate>
  )
}

export default AccessFlatDevicePage
