// @flow
import * as types from './types'

export const syncInstallationPointRequest = () => ({
  type: types.SYNC_INSTALLATION_POINT_REQUEST,
})
export const syncInstallationPointSuccess = (msg) => ({
  type: types.SYNC_INSTALLATION_POINT_SUCCESS,
  payload: { msg },
})
export const syncInstallationPointError = (error) => ({
  type: types.SYNC_INSTALLATION_POINT_ERROR,
  payload: { error },
})

export const closeCollectingKeysRequest = () => ({
  type: types.CLOSE_COLLECTING_KEYS_REQUEST,
})
export const closeCollectingKeysSuccess = (msg) => ({
  type: types.CLOSE_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const closeCollectingKeysError = (error) => ({
  type: types.CLOSE_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const openCollectingKeysRequest = () => ({
  type: types.OPEN_COLLECTING_KEYS_REQUEST,
})
export const openCollectingKeysSuccess = (msg) => ({
  type: types.OPEN_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const openCollectingKeysError = (error) => ({
  type: types.OPEN_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const prolongCollectingKeysRequest = () => ({
  type: types.PROLONG_COLLECTING_KEYS_REQUEST,
})
export const prolongCollectingKeysSuccess = (msg) => ({
  type: types.PROLONG_COLLECTING_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const prolongCollectingKeysError = (error) => ({
  type: types.PROLONG_COLLECTING_KEYS_ERROR,
  payload: {
    error,
  },
})

export const searchInstallationPointRequest = () => ({
  type: types.SEARCH_INSTALLATION_POINT_REQUEST,
})
export const searchInstallationPointSuccess = () => ({
  type: types.SEARCH_INSTALLATION_POINT_SUCCESS,
})
export const searchInstallationPointError = (error) => ({
  type: types.SEARCH_INSTALLATION_POINT_ERROR,
  payload: {
    error,
  },
})

export const generateQrRequest = () => ({
  type: types.GENERATE_QR_REQUEST,
})
export const generateQrSuccess = () => ({
  type: types.GENERATE_QR_SUCCESS,
})
export const generateQrError = (error) => ({
  type: types.GENERATE_QR_ERROR,
  payload: {
    error,
  },
})

export const invalidateQrRequest = () => ({
  type: types.INVALIDATE_QR_REQUEST,
})
export const invalidateQrSuccess = (msg) => ({
  type: types.INVALIDATE_QR_SUCCESS,
  payload: {
    msg,
  },
})
export const invalidateQrError = (error) => ({
  type: types.INVALIDATE_QR_ERROR,
  payload: {
    error,
  },
})

export const fetchInstallationPointsRequest = () => ({
  type: types.FETCH_INSTALLATION_POINTS_REQUEST,
})
export const fetchInstallationPointsSuccess = (installationPoints) => ({
  type: types.FETCH_INSTALLATION_POINTS_SUCCESS,
  payload: {
    installationPoints,
  },
})
export const fetchInstallationPointsFailure = (error) => ({
  type: types.FETCH_INSTALLATION_POINTS_FAILURE,
  payload: {
    error,
  },
})
