import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export default function Collapse({
  open = true,
  style = {},
  children,
  toHeight,
  ...props
}) {
  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { height: toHeight || 'auto' },
            collapsed: { height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          style={{ overflow: 'hidden', ...style }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
