import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import { Card, withSearch } from 'components'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import { useTranslation } from 'react-i18next'

import { getLocalities } from 'store/operations'
import { selectLocality } from 'store/actions'

const searchLocalities = (props, query) => {
  const { selectedCountry, selectedRegion, selectedArea, dispatch } = props

  const country_uuid = (selectedCountry && selectedCountry.uuid) || null
  const region_uuid = (selectedRegion && selectedRegion.uuid) || null
  const area_uuid = (selectedArea && selectedArea.uuid) || null
  const q = query || null

  dispatch(
    getLocalities({
      area_uuid,
      country_uuid,
      region_uuid,
      q,
    })
  )
}

const Localities = ({
  localities,
  err,
  dispatch,
  history,
  match,
  ...props
}) => {
  const { t } = useTranslation('geo')

  return (
    <Card
      title={t('geo:locations.city_plural')}
      titleIcon={<LocationCityIcon />}
      {...props}
    >
      {localities ? (
        localities.map((l) => (
          <ListItem
            key={l.uuid}
            button
            onClick={() => {
              dispatch(selectLocality(l))
              history.push(`/l/${l.uuid}`)
            }}
          >
            <ListItemIcon>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText primary={l.full_name} />
            <ListItemSecondaryAction style={{ marginRight: '1rem' }}>
              <ArrowForwardIcon />
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Loader msg={t('geo:loading.cities')} err={err} fluid />
      )}
    </Card>
  )
}

const mapStateToProps = ({ resources }) => ({
  localities: resources.localities.list,
  err: resources.localities.err,
})

let toExp = withSearch(searchLocalities)(Localities)
toExp = withRouter(toExp)

export default connect(mapStateToProps)(toExp)
