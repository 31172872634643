import React from 'react'
import { Button } from 'sputnik-ui'
import { PictureAsPdf } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

// const schemes = {
//   0: MetakomScheme,
//   1: VizitScheme,
//   2: MetakomScheme,
// }

const getUrls = (commutator) => {
  switch (commutator) {
    case 1:
      return [{ value: 'vizit.pdf', label: 'Визит' }]
    case 2:
      return [{ value: 'cyfral.pdf', label: 'Цифрал' }]
    case 3:
      return [{ value: 'eltis.pdf', label: 'Элтис' }]
    default:
      return [{ value: 'metakom.pdf', label: 'Метаком' }]
  }
}

const CommutatorScheme = ({ commutator, ...props }) => {
  const urls = getUrls(commutator)
  // return schemes.map(Scheme => <Scheme {...props} />)

  const { t } = useTranslation('devices')

  return urls.map((com) => (
    <Button
      component="a"
      variant="text"
      target="_blank"
      href={`/schemes/${com.value}`}
      primary
      rounded
      style={{
        textTransform: 'none',
        fontSize: '1rem',
      }}
      key={com.value}
      {...props}
    >
      <PictureAsPdf style={{ marginRight: 8 }} />
      {/* «{com.label}» */}
      {t('devices:helper_text.com_connection_scheme', {
        com: t('devices:params.com_interval', {
          count: commutator,
          postProcess: 'interval',
        }),
      })}
    </Button>
  ))
}

export default CommutatorScheme
