import Api from 'utils/api'
import * as actions from './actions'

// areas

export const getAreas =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getAreasRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const areas = await Api.request(`/areas${setParams()}`)

      dispatch(actions.getAreasSuccess(areas))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getAreasError(message))
    }
  }

export const fetchArea = (areaUuid) => async (dispatch) => {
  dispatch(actions.fetchAreaRequest())
  try {
    const area = await Api.request(`/areas/${areaUuid}`)

    dispatch(actions.fetchAreaSuccess(area))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchAreaFailure(message))
  }
}
