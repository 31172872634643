import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Wrapper, Loader } from 'sputnik-ui'
import { Dialpad, AddCircle } from '@material-ui/icons'
import {
  Typography,
  CardHeader,
  List,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import FoldButton from 'components/atoms/FoldButton'
import AccessCode from 'components/atoms/AccessCode'
import AddAccessCodeModal from 'components/molecules/AddAccessCodeModal'
import { fetchAccessCodes } from 'store/codes/operations'
import { useTranslation } from 'react-i18next'
import { Card } from 'shared/ui'

const ListWrapper = styled.div`
  /* transition: max-height 300ms ease-in; */
  max-height: 0rem;
  padding: 0;
  overflow-y: scroll;
  &:last-child {
    padding-bottom: 0;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(192, 192, 192);
  }

  ${(p) =>
    p.isExpanded &&
    css`
      padding: 16px 0 !important;
      max-height: 26rem !important;
    `}
`

const StyledHeader = styled(CardHeader)`
  background-color: inherit;
  color: inherit;
  position: sticky;
  z-index: 2;
  top: 0;
  height: 80;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  background-color: ${(p) => p.theme.palette.background.primary};
  color: ${(p) => p.theme.palette.text.primary};
`

export default function AccessCodes() {
  const [isExpanded, setIsExpanded] = useState(false)
  const { entry: entryUuid } = useParams()

  const [modals, setModals] = useState({
    addAccessCode: false,
  })
  const toggleAddAccessCodeModal = useCallback(
    () =>
      setModals((prevModals) => ({
        ...prevModals,
        addAccessCode: !prevModals.addAccessCode,
      })),
    [setModals]
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAccessCodes(entryUuid))
  }, [dispatch, entryUuid])
  const accessCodes = useSelector(({ codes }) => codes.list)

  const { t } = useTranslation(['keys', 'common'])

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Card
        style={{
          padding: 0,
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <StyledHeader
          avatar={<Dialpad />}
          title={
            <Typography
              color="inherit"
              style={{
                fontSize: '1.2rem',
              }}
            >
              {t('keys:units.access_code_plural')}
            </Typography>
          }
          action={
            <div
              style={{
                marginTop: 8,
              }}
            >
              <Tooltip
                placement="top"
                title={t('keys:actions.add_access_code')}
              >
                <IconButton
                  color="inherit"
                  disableRipple
                  onClick={toggleAddAccessCodeModal}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </div>
          }
        />
        <ListWrapper isExpanded={isExpanded}>
          <List>
            {accessCodes ? (
              accessCodes.map(({ exp_time, value, uuid }) => (
                <AccessCode key={uuid} expTime={exp_time} uuid={uuid}>
                  {value}
                </AccessCode>
              ))
            ) : (
              <Loader fluid />
            )}
          </List>
        </ListWrapper>
      </Card>
      <Tooltip
        placement="bottom"
        title={isExpanded ? t('common:button.fold') : t('common:button.unfold')}
      >
        <FoldButton
          onClick={() => setIsExpanded(!isExpanded)}
          disableRipple
          isExpanded={isExpanded}
        />
      </Tooltip>
      <AddAccessCodeModal
        open={modals.addAccessCode}
        handleClose={toggleAddAccessCodeModal}
      />
    </div>
  )
}
