import React, { useState, useCallback, useEffect } from 'react'
import {
  Button,
  FullscreenExitButton,
  FullscreenDialogTitle,
  Select,
  Typography,
  ConfirmModal,
  Input,
} from 'sputnik-ui'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import FormField from 'components/atoms/FormField'
import { Formik, Form } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'

import { Help } from '@material-ui/icons'
import {
  Fade,
  DialogActions,
  DialogContent,
  Dialog,
  Tooltip,
} from '@material-ui/core'

import { getIntercomsByIdentifier } from 'store/devices/operations'
import { useTranslation } from 'react-i18next'
import { fetchInstallationPoints } from 'store/installations/operations'
import { showInfoMessage } from 'store/ui/actions'
import debounce from 'lodash/debounce'
import HighlightText from 'components/atoms/HighlightText'

import { useDebouncedCallback } from 'use-debounce'
import DeviceListItem from 'features/Devices/DeviceListItem'
import {
  fetchAvailable,
  setSearch,
  manualGateDevice,
  linkDevice,
} from 'features/Devices/installationsSlice'
import i18next from 'i18next'
import GateForm from './GateForm'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Pin } from './pin.svg'

function Transition(props) {
  return <Fade direction="up" {...props} />
}

const MAX = 1000
const MIN = -1000

const validationSchema = Yup.object().shape({
  entry: Yup.number().max(
    100,
    i18next.t('common:field.error.max_entry', { number: 100 })
  ),
  ext_com: Yup.number().min(0).max(3),
  first_flat: Yup.number()
    .min(1, i18next.t('common:field.error.greater_or_equal', { number: 1 }))
    .max(
      1000,
      i18next.t('common:field.error.greater_or_equal', { number: 1000 })
    ),
  last_flat: Yup.number()
    .min(Yup.ref('first_flat'), i18next.t('common:field.error.invalid_range'))
    .max(
      1000,
      i18next.t('common:field.error.greater_or_equal', { number: 1000 })
    ),
  flat_offset: Yup.number()
    .min(
      -1000,
      i18next.t('common:field.error.greater_or_equal', { number: -1000 })
    )
    .max(1000, i18next.t('common:field.error.less_or_equal', { number: 1000 })),

  country: Yup.string()
    .trim()
    .min(2, i18next.t('common:field.error.min_count', { number: 2 })),
  region: Yup.string()
    .trim()
    .min(2, i18next.t('common:field.error.min_count', { number: 2 })),
  locality: Yup.string()
    .trim()
    .min(2, i18next.t('common:field.error.min_count', { number: 2 })),
  street: Yup.string()
    .trim()
    .min(2, i18next.t('common:field.error.min_count', { number: 2 })),
  house: Yup.string()
    .trim()
    .min(1, i18next.t('common:field.error.min_house_num', { number: 1 })),
  house_addition: Yup.number().min(1),

  comment: Yup.string().trim().min(2, 'test'), // todo
  cameraComment: Yup.string().trim().min(2, 'test'), // todo
})

function AddAddressPage(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation(['devices', 'geo', 'common', 'info', 'messages'])
  const [state, setState] = useState({
    identifier: '',
    ext_com: 0,
  })
  // redirect actions
  const [redirectModal, setRedirectModal] = useState(false)
  const [responseAddress, setResponseAddress] = useState(null)
  ///
  const [touchGate, setTouchGate] = useState(false)
  const [gatePos, setGatePos] = useState({ gate_lat: 0, gate_lon: 0 })

  const manualArr = [
    {
      label: t('devices:manual.country'),
      dataId: 'country',
      req: true,
    },
    {
      label: t('devices:manual.region'),
      dataId: 'region',
      req: false,
    },
    {
      label: t('devices:manual.locality'),
      dataId: 'locality',
      req: true,
    },
    {
      label: t('devices:manual.street'),
      dataId: 'street',
      req: true,
    },
    {
      label: t('devices:manual.house'),
      dataId: 'house',
      req: true,
    },
  ]

  const commutatorOptions = [
    {
      value: 0,
      label: t('devices:params.com_interval', {
        count: 0,
        postProcess: 'interval',
      }),
    },
    {
      value: 1,
      label: t('devices:params.com_interval', {
        count: 1,
        postProcess: 'interval',
      }),
    },
    {
      value: 2,
      label: t('devices:params.com_interval', {
        count: 2,
        postProcess: 'interval',
      }),
    },
    {
      value: 3,
      label: t('devices:params.com_interval', {
        count: 3,
        postProcess: 'interval',
      }),
    },
  ]

  const handleChange = useCallback(
    (name) => (e) => {
      setState({
        ...state,
        [name]: e.target.value,
      })
    },
    [state]
  )

  const [deviceType, setDeviceType] = useState('intercom')
  const [enterManually, setEnterManually] = useState(false)

  const createOption = React.useCallback(
    (label, value, other = {}) => ({
      label,
      value,
      ...other,
    }),
    []
  )

  // fixme
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchInstallationPoints = React.useCallback(
    // fixme
    // eslint-disable-next-line consistent-return
    debounce((q, cb) => {
      if (!q || q.length < 3) return cb([])
      dispatch(fetchInstallationPoints(q)).then((installationPoints) => {
        const options = installationPoints.map((o) =>
          createOption(o.full_address, o.full_address, {
            country: o.country,
            region: o.region,
            locality: o.locality,
            street: o.street,
            house: o.house,
            lat: o.lat,
            lon: o.lon,
          })
        )
        cb(options)
      })
    }, 350),
    []
  )

  const linkPending = useSelector(
    ({ installationsNew }) => installationsNew.linkPending
  )
  const availableDevices = useSelector(
    ({ installationsNew }) => installationsNew.available
  )

  const search = useSelector(({ installationsNew }) => installationsNew.search)
  const loadingAvailable = useSelector(
    ({ installationsNew }) => installationsNew.loadingAvailable
  )
  const [handleSearch] = useDebouncedCallback(
    (q) => dispatch(setSearch(q)),
    400
  )

  useEffect(() => {
    dispatch(fetchAvailable({ q: search }))
  }, [dispatch, search, handleSearch])

  const redirectToNextPage = useCallback(() => {
    const { address_type, address_uuid } = responseAddress
    history.push(
      address_type === 'gate' || address_type === 'house'
        ? `/h/${address_uuid}`
        : `/e/${address_uuid}`
    )
  }, [history, responseAddress])

  const handleSubmit = useCallback(
    (values) => {
      const { ext_com } = state
      const {
        country,
        house,
        locality,
        region,
        street,
        comment,
        cameraComment,
      } = values
      let params = {
        country,
        house,
        locality,
        region,
        street,
        ext_com,
        comment,
        camera_comment: cameraComment,
        identifier: state.identifier?.value,
        device_type:
          state.identifier?.type === 'camera' ? 'camera' : deviceType,
        ...gatePos,
      }

      if (deviceType === 'intercom') {
        params = { ...values, ...params }
      }

      if (values?.isManualForm) {
        params = { isManualForm: values.isManualForm, ...params }
      }

      dispatch(linkDevice(params))
        .then(unwrapResult)
        .then(({ address_uuid, address_type, ...kek }) => {
          setResponseAddress({ address_uuid, address_type })
          redirectToNextPage()
        })
    },
    [deviceType, dispatch, gatePos, redirectToNextPage, state]
  )

  // const redirectToDeviceSettings = () => {
  //   const { address_uuid } = responseAddress
  //   history.push(`device_zone/${address_uuid}`)
  // }

  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.handleClose}
        scroll="paper"
        fullScreen
        transitionDuration={0}
      >
        <FullscreenExitButton
          onClick={() => {
            props.handleClose()
            setEnterManually(false)
          }}
        />
        <FullscreenDialogTitle
          subtitle={
            <Typography
              color="textSecondary"
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {t('devices:helper_text.install_simple')}
            </Typography>
          }
        >
          <Typography
            style={{
              fontFamily: 'Play', // fix me
            }}
            variant="headline"
          >
            {t('devices:actions.install')}
          </Typography>
        </FullscreenDialogTitle>
        <Formik
          initialValues={state}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setValues }) => (
            <Form style={styles.form}>
              <DialogContent style={styles.content}>
                <div
                  style={{
                    width: '100%',
                    flexDirection: 'column',
                    marginTop: '19px',
                  }}
                >
                  <Select
                    style={{ width: '100%' }}
                    label={t('devices:units.device', { count: 1 })}
                    variant="outlined"
                    handleSelect={(o) =>
                      setState({
                        ...state,
                        identifier: o,
                      })
                    }
                    selected={state.identifier}
                    placeholder={t('devices:placeholder.search')}
                    isMulti={false}
                    isLoading={loadingAvailable}
                    options={availableDevices}
                    onInputChange={handleSearch}
                    notFoundMessage={t('messages:search.empty')}
                    // fixme
                    // eslint-disable-next-line react/no-unstable-nested-components
                    formatOptionLabel={(o) => (
                      <DeviceListItem
                        {...o}
                        secondaryLabel={t(
                          `devices:types.${
                            o.type === 'camera' ? 'camera' : deviceType
                          }`
                        )}
                      />
                    )}
                    filterOption={(o) => o}
                  />
                </div>
                {state.identifier?.type !== 'camera' && (
                  <Select
                    style={{ marginTop: '19px' }}
                    label={t('devices:params.com_type')}
                    variant="outlined"
                    isMulti={false}
                    handleSelect={(o) =>
                      setState({
                        ...state,
                        ext_com: o.value,
                      })
                    }
                    options={commutatorOptions}
                    defaultValue={commutatorOptions[0]}
                  />
                )}

                {enterManually ? (
                  <>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: ' repeat(3, 1fr)',
                        gridColumnGap: '0.8rem',
                        marginTop: '19px',
                      }}
                    >
                      {manualArr.map((el) => (
                        <FormField
                          style={{ marginBottom: '0.8rem' }}
                          key={el.dataId}
                          fullWidth
                          required={el?.req}
                          label={el.label}
                          margin="dense"
                          name={el.dataId}
                          helperText={
                            el.dataId === 'house'
                              ? t('devices:helper_text.building_available')
                              : null
                          }
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'column',
                        marginTop: '19px',
                      }}
                    >
                      <Select
                        label={t('geo:locations.address_house')}
                        variant="outlined"
                        async
                        loadOptions={handleFetchInstallationPoints}
                        isOptionDisabled={(option) => option.isDisabled}
                        handleSelect={(o) => {
                          setGatePos({ gate_lat: o.lat, gate_lon: o.lon })
                          setValues({ ...o })
                        }}
                        placeholder={t('geo:placeholder.address')}
                        isMulti={false}
                        style={{ width: '100%', marginTop: '19px' }}
                        notFoundMessage={t('messages:search.empty')}
                      />

                      {/* <Input
                        fullWidth
                        name="cameraComment"
                        value={values.cameraComment}
                        style={{ marginTop: '19px' }}
                        label="Текст, который будет отображаться в приложении жителя поверх изображения с камеры"
                      />

                      <Input
                        fullWidth
                        name="comment"
                        value={values.comment}
                        label="Заметка"
                        style={{ marginBottom: 14, marginTop: '19px' }}
                      />
                      <Typography variant="note" color='textSecondary'>
                        Обязательно оставьте для себя понятную и подробную заметку, для того, чтобы
                        не потерять камеру.
                      </Typography> */}
                    </div>

                    <Typography
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '25px 10px',
                        color: '#0EA7FF',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setEnterManually(true)
                        setGatePos({ gate_lat: 0, gate_lon: 0 })
                        setValues({
                          ...values,
                          isManualForm: true,
                          lat: undefined,
                          lon: undefined,
                        })
                      }}
                    >
                      <Pin style={{ marginRight: 10, height: 24, width: 24 }} />
                      {t('devices:manual.enter_manual')}
                    </Typography>
                  </>
                )}

                {state.identifier.type === 'camera' && (
                  <div style={{ marginTop: 19 }}>
                    <FormField
                      fullWidth
                      label={t('geo:placeholder.entry')}
                      type="number"
                      margin="dense"
                      name="entry"
                    />

                    <div style={styles.flatRangeWrapper}>
                      <FormField
                        style={styles.input}
                        labelProps={{
                          style: { marginTop: '19px' },
                        }}
                        type="number"
                        inputProps={{
                          min: MIN,
                          step: 1,
                          max: MAX - 1,
                        }}
                        label={t('devices:placeholder.first_flat')}
                        name="first_flat"
                      />

                      <FormField
                        style={styles.input}
                        labelProps={{
                          style: { marginTop: '19px' },
                        }}
                        type="number"
                        label={t('devices:placeholder.last_flat')}
                        inputProps={{
                          min: MIN + 1,
                          step: 1,
                          max: MAX,
                        }}
                        name="last_flat"
                      />
                    </div>
                  </div>
                )}

                {state.identifier?.type === 'intercom' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'grid',
                        gap: '1.625rem',
                        gridTemplateColumns:
                          'min-content min-content  min-content',
                        margin: '34px 0',
                        justifyContent: 'center',
                      }}
                    >
                      <HighlightText
                        onClick={() => setDeviceType('intercom')}
                        active={
                          state.identifier?.type === 'intercom' &&
                          deviceType === 'intercom'
                        }
                      >
                        {t('devices:units.intercom')}
                      </HighlightText>
                      <HighlightText
                        active={
                          state.identifier?.type === 'intercom' &&
                          deviceType === 'gate'
                        }
                        onClick={() => setDeviceType('gate')}
                      >
                        {t('devices:units.gate')}
                      </HighlightText>
                    </div>
                    {deviceType === 'intercom' && (
                      <>
                        <FormField
                          fullWidth
                          label={t('geo:placeholder.entry')}
                          type="number"
                          margin="dense"
                          name="entry"
                        />

                        <div
                          style={{
                            display: 'grid',
                            gridGap: '1rem',
                            gridTemplateColumns:
                              'repeat(auto-fit, minmax(200px, 1fr))',
                            justifyContent: 'start',
                            width: '100%',
                          }}
                        >
                          <div style={styles.flatRangeWrapper}>
                            <FormField
                              style={styles.input}
                              type="number"
                              inputProps={{
                                min: MIN,
                                step: 1,
                                max: MAX - 1,
                              }}
                              label={t('devices:placeholder.first_flat')}
                              name="first_flat"
                            />

                            <FormField
                              style={styles.input}
                              type="number"
                              label={t('devices:placeholder.last_flat')}
                              inputProps={{
                                min: MIN + 1,
                                step: 1,
                                max: MAX,
                              }}
                              name="last_flat"
                            />
                          </div>
                          <FormField
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {t('devices:params.flat_offset')}
                                <Tooltip title={t('common:learn_more')}>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.sputnik.systems/ru/articles/3637638-%D1%81%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B4%D0%B8%D0%B0%D0%BF%D0%B0%D0%B7%D0%BE%D0%BD%D0%B0"
                                  >
                                    <Info
                                      color="primary"
                                      style={{
                                        marginLeft: '.325rem',
                                        cursor: 'pointer',
                                        fontSize: 'inherit',
                                        height: 20,
                                        width: 20,
                                      }}
                                    />
                                  </a>
                                </Tooltip>
                              </div>
                            }
                            style={{ width: '100%' }}
                            type="number"
                            name="flat_offset"
                          />
                        </div>
                      </>
                    )}
                    {deviceType === 'gate' && (
                      <GateForm
                        position={{ lat: values.lat, lon: values.lon }}
                        setGatePos={(data) => setGatePos({ ...data })}
                        isManual={values?.isManualForm || false}
                        touch={() => {
                          const arr = [
                            values?.country || '',
                            values?.region || '',
                            values?.locality || '',
                            values?.street || '',
                            values?.house || '',
                            values?.house_addition || '',
                          ]
                          dispatch(
                            manualGateDevice({
                              address: arr.join(' '),
                              lookup: 'yandex',
                            })
                          ).then((d) => {
                            if (d?.payload[0]) {
                              const { lat, lon } = d.payload[0]
                              setGatePos({ gate_lat: lat, gate_lon: lon })
                              setValues({ ...values, lat, lon })
                              setTouchGate(true)
                            } else {
                              dispatch(
                                showInfoMessage('info:alert.address_not_found')
                              )
                            }
                          })
                        }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: 'center',
                  margin: '1.325rem',
                  marginBottom: '100px',
                  width: '100%',
                }}
              >
                <Button
                  style={{ fontFamily: 'Open Sans' }} // fix me
                  size="fullScreen"
                  variant="v2"
                  primary
                  rounded
                  type="submit"
                  disabled={
                    !values.country ||
                    !values.locality ||
                    !values.street ||
                    !values.house ||
                    !state.identifier?.value ||
                    !isEmpty(errors) ||
                    (state.identifier?.type !== 'camera' &&
                      deviceType === 'intercom' &&
                      !values.entry) ||
                    linkPending ||
                    (values.isManualForm && deviceType === 'gate'
                      ? !(values.isManualForm && touchGate)
                      : false)
                  }
                >
                  {!linkPending &&
                    (state.identifier?.type === 'camera'
                      ? t('devices:cameras.install')
                      : deviceType === 'gate'
                      ? t('devices:gates.install')
                      : t('devices:intercoms.install'))}
                  {linkPending &&
                    `${t('devices:actions.connect_device.connecting')} ${
                      state.identifier?.type === 'camera'
                        ? t('devices:actions.connect_device.camera')
                        : deviceType === 'gate'
                        ? t('devices:actions.connect_device.gate')
                        : t('devices:actions.connect_device.intercom')
                    }...`}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      {/* <ConfirmModal
        icon
        title="Привязать камеру к другим адресам?"
        // open={false}
        open={redirectModal}
        label={
          'Жители других подъездов также получат доступ к камере в мобильном приложении. На следующем шаге вы сможете гибко настроить права доступа'
        }
        actions={
          <>
            <Button variant="v2" primary fullWidth onClick={redirectToDeviceSettings}>
              Привязать
            </Button>
            <Button variant="v2" tertiary onClick={redirectToNextPage}>
              Позже
            </Button>
          </>
        }
      /> */}
    </>
  )
}

const isEmpty = (obj) => Object.keys(obj).length === 0

const styles = {
  suggestionIcon: { marginRight: 8, fill: 'currentColor' },
  content: {
    overflow: 'visible',
    maxWidth: 1275, // old 768
    margin: '0 auto auto',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
  },
  submitBtn: { marginLeft: 'auto', marginTop: 16 },
  flatRangeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  flatRangeInput: {
    textAlign: 'center',
    minWidth: '100px',
  },
}

const mapStateToProps = ({ devices }) => ({
  error: devices.error,
  deviceType: devices.deviceType,
  unlinkedDevices: devices.unlinked,
})

export default connect(mapStateToProps)(AddAddressPage)
