export default (theme) => ({
  summary: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.primary['500'],
  },
  heading: {
    color: theme.palette.secondary.contrastText,
  },
  card: {
    // boxShadow: '0 4px 12px 0.5px rgba(0,0,0,0.08)',
    '&:hover': {
      '.card-header': {
        backgroundColor: theme.palette.primary['500'],
      },
    },
    paddingBottom: '1rem',
    overflow: 'hidden',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.palette.secondary.contrastText,
    textAlign: 'left',
  },
  searchHeader: {
    height: 80,
    color: theme.palette.primary.contrastText,
  },
  cardAction: {
    marginTop: 0,
    marginRight: -8,
  },
  avatar: {
    // position: 'absolute',
  },
  searchAction: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 8,
  },
  cardContent: {
    overflowY: 'scroll',
    maxHeight: 'calc(100% - 1.625rem * 3)',
    paddingLeft: 0,
    paddingRight: 0,
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(192, 192, 192)',
    },
  },
  list: {
    padding: 0,
  },
  listItem: {},
  listAction: {
    // marginRight: '1rem',
  },
})
