import Api from 'utils/api'
import { showInfoMessage } from 'store/ui/actions'
import * as actions from './actions'

export const getEntries =
  ({ parentUuid, q }) =>
  async (dispatch) => {
    dispatch(actions.getEntriesRequest())
    try {
      const setParams = () => {
        let params = []

        if (parentUuid) params.push(`parent_uuid=${parentUuid}`)
        if (q) params.push(`q=${q}`)

        params = params.join('&')

        return params.length > 0 ? `?${params}` : params
      }

      const entries = await Api.request(`/entries${setParams()}`)

      dispatch(actions.getEntriesSuccess(entries))
    } catch (err) {
      const { message } = await err.json()
      dispatch(actions.getEntriesError(message))
    }
  }

export const fetchEntry = (uuid) => async (dispatch) => {
  dispatch(actions.fetchEntryRequest())
  try {
    const entry = await Api.request(`/entries/${uuid}`)
    dispatch(actions.fetchEntrySuccess(entry))
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.fetchEntryFailure(message))
  }
}

export const saveEntryNotice =
  ({ entry, value }) =>
  async (dispatch) => {
    try {
      const res = await Api.request(`/entries/${entry}`, {
        body: JSON.stringify({
          notice: value,
        }),
        method: 'PUT',
      })
      dispatch(showInfoMessage(res?.message))
    } catch (err) {
      const { error } = await err.json()
      dispatch(showInfoMessage(error))
    }
  }
