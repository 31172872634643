import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useInput } from 'sputnik-ui'
import debounce from 'lodash/debounce'
import { fetchOrders } from 'store/orders/operations'
import { clearOrders } from 'store/orders/actions'

export default function useOrders() {
  const dispatch = useDispatch()

  const { orders, ordersCount, loading } = useSelector(({ orders }) => ({
    orders: orders.list,
    ordersCount: orders.count,
    loading: orders.loading,
  }))

  // sort
  const [sort, setSort] = useState({
    by: 'created_at',
    order: 'desc',
  })
  const handleSort = useCallback((by) => {
    setSort((prevSort) => ({
      by,
      order:
        by === prevSort.by
          ? prevSort.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'desc',
    }))
  }, [])

  // filter
  const [filters, setFilters] = useState({
    type: null,
    urgent: null,
  })
  const handleFilter = useCallback((newFilter) => {
    setFilters((filters) => ({
      ...filters,
      ...newFilter,
      type:
        filters.type === newFilter.type ? null : newFilter.type || filters.type,
    }))
  }, [])

  const [page, setPage] = useState(1)
  // const handleScroll = useCallback(
  //   e => {
  //     if (e) {
  //       const bottom =
  //         e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
  //       if (bottom && !loading) {
  //         setPage(page + 1)
  //       }
  //     }
  //   },
  //   [loading, page],
  // )
  const incrementPage = useCallback(() => setPage(page + 1), [page])

  const { value: query, bind } = useInput('')
  useEffect(() => {
    setPage(1)
  }, [query, filters, sort])
  // fixme key feature
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetch = useCallback(
    debounce(({ filters, page, query, sort }) => {
      dispatch(
        fetchOrders({
          filters,
          page,
          limit: 50,
          query,
          sortBy: sort.by,
          sortOrder: sort.order,
        })
      )
    }, 200),
    [dispatch]
  )
  useEffect(() => {
    handleFetch({ filters, page, query, sort })
  }, [handleFetch, page, query, filters, sort])

  useEffect(() => () => dispatch(clearOrders()), [dispatch])

  return {
    bind,

    orders,
    ordersCount,
    loading,
    handleSort,
    handleFilter,
    filters,
    sort,
    incrementPage,
  }
}
