import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from 'sputnik-ui'
import styled from 'styled-components'
import useWarrantyInputsData from './useWarrantyInputsData'
import WarrantyItem from './WarrantyItem'

const Wrapper = styled.div`
  width: 100%;
  min-height: 640px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 2rem;
`

const Column = styled.div`
  border-top: 4px solid;
  border-right: 1px dashed;
`

const ColumnHead = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  //, 0px 12px 24px rgba(0, 0, 0, 0.06)
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  /* overflow: hidden; */
  /* &:nth-last-child(0) {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  } */
`

const ColumnContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`

const assignment = (list, statuses) => {
  let data = JSON.parse(JSON.stringify(statuses))
  // trash wtf
  const sorter = (localId, el) => {
    const newColumns = data.map((obj) => {
      if (obj.localId === localId) {
        obj.data = [...obj.data, el]
      }
      return obj
    })
    data = newColumns
  }

  list.forEach((el) => {
    switch (el.status) {
      case 'review':
        return sorter('review', el)
      case 'repaired':
        return sorter('repaired', el)
      case 'refusal':
        return sorter('refusal', el)

      case 'repair':
        return sorter('repair', el)
      case 'taken_in_repair':
        return sorter('repair', el)

      default:
        return sorter('review', el)
    }
  })

  return data
}

function WarrantyContainer() {
  const [columns, setColumns] = useState([])
  const { statuses } = useWarrantyInputsData()
  const list = useSelector(({ warranty }) => warranty.list)
  const pending = useSelector(({ warranty }) => warranty.pending)

  useEffect(() => {
    list && setColumns(assignment(list, statuses))
  }, [list, statuses])

  if (pending) return <Loader fullWidth />
  return (
    <Wrapper>
      {columns.map(({ color, data, label, localId }) => (
        <Column key={localId} style={{ borderColor: color }}>
          <ColumnHead>{label}</ColumnHead>
          <ColumnContent>
            {data.map((item) => {
              const {
                number,
                status,
                created_at,
                updated_at,
                device_number,
                locale_status,
                surname,
                name,
                paid,
              } = item

              return (
                <WarrantyItem
                  paid={paid}
                  key={number}
                  color={color}
                  number={number}
                  status={status}
                  created_at={created_at}
                  updated_at={updated_at}
                  device_number={device_number}
                  locale_status={locale_status}
                  surname={surname}
                  name={name}
                />
              )
            })}
          </ColumnContent>
        </Column>
      ))}
    </Wrapper>
  )
}

export default WarrantyContainer
