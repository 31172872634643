import React from 'react'
import { Field } from 'formik'
import { TextArea } from 'sputnik-ui'

const InputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <TextArea
    {...field}
    {...props}
    error={touched[field.name] && Boolean(errors[field.name])}
    helperText={errors[field.name] || props.helperText}
  />
)

export const FormArea = (props) => (
  <Field component={InputComponent} {...props} />
)
