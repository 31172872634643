import React from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

const withSearch = (searchFunction) => (C) => {
  class ComponentWithSearch extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isSearching: false,
      }
      this.handleSearch = debounce((e) => {
        searchFunction(this.props, e.target.value)
      }, 300)
    }

    toggleSearch = () =>
      this.setState((prevState) => ({ isSearching: !prevState.isSearching }))

    onSearch = (e) => {
      e.persist()
      this.handleSearch(e)
    }

    render() {
      const { state, props } = this
      return (
        <C
          {...props}
          {...state}
          toggleSearch={this.toggleSearch}
          onSearch={this.onSearch}
        />
      )
    }
  }

  const mapStateToProps = ({ selected }) => ({
    selected,
  })

  return connect(mapStateToProps)(ComponentWithSearch)
}

export default withSearch
