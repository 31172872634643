import React, { useState } from 'react'
import { CitizensCardPreview } from './CitizensCardPreview'

const CitizensMainModal = React.lazy(() =>
  import('./CitizensMainModal/CitizensMainModal')
)

export const CitizensListPro = () => {
  const [open, setOpen] = useState(false)

  const handleOpenCitizensModal = () => {
    setOpen(true)
  }

  const handleCloseCitizensModal = () => {
    setOpen(false)
  }

  return (
    <>
      <CitizensCardPreview onOpenCitizensModal={handleOpenCitizensModal} />
      <CitizensMainModal open={open} onClose={handleCloseCitizensModal} />
    </>
  )
}
