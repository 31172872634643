export const FETCH_ORDERS_REQUEST = 'orders/FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'orders/FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAILURE = 'orders/FETCH_ORDERS_FAILURE'

export const FETCH_ORDER_REQUEST = 'orders/FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'orders/FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'orders/FETCH_ORDER_FAILURE'

export const COMPLETE_ORDER_REQUEST = 'orders/COMPLETE_ORDER_REQUEST'
export const COMPLETE_ORDER_SUCCESS = 'orders/COMPLETE_ORDER_SUCCESS'
export const COMPLETE_ORDER_FAILURE = 'orders/COMPLETE_ORDER_FAILURE'

export const SEND_MESSAGE_REQUEST = 'orders/SEND_MESSAGE_REQUEST'
export const SEND_MESSAGE_SUCCESS = 'orders/SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAILURE = 'orders/SEND_MESSAGE_FAILURE'

export const FETCH_ARCHIVED_ORDERS_REQUEST =
  'orders/FETCH_ARCHIVED_ORDERS_REQUEST'
export const FETCH_ARCHIVED_ORDERS_SUCCESS =
  'orders/FETCH_ARCHIVED_ORDERS_SUCCESS'
export const FETCH_ARCHIVED_ORDERS_FAILURE =
  'orders/FETCH_ARCHIVED_ORDERS_FAILURE'

export const RECEIVE_NEW_ORDER = 'orders/RECEIVE_NEW_ORDER'
export const UPDATE_CURRENT_ORDER = 'orders/UPDATE_CURRENT_ORDER'
export const UPDATE_ORDERS_LIST = 'orders/UPDATE_ORDERS_LIST'

export const CLEAR_ORDERS = 'orders/CLEAR'
export const CLEAR_CURRENT_ORDER = 'orders/CLEAR_CURRENT'
