import * as types from './types'

export const fetchCurrentRequest = () => ({
  type: types.FETCH_CURRENT_REQUEST,
})
export const fetchCurrentSuccess = (partner) => ({
  type: types.FETCH_CURRENT_SUCCESS,
  payload: {
    partner,
  },
})
export const fetchCurrentFailure = (error) => ({
  type: types.FETCH_CURRENT_FAILURE,
  payload: {
    error,
  },
})

export const createPartnerRequest = () => ({
  type: types.CREATE_PARTNER_REQUEST,
})
export const createPartnerSuccess = (msg) => ({
  type: types.CREATE_PARTNER_SUCCESS,
  payload: { msg },
})
export const createPartnerFailure = (error) => ({
  type: types.CREATE_PARTNER_FAILURE,
  payload: {
    error,
  },
})
