import { combineReducers } from 'redux'
import * as types from './types'

import { FETCH_COUNTRY_SUCCESS } from './countries/types'
import { FETCH_AREA_SUCCESS } from './areas/types'
import { FETCH_ENTRY_SUCCESS } from './entries/types'
import { FETCH_FLAT_SUCCESS } from './flats/types'
import { FETCH_HOUSE_SUCCESS } from './houses/types'
import { FETCH_LOCALITY_SUCCESS } from './localities/types'
import { FETCH_REGION_SUCCESS } from './regions/types'
import { FETCH_STREET_SUCCESS } from './streets/types'

import countries from './countries/reducers'
import regions from './regions/reducers'
import localities from './localities/reducers'
import areas from './areas/reducers'
import streets from './streets/reducers'
import houses from './houses/reducers'
import entries from './entries/reducers'
import flats from './flats/reducers'
import citizens from './citizens/reducers'

const initialState = {
  parents: null,
  fullAddress: null,
}

function current(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        parents: payload.country.parents,
        fullAddress: payload.country.full_address,
      }
    case FETCH_AREA_SUCCESS:
      return {
        ...state,
        parents: payload.area.parents,
        fullAddress: payload.area.full_address,
      }
    case FETCH_ENTRY_SUCCESS:
      return {
        ...state,
        parents: payload.entry.parents,
        fullAddress: payload.entry.full_address,
      }
    case FETCH_FLAT_SUCCESS:
      return {
        ...state,
        parents: payload.flat.parents,
        fullAddress: payload.flat.full_address,
      }
    case FETCH_HOUSE_SUCCESS:
      return {
        ...state,
        parents: payload.house.parents,
        fullAddress: payload.house.full_address,
      }
    case FETCH_LOCALITY_SUCCESS:
      return {
        ...state,
        parents: payload.locality.parents,
        fullAddress: payload.locality.full_address,
      }
    case FETCH_REGION_SUCCESS:
      return {
        ...state,
        parents: payload.region.parents,
        fullAddress: payload.region.full_address,
      }
    case FETCH_STREET_SUCCESS:
      return {
        ...state,
        parents: payload.street.parents,
        fullAddress: payload.street.full_address,
      }

    case types.CLEAR_RESOURCES:
      return initialState

    default:
      return state
  }
}

export default combineReducers({
  current,

  countries,
  regions,
  localities,
  areas,
  streets,
  houses,
  entries,
  flats,
  citizens,
})
