import * as types from './types'

export const getKeysRequest = () => ({
  type: types.GET_KEYS_REQUEST,
})
export const getEntryKeysSuccess = (keys, firstPage, keysCount) => ({
  type: types.GET_ENTRY_KEYS_SUCCESS,
  payload: { keys, firstPage, keysCount },
})
export const getEntryKeysError = (isUnpaid) => ({
  type: types.GET_ENTRY_KEYS_ERROR,
  payload: { isUnpaid },
})

export const getFlatKeysSuccess = (keys, firstPage, keysCount, flatUuid) => ({
  type: types.GET_FLAT_KEYS_SUCCESS,
  payload: { keys, firstPage, keysCount, flatUuid },
})
export const getFlatKeysError = (isUnpaid) => ({
  type: types.GET_FLAT_KEYS_ERROR,
  payload: { isUnpaid },
})

export const addKeyRequest = () => ({
  type: types.ADD_KEY_REQUEST,
})
export const addKeySuccess = (msg) => ({
  type: types.ADD_KEY_SUCCESS,
  payload: { msg },
})
export const addKeyError = (error) => ({
  type: types.ADD_KEY_ERROR,
  payload: { error },
})

export const removeKeyRequest = () => ({
  type: types.REMOVE_KEY_REQUEST,
})
export const removeKeySuccess = (msg) => ({
  type: types.REMOVE_KEY_SUCCESS,
  payload: { msg },
})
export const removeKeyError = (error) => ({
  type: types.REMOVE_KEY_ERROR,
  payload: { error },
})

export const deleteAllKeysRequest = () => ({
  type: types.DELETE_ALL_KEYS_REQUEST,
})
export const deleteAllKeysSuccess = (msg) => ({
  type: types.DELETE_ALL_KEYS_SUCCESS,
  payload: {
    msg,
  },
})
export const deleteAllKeysError = (error) => ({
  type: types.DELETE_ALL_KEYS_ERROR,
  payload: {
    error,
  },
})

export const syncKeysRequest = () => ({
  type: types.SYNC_KEYS_REQUEST,
})
export const syncKeysSuccess = (msg) => ({
  type: types.SYNC_KEYS_SUCCESS,
  payload: {
    msg: msg?.message,
  },
})
export const syncKeysError = (error) => ({
  type: types.SYNC_KEYS_ERROR,
  payload: {
    error,
  },
})

export const fetchInvalidKeysRequest = () => ({
  type: types.FETCH_INVALID_KEYS_REQUEST,
})
export const fetchInvalidKeysSuccess = (keys) => ({
  type: types.FETCH_INVALID_KEYS_SUCCESS,
  payload: {
    keys,
  },
})
export const fetchInvalidKeysFailure = (error) => ({
  type: types.FETCH_INVALID_KEYS_FAILURE,
  payload: {
    error,
  },
})

export const removeInvalidKeyRequest = () => ({
  type: types.REMOVE_INVALID_KEY_REQUEST,
})
export const removeInvalidKeySuccess = () => ({
  type: types.REMOVE_INVALID_KEY_SUCCESS,
})
export const removeInvalidKeyFailure = (error) => ({
  type: types.REMOVE_INVALID_KEY_FAILURE,
  payload: {
    error,
  },
})

export const addInvalidKeyRequest = () => ({
  type: types.ADD_INVALID_KEY_REQUEST,
})
export const addInvalidKeySuccess = (msg) => ({
  type: types.ADD_INVALID_KEY_SUCCESS,
  payload: { msg },
})
export const addInvalidKeyFailure = (error) => ({
  type: types.ADD_INVALID_KEY_FAILURE,
  payload: {
    error,
  },
})

export const importKeysRequest = () => ({
  type: types.IMPORT_KEYS_REQUEST,
})
export const importKeysSuccess = (msg) => ({
  type: types.IMPORT_KEYS_SUCCESS,
  payload: { msg },
})
export const importKeysFailure = (error) => ({
  type: types.IMPORT_KEYS_FAILURE,
  payload: {
    error,
  },
})

export const exportKeysRequest = () => ({
  type: types.EXPORT_KEYS_REQUEST,
})
export const exportKeysSuccess = (msg) => ({
  type: types.EXPORT_KEYS_SUCCESS,
  payload: { msg },
})
export const exportKeysFailure = (error) => ({
  type: types.EXPORT_KEYS_FAILURE,
  payload: {
    error,
  },
})

export const clearFlatKeys = () => ({
  type: types.CLEAR_FLAT_KEYS,
})
