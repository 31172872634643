import React from 'react'
import { Button, ConfirmModal } from 'sputnik-ui'
import { ReactComponent as EmojiSmile } from 'assets/EmojiSmile.svg'
import { Trans, useTranslation } from 'react-i18next'

export const SuccessWarrantyOrder = ({ onClose, open }) => {
  const { t } = useTranslation('warranty')

  return (
    <ConfirmModal
      open={open}
      title={t('warranty:title.registered')}
      label={
        <Trans
          i18nKey="warranty:thank_text"
          components={{ nextLine: <br /> }}
        />
      }
      icon={<EmojiSmile />}
      actions={
        <>
          <Button variant="v2" primary fullWidth onClick={onClose}>
            {t('warranty:action.to_applications')}
          </Button>
        </>
      }
    />
  )
}
