import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Card } from 'shared/ui'
import { Button, TextArea, Typography } from 'sputnik-ui'
import { saveEntryNotice } from 'store/resources/operations'

export const AddressNote = ({ ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'entryPage'])
  const { entryPending } = useSelector(
    ({ resourcesEntries }) => resourcesEntries
  )
  const notice =
    useSelector(({ resourcesEntries }) => resourcesEntries.current?.notice) ||
    ''
  const [value, setValue] = useState(notice)
  const { entry } = useParams()

  const handleChangeComment = (text) => {
    const len = text.target.value.length
    if (len <= 200) {
      setValue(text.target.value)
    }
  }

  useEffect(() => {
    setValue(notice)
  }, [notice])

  const handleSave = () => {
    dispatch(saveEntryNotice({ entry, value }))
  }

  return (
    <Card {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
          flexWrap: 'wrap',
          gap: 10,
        }}
      >
        <Typography variant="body" color="textSecondary">
          {t('entryPage:notice', {
            count: 200,
            of: 200 - Number(value?.length),
          })}
        </Typography>
        <Button
          variant="v2"
          disabled={entryPending}
          tertiary
          style={{
            width: 'fit-content',
            padding: 0,

            justifyContent: 'flex-end',
          }}
          onClick={handleSave}
        >
          {t('common:button.save')}
        </Button>
      </div>
      <TextArea
        onChange={handleChangeComment}
        value={value}
        maxLength="200"
        disabled={entryPending}
      />
    </Card>
  )
}
