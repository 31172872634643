import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'utils/api'
import { CLEAR_RESOURCES } from 'store/resources/types'
import { CLEAR_STATUS } from 'store/ui/types'
import { showErrorMessage, showInfoMessage } from 'store/ui/actions'

export const getThirdPartyCamera = createAsyncThunk(
  'cameras/getThirdParty',
  async (uuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(`/foreign_cameras/${uuid}`)
      return Promise.resolve(payload)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(err)
    }
  }
)

export const addThirdPartyCamera = createAsyncThunk(
  'cameras/addThirdParty',
  async ({ ...args }, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(`/foreign_cameras`, {
        method: 'POST',
        body: JSON.stringify({ ...args }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(err)
    }
  }
)

export const editThirdPartyCamera = createAsyncThunk(
  'cameras/editThirdParty',
  async ({ uuid, ...args }, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(`/foreign_cameras/${uuid}`, {
        method: 'PATCH',
        body: JSON.stringify({ ...args }),
      })
      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { error } = await err.json()
      dispatch(showErrorMessage(error))
      return rejectWithValue(err)
    }
  }
)

export const unlinkCamera = createAsyncThunk(
  'cameras/unlink',
  async (cameraUuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(`/cameras/${cameraUuid}/unlink`, {
        method: 'PATCH',
      })
      dispatch(showInfoMessage(payload?.message))
      return Promise.resolve(payload)
    } catch (err) {
      const { message } = await err.json()
      dispatch(showErrorMessage(message))
      return rejectWithValue(message)
    }
  }
)

export const fetchCamera = createAsyncThunk(
  'cameras/fetch',
  async (cameraUuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(`/cameras/${cameraUuid}`)
      return payload
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

export const fixStreamCamera = createAsyncThunk(
  'cameras/fixStream',
  async (cameraUuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/cameras/${cameraUuid}/create_stream`,
        {
          method: 'POST',
        }
      )

      dispatch(showInfoMessage(payload?.message))
      return payload
    } catch (err) {
      const { message } = await err.json()
      return rejectWithValue(message)
    }
  }
)

const initialState = {
  msg: '',
  error: '',
  unlinking: false,
  current: null,
  pending: false,
}

// reducers
export const camerasSlice = createSlice({
  name: 'cameras',
  initialState,
  reducers: {
    clearCamerasStore: () => initialState,
  },
  extraReducers: {
    [CLEAR_RESOURCES]: () => initialState,
    [CLEAR_STATUS]: (state) => {
      state.msg = ''
      state.error = ''
    },

    [getThirdPartyCamera.pending]: (state) => {
      state.pending = true
    },
    [getThirdPartyCamera.fulfilled]: (state, { payload }) => {
      state.current = payload
      state.pending = false
    },
    [getThirdPartyCamera.rejected]: (state) => {
      state.pending = false
    },

    [unlinkCamera.pending]: (state) => {
      state.unlinking = true
    },
    [unlinkCamera.fulfilled]: (state) => {
      state.unlinking = false
    },
    [unlinkCamera.rejected]: (state) => {
      state.unlinking = false
    },
    [fetchCamera.fulfilled]: (state, { payload }) => {
      state.current = payload
    },
    [fixStreamCamera.fulfilled]: (state) => {},
  },
})
export const camerasActions = camerasSlice.actions
export default camerasSlice.reducer
