import React, { useCallback, useState, useEffect } from 'react'
import { Button } from 'sputnik-ui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Zoom,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormField from 'components/atoms/FormField'
import { useDispatch, useSelector } from 'react-redux'
import { createPartner } from 'store/partners/operations'
import styled from 'styled-components'
import { toggle } from 'utils/helpers'
import i18next from 'i18next'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

const schema = Yup.object().shape({
  organisation: Yup.string().required(i18next.t('common:field.error.required')),
  contact_phone: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  support_phone: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  first_working_hour: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  last_working_hour: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  weekend_first_working_hour: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  weekend_last_working_hour: Yup.string().required(
    i18next.t('common:field.error.required')
  ),
  head_name: Yup.string().required(i18next.t('common:field.error.required')),
  address: Yup.string().required(i18next.t('common:field.error.required')),
})

const Day = styled.div`
  width: 32px;
  height: 32px;
  border-radius: ${(p) => p.theme.sizes.borderRadius};
  display: grid;
  place-items: center;
  background: ${(p) =>
    p.selected
      ? p.theme.palette.secondary[500]
      : p.theme.palette.background.secondary};
  cursor: pointer;
  transition: all 200ms ease-in;
  margin: 0.325rem 0 0 0.325rem;
  font-size: 0.875rem;
  &:hover {
    background: ${(p) => p.theme.palette.secondary[300]};
  }
`

const days = [0, 1, 2, 3, 4, 5, 6]

const dayTypeCheck = (arr) => {
  if (!arr) return null
  const type = arr.some((el) => typeof el === 'string')
  if (type) {
    return arr.map((el) => Number(el))
  }
  return arr
}

const dayToStr = (d) => {
  if (typeof d === 'number') {
    switch (d) {
      case 0:
        return i18next.t('time:days.short.mon')
      case 1:
        return i18next.t('time:days.short.tue')
      case 2:
        return i18next.t('time:days.short.wed')
      case 3:
        return i18next.t('time:days.short.thurs')
      case 4:
        return i18next.t('time:days.short.fri')
      case 5:
        return i18next.t('time:days.short.sat')
      case 6:
        return i18next.t('time:days.short.sun')
      default:
        return i18next.t('time:days.short.mon')
    }
  } else {
    throw new Error('day is NaN')
  }
}

function ProfileModal({ open, handleClose }) {
  const [profileModalDismissed, setProfileModalDismissed] = useState(
    Boolean(sessionStorage.getItem('profileModalDismissed'))
  )

  const dispatch = useDispatch()
  const partner = useSelector(({ partners }) => partners.current)
  const isFilled = partner?.is_filled || true

  const isOpen = !profileModalDismissed && !isFilled

  const { t } = useTranslation(['common', 'profile'])

  const onClose = () => {
    sessionStorage.setItem('profileModalDismissed', true)
    setProfileModalDismissed(true)
  }

  const handleSubmit = useCallback(
    (values) => {
      dispatch(createPartner(values)).then(handleClose)
    },
    [dispatch, handleClose]
  )

  return (
    <Dialog
      open={open || isOpen}
      TransitionComponent={Transition}
      onClose={handleClose || onClose}
      disableBackdropClick
    >
      <DialogTitle>{t('profile:title')}</DialogTitle>
      <Formik
        initialValues={{
          ...partner,
          phone: '',
          head_name: partner?.head_name || '',
          //   address: '',
          first_working_hour: partner?.first_working_hour || '09:00',
          last_working_hour: partner?.last_working_hour || '18:00',
          weekend_first_working_hour:
            partner?.weekend_first_working_hour || '09:00',
          weekend_last_working_hour:
            partner?.weekend_last_working_hour || '18:00',
          working_days: dayTypeCheck(partner?.working_days) || [],
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form>
            <DialogContent>
              <Typography>{t('profile:subheader')}</Typography>
              <FormField
                fullWidth
                label={t('profile:form.org')}
                name="organisation"
                style={{ marginTop: '1rem' }}
              />
              <FormField
                fullWidth
                label={t('profile:form.address')}
                name="address"
                style={{ marginTop: '1rem' }}
              />

              <FormField
                style={{ marginTop: '1rem' }}
                fullWidth
                label={t('profile:form.support_phone')}
                name="support_phone"
                type="phone"
                // helperText={t('profile:helper.support_phone')}
              />

              <FormField
                style={{ marginTop: '1rem' }}
                fullWidth
                label={t('profile:form.additional_contacts')}
                name="additional_contacts"
                type="phone"
                helperText={t('profile:helper.additional_contacts')}
              />

              {/* 
              <div style={{ display: 'flex', marginTop: '1rem' }}>
                <FormField
                  fullWidth
                  label="Имя ответственного лица"
                  name="head_first_name"
                />
                <FormField
                  fullWidth
                  label="Фамилия ответственного лица"
                  name="head_last_name"
                  style={{ marginLeft: '1rem' }}
                />
              </div> */}
              <FormField
                style={{ marginTop: '1rem' }}
                fullWidth
                label={t('profile:form.charge_person')}
                name="head_name"
              />
              <FormField
                style={{ marginTop: '1rem' }}
                fullWidth
                label={t('profile:form.contact_phone')}
                name="contact_phone"
                type="phone"
                // helperText={t('profile:helper.contact_phone')}
              />

              <Typography style={{ marginTop: '1rem' }}>
                {t('profile:work_hours.title')}
              </Typography>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '48%' }}>
                  <Typography color="textSecondary">
                    {t('profile:work_hours.weekdays')}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormField
                      fullWidth
                      name="first_working_hour"
                      label={t('profile:form.work_from')}
                      style={{ marginRight: '.325rem' }}
                      type="time"
                    />
                    <FormField
                      fullWidth
                      name="last_working_hour"
                      label={t('profile:form.work_till')}
                      type="time"
                    />
                  </div>
                </div>
                <div style={{ width: '48%' }}>
                  <Typography color="textSecondary">
                    {t('profile:work_hours.weekend')}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <FormField
                      fullWidth
                      name="weekend_first_working_hour"
                      label={t('profile:form.work_from')}
                      style={{ marginRight: '.325rem' }}
                      type="time"
                      disabled={
                        !values.working_days.includes(5) &&
                        !values.working_days.includes(6)
                      }
                    />
                    <FormField
                      fullWidth
                      name="weekend_last_working_hour"
                      label={t('profile:form.work_till')}
                      type="time"
                      disabled={
                        !values.working_days.includes(5) &&
                        !values.working_days.includes(6)
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                {days.map((d) => (
                  <Day
                    key={d}
                    selected={values.working_days.includes(d)}
                    onClick={() => {
                      const newDays = [...values.working_days]
                      toggle(newDays, d)
                      setValues({
                        ...values,
                        working_days: newDays,
                      })
                    }}
                  >
                    {dayToStr(d)}
                  </Day>
                ))}
              </div>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} rounded>
                {t('common:button.later')}
              </Button>
              <Button rounded primary variant="contained" type="submit">
                {t('common:button.confirm')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default ProfileModal
