import i18next from 'i18next'

function getYesterday(d) {
  return new Date(d.setDate(d.getDate() - 1))
}

export const formatDate = (date, fullDate = false, view = 'default') => {
  // view default || numeric
  const dateOptions = fullDate
    ? {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
      }
    : {
        month: '2-digit',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        year: '2-digit',
      }
  const dateObj = new Date(date)
  const isToday = new Date().toDateString() === dateObj.toDateString()
  const isYesterday =
    getYesterday(new Date()).toDateString() === dateObj.toDateString()

  if (view === 'numeric')
    return dateObj.toLocaleString(undefined, dateOptions).replace(/,/gi, ' ')

  return isToday && view
    ? `${i18next.t('time:today')}, ${dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    : isYesterday
    ? `${i18next.t('time:yesterday')}, ${dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    : dateObj.toLocaleString(undefined, dateOptions)
}

export const copyToClipboard = (str) => {
  const { clipboard } = window.navigator
  /*
   * fallback to older browsers (including Safari)
   * if clipboard API not supported
   */
  if (!clipboard || typeof clipboard.writeText !== `function`) {
    const textarea = document.createElement(`textarea`)
    textarea.value = str
    textarea.setAttribute(`readonly`, true)
    textarea.setAttribute(`contenteditable`, true)
    textarea.style.position = `absolute`
    textarea.style.left = `-9999px`
    document.body.appendChild(textarea)
    textarea.select()
    const range = document.createRange()
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    textarea.setSelectionRange(0, textarea.value.length)
    document.execCommand(`copy`)
    document.body.removeChild(textarea)

    return Promise.resolve(true)
  }

  return clipboard.writeText(str)
}

// url

export const buildUrlQuery = (params) =>
  Object.entries(params)
    .filter(([key, val]) => val !== undefined)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&')

export const buildUrl = (baseUrl, params = {}) => {
  const query = buildUrlQuery(params)
  return query ? `${baseUrl}?${query}` : baseUrl
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max)
}

export function toggle(array, value) {
  const index = array.indexOf(value)
  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

export function getDeviceTypeByNumber(val) {
  const len = val?.toString().trim().length
  const variables = {
    10: 'intercom',
    16: 'camera',
  }
  return variables[len] || 'intercom'
}

export function isDeviceUuidValid(val) {
  const len = val?.toString().trim().length
  const variables = {
    10: 'intercom',
    16: 'camera',
  }
  return !!variables[len]
}

export function truncate(text, limit = 8) {
  if (text?.length > limit) {
    return `${text.substring(0, limit)}...`
  }
  return text
}

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  return scrollbarWidth
}

export const betaTestInProduction = (companyId, component) => {
  const isSputnik = 'b1782e4f-9198-49d1-b5aa-7bdba9c87d21'
  return companyId === isSputnik ? component : null
}

export const keyDownWithClick = (event, func, keys = ['Enter']) => {
  if (event.type === 'click') {
    func()
    return
  }
  if (keys.includes(event.key)) {
    func()
  }
}

export const ExcludeTurkey = ({ children }) => {
  if (window.location.host === 'control.evimiz.info') return null

  return children
}
