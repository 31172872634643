import * as types from './types'
import { CLEAR_RESOURCES } from '../resources/types'
import { CLEAR_STATUS } from '../ui/types'

const initialState = {
  msg: null,
  error: null,
  isUnpaid: false,
  list: [],
  count: null,
  flatKeys: [],
}

export default function keys(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_KEYS_REQUEST:
      return { ...state, loading: true }
    case types.GET_ENTRY_KEYS_SUCCESS:
      return {
        ...state,
        list: payload.firstPage
          ? payload.keys
          : [...state.list, ...payload.keys],
        loading: false,
        count: payload.keysCount,
      }
    case types.GET_ENTRY_KEYS_ERROR:
      return { ...state, isUnpaid: payload.isUnpaid, loading: false }
    case types.GET_FLAT_KEYS_SUCCESS: {
      const { flatUuid, firstPage, keys } = payload

      return {
        ...state,
        loading: false,
        flatKeys: {
          ...state.flatKeys,
          [flatUuid]: firstPage ? keys : [...state.flatKeys, ...keys],
        },
      }
    }

    // return {
    //   ...state,
    //   flatKeys: payload.firstPage
    //     ? payload.keys
    //     : [...state.flatKeys, ...payload.keys],
    //   loading: false,
    //   flatKeysCount: payload.keysCount,
    // }
    case types.GET_FLAT_KEYS_ERROR:
      return { ...state, isUnpaid: payload.isUnpaid, loading: false }
    case types.REMOVE_KEY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.REMOVE_KEY_ERROR:
      return { ...state, error: payload.error }
    case types.ADD_KEY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.ADD_KEY_ERROR:
      return { ...state, error: payload.error }
    case types.DELETE_ALL_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.DELETE_ALL_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.SYNC_KEYS_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.SYNC_KEYS_ERROR:
      return { ...state, error: payload.error }
    case types.FETCH_INVALID_KEYS_SUCCESS:
      return { ...state, invalid: payload.keys }
    case types.FETCH_INVALID_KEYS_FAILURE:
      return { ...state, error: payload.error }
    case types.REMOVE_INVALID_KEY_FAILURE:
      return { ...state, error: payload.error }
    case types.ADD_INVALID_KEY_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.ADD_INVALID_KEY_FAILURE:
      return { ...state, error: payload.error }
    case types.CLEAR_FLAT_KEYS:
      return { ...state, flatKeys: [] }
    case CLEAR_STATUS:
      return { ...state, error: null, msg: null }
    case CLEAR_RESOURCES:
      return initialState
    default:
      return state
  }
}
