import * as types from './types'

export const clearStatus = () => ({
  type: types.CLEAR_STATUS,
})

export const showInfoMessage = (msg) => ({
  type: types.SHOW_INFO_MESSAGE,
  payload: { msg },
})

export const showUpdateMessage = (updateAction) => ({
  type: types.SHOW_UPDATE_MESSAGE,
  payload: { updateAction },
})

export const showErrorMessage = (msg) => ({
  type: types.SHOW_ERROR_MESSAGE,
  payload: { msg },
})
