import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'shared/ui'
import { IconColors, UsersIcon } from 'sputnik-icons'
import styled from 'styled-components'
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchCitizensList } from '../model/services/fetchCitizensList'
import { tables } from '../model/const/const'
import { getCitizensProPending } from '../model/selectors/getCitizensProPending'

const BlueText = styled.span`
  color: ${(p) => p.theme.palette.blue.main};
`

export const CitizensCardPreview = ({ onOpenCitizensModal }) => {
  const { t } = useTranslation(['people', 'messages'])
  const dispatch = useDispatch()
  const pending = useSelector(getCitizensProPending)
  const addressUuid = useSelector(
    (state) => state.resources.entries?.current?.uuid
  )

  const [metaCache, setMetaCache] = useState()

  useEffect(() => {
    addressUuid &&
      dispatch(
        fetchCitizensList({ addressUuid, limit: 1, inviteState: tables.all })
      )
        .then(unwrapResult)
        .then((payload) => setMetaCache(payload.body.metadata.meta))
  }, [addressUuid, dispatch])

  return (
    <Card
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UsersIcon style={{ marginRight: '1rem' }} color={IconColors.grey} />
        {metaCache && metaCache?.total_count
          ? `${metaCache?.total_count} ${t('people:living_person_3')}`
          : pending
          ? t('messages:content.loading')
          : t('people:pro.messages.empty')}
      </div>
      <BlueText onClick={onOpenCitizensModal} style={{ cursor: 'pointer' }}>
        {t('people:pro.actions.to_citizens')}
      </BlueText>
    </Card>
  )
}
